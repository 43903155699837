
import { OnboardingScreenWrapper } from "../OnboardingScreenWrapper";

export const TransitionToPracticeSystemSetupScreen = () => {

  return (
    <OnboardingScreenWrapper title={"Practice System Setup"}>
      <>
      </>
    </OnboardingScreenWrapper>
  );
}

import { PopupModal } from "../../../../components/PopupModal";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/x.svg";
import { TextInput } from "../../../../components/TextInput";
import React, { useEffect } from "react";
import { Button } from "../../../../components/Button";
import { useFiles } from "../context/file-context";

export function RenameFolderDialog({ data, onClose, open }) {
  const { renameFolder, renamingFolder, currentFolder, allFiles } =
    useFiles() as any;
  const [folderName, setFolderName] = React.useState<string>("");
  const [errors, setErrors] = React.useState<any>({});

  const handleSubmit = async () => {
    if (!folderName) {
      setErrors({ ...errors, folderName: "Please enter folder name" });
      return;
    }
    await renameFolder(folderName, data);
    onClose();
  };

  useEffect(() => {
    if (open && data?.id) {
      setFolderName(data?.name);
    }
  }, [data?.id, data?.name, open]);

  const list = currentFolder ? currentFolder.children || [] : allFiles || [];
  const isDuplicateFolder = !!list?.find(
    (folder) =>
      folder.name?.toLowerCase() === folderName?.toLowerCase() &&
      folder.id !== data.id
  );
  const disabled = !folderName || isDuplicateFolder;
  return (
    <PopupModal
      contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[442px] max-w-[442px]"
      onClose={onClose}
    >
      <>
        <div className="w-full justify-between items-start flex">
          <div className="text-black text-2xl font-bold font-['Outfit'] leading-7">
            Rename folder
          </div>
          <div className="justify-end items-center gap-3 flex">
            <button onClick={onClose}>
              <CloseIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
        <div className="w-full">
          <div className="flex flex-col gap-1.5 mb-8">
            <span className="text-black/80 text-sm">Folder Name</span>
            <TextInput
              placeholder="Enter Folder Name"
              className="!p-[14px]"
              name="folderName"
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
            />
            {isDuplicateFolder ? (
              <div className="text-red-500 text-xs font-medium">
                Duplicate Folder name not allowed
              </div>
            ) : (
              errors.folderName && (
                <div className="text-red-500 text-xs font-medium">
                  {errors.folderName}
                </div>
              )
            )}
          </div>
          <Button
            disabled={disabled || renamingFolder}
            variant="primary"
            className="!rounded-full w-full"
            onClick={handleSubmit}
            loading={renamingFolder}
          >
            Rename
          </Button>
        </div>
      </>
    </PopupModal>
  );
}

import { locationData } from "./locationData";
import Cookies from "js-cookie";

export const setToken = (token: string) => {
  // set to cookie storage
  Cookies.set("token", token, { expires: 7 });
};

export const getToken = () => {
  return Cookies.get("token");
};

export const clearCookies = () => {
  Cookies.remove("token");
  // Cross browser cookie does not remove by javascript code
  // So, we need to remove it by setting empty value
  setUser({});
};

export const setUser = (user: any) => {
  // set to cookies storage
  Cookies.set("headstart_user", JSON.stringify(user), {
    domain: process.env.NODE_ENV === "development" ? "" : ".headstart.health",
    expires: 7,
  });
};

export const getUser = () => {
  // get from cookies storage
  const user = Cookies.get("headstart_user");
  return user ? JSON.parse(user) : null;
};

export function checkPasswordStrength(password) {
  const requirements = [
    { regex: /[a-z]/, message: "lowercase letter" },
    { regex: /[A-Z]/, message: "uppercase letter" },
    { regex: /\d/, message: "digit" },
    { regex: /[@$!%*?&]/, message: "special character" },
    { regex: /.{8,}/, message: "at least 8 characters" },
  ];
  let score = 0;
  let message = "";
  requirements.forEach((requirement) => {
    if (requirement.regex.test(password)) {
      score++;
    } else {
      message += ` ${requirement.message},`;
    }
  });
  if (score === requirements.length) {
    message = "Your password is great. Nice work!";
  } else {
    message = `Your password needs ${message.slice(0, -1)}`;
  }
  return { score: (score / requirements.length) * 100, message };
}

export const validateEmail = (email: string): boolean => {
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return emailRegex.test(email);
};

export const validatePhone = (phone: string): boolean => {
  return !!phone.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
};

export function formatNumber(num: number) {
  const absNum = Math.abs(num);

  if (absNum >= 1e9) {
    return (num / 1e9).toFixed(1).replace(/\.0$/, "") + "B";
  } else if (absNum >= 1e6) {
    return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M";
  } else if (absNum >= 1e3) {
    return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "k";
  } else {
    return num.toString();
  }
}

export function isMe(type: string, isPatientSender: boolean) {
  // type = "P - Provider" or "PF - Patient"
  // isPatientSender = true & type = "PF" => true
  // isPatientSender = false & type = "P" => true
  return (isPatientSender && type === "PF") || (!isPatientSender && type === "P");
}

export const getLocationListForSelect = () => {
  const locationList: { label: string; value: string }[] = locationData.sort().map((location) => ({
    label: location,
    value: location,
  }));
  return locationList;
};

export const getStateOrCity = (location: string) => {
  if (!location) return "";
  // ex. Denver, Colorado => Colorado
  // ex. Denver => Denver
  // ex. Colorado => Colorado
  const locationArr = location.split(",");
  if (locationArr.length === 2) {
    return locationArr[1].trim();
  }
  return locationArr[0].trim();
};

export function getInitial(firstName: string, lastName: string) {
  // Akshay Kumar => AK
  // Akshay => A
  // Kumar => K
  if (firstName && lastName) {
    return `${firstName[0]}${lastName[0]}`;
  } else if (firstName) {
    return firstName[0];
  } else if (lastName) {
    return lastName[0];
  } else {
    return "";
  }
}

export const youtubeParser = (url) => {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
};

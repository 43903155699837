import React, { useEffect } from "react";
import { api } from "../../../services/api";
import { getUser, setUser } from "../../../services/utils";
import { CONTACT_FETCH_INTERVAL } from "../../mailbox/context/mailbox-context";



const ACTIVE_STATUS_INTERVAL = 1000 * 60; // 1 minute

export enum OnboardingScreen {
  CONFIRM_CONTACT_INFO = "CONFIRM_CONTACT_INFO",
  CREDENTIALING_SETUP = "CREDENTIALING_SETUP",
  UPLOAD_RESUME = "UPLOAD_RESUME",
  MSA = "MSA",
  BOOK_HEADSTART_CALL = "BOOK_HEADSTART_CALL",
  TRANSITION_ENTITY_SET_UP = "TRANSITION_ENTITY_SET_UP",
  ENTITY_SETUP = "ENTITY_SETUP",
  UPLOAD_ENTITY_DOCUMENTS = "UPLOAD_ENTITY_DOCUMENTS",
  JOINDER_AGREEMENT = "JOINDER_AGREEMENT",
  PROVIDER_PARTICIPATION_AGREEMENT = "PROVIDER_PARTICIPATION_AGREEMENT",
  TRANSITION_PRACTICE_SYSTEMS_SETUP = "TRANSITION_PRACTICE_SYSTEMS_SETUP",
  BANK_ACCOUNT_SETUP = "BANK_ACCOUNT_SETUP",
  INSURANCE_SETUP = "INSURANCE_SETUP",
  PROFILE_SETUP = "PROFILE_SETUP",
  SOFTWARE_ONBOARDING = "SOFTWARE_ONBOARDING",
  SET_UP_COMPLETE = "SET_UP_COMPLETE",
  LAUNCH_YOUR_PRACTICE = "LAUNCH_YOUR_PRACTICE",
}

export namespace OnboardingScreen {
  export const nextScreen = (screen: OnboardingScreen) => {
    const arr = Object.values(OnboardingScreen);
    const nextIndex = arr.indexOf(screen) + 1;
    if (nextIndex === arr.length) return undefined
    return arr[nextIndex];
  }

  export const previousScreen = (screen: OnboardingScreen) => {
    const arr = Object.values(OnboardingScreen);
    const nextIndex = arr.indexOf(screen) - 1;
    if (nextIndex === -1) return undefined
    return arr[nextIndex];
  }

  export function parse(onboardingScreen?: string): OnboardingScreen | undefined {
    if (!onboardingScreen) return undefined
    const screen = OnboardingScreen[onboardingScreen];
    if (screen && screen.length > 0) return screen
    return undefined
  }
}

interface UserContextType {
  userInfo: any;
  refreshUserInfo: () => {};
  providerOnboardingStatus?: {
    currentOnboardingScreen: OnboardingScreen;
    onboardingScreenStatuses: Map<OnboardingScreen, string>;
    onboardingStatus: string;
  };
  setUserInfo: any;
  fetchUser: any;
  loading: any;
  selectedProvider: any;
  setSelectedProvider: any;
  fetchPatientsCount: any;
  patientCount: any;
  unreadPatientConversationCount: any;
}

export const UserContext = React.createContext<UserContextType | undefined>(undefined);

export function UserProvider({ children }) {
  const [userInfo, setUserInfo] = React.useState({});
  const [userInfoLoading, setUserInfoLoading] = React.useState(false);
  const [patientCount, setPatientCount] = React.useState(0);
  const [unreadPatientConversationCount, setUnreadPatientConversationCount] = React.useState(0);
  const [selectedProvider, setSelectedProvider] = React.useState<any>(null);
  const user = getUser();
  const activeStatusRef = React.useRef<any>(null);
  const patientCountRef = React.useRef<any>(null);

  const fetchUser = async (userId: string, user?: any) => {
    try {
      setUserInfoLoading(true);
      const response = await api.getUserProfile(userId);
      setUserInfoLoading(false);
      setUserInfo({
        ...response,
      });
      setUser({
        ...(user || {}),
        ...response,
      });
    } catch (error) { }
  };

  const fetchPatientsCount = async () => {
    try {
      const response = await api.getPatientsCount(user?.id);
      if ([200, 201].includes(response.status)) {
        setPatientCount(response.data.count);
      }
    } catch (error) { }
    try {
      const convRes = await api.getAllUnreadPatientConversationCount(user?.id);
      if ([200, 201].includes(convRes.status)) {
        setUnreadPatientConversationCount(convRes.data.count);
      }
    } catch (error) { }
  };

  const typedProvider = userInfo as {
    type: string;
    onboardingStatus: {
      currentOnboardingScreen: OnboardingScreen;
      onboardingScreenStatuses: Map<OnboardingScreen, string>;
      onboardingStatus: string;
    }
  }
  const providerOnboardingStatus = (userInfo && typedProvider?.type === "P") ? typedProvider.onboardingStatus : undefined;

  const refreshUserInfo = async () => {
    return await fetchUser(user?.id, user);
  }

  const values = {
    userInfo,
    refreshUserInfo,
    providerOnboardingStatus,
    setUserInfo,
    fetchUser,
    loading: userInfoLoading,
    selectedProvider,
    setSelectedProvider,
    fetchPatientsCount,
    patientCount,
    unreadPatientConversationCount,
  };
  useEffect(() => {
    if (user?.id) {
      fetchUser(user?.id, user);

      if (activeStatusRef.current) clearInterval(activeStatusRef.current);
      activeStatusRef.current = setInterval(() => {
        api.markUserAsActive(user?.id);
      }, ACTIVE_STATUS_INTERVAL);

      if (user?.type === "P") {
        fetchPatientsCount();
        if (patientCountRef.current) clearInterval(patientCountRef.current);
        patientCountRef.current = setInterval(() => {
          fetchPatientsCount();
        }, CONTACT_FETCH_INTERVAL);
      }
    }
    return () => {
      if (activeStatusRef.current) clearInterval(activeStatusRef.current);
      if (patientCountRef.current) clearInterval(patientCountRef.current);
    };
  }, [user?.id]);
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
}

export function useUser() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
}

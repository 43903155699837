
import { OnboardingScreenWrapper } from "../OnboardingScreenWrapper";

export const UploadEntityDocumentsScreen = () => {

  return (
    <OnboardingScreenWrapper title={"Upload Entity Documents"}>
      <>
      </>
    </OnboardingScreenWrapper>
  );
}

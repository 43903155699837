import { OnboardingScreenWrapper } from "../OnboardingScreenWrapper";

export const ProfileSetupScreen = () => {

  return (
    <OnboardingScreenWrapper title={"Profile Setup"}>
      <>
      </>
    </OnboardingScreenWrapper>
  );
}

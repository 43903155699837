
import { OnboardingScreenWrapper } from "../OnboardingScreenWrapper";

export const LaunchYourPracticeScreen = () => {

  return (
    <OnboardingScreenWrapper title={"Launch Your Practice"}>
      <>
      </>
    </OnboardingScreenWrapper>
  );
}

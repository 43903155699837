import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/icons/Logo.svg";
import { ReactComponent as FolderIcon } from "../../../assets/icons/folder.svg";
import { ReactComponent as PersonIcon } from "../../../assets/icons/person.svg";
import { ReactComponent as BulletinIcon } from "../../../assets/icons/bulletin.svg";
import { ReactComponent as ToolsIcon } from "../../../assets/icons/tools.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { ReactComponent as AddTickTask } from "../../../assets/icons/add-task-list.svg";
import { ReactComponent as WhiteChevronDown } from "../../../assets/icons/chevron-down-white.svg";
import { ReactComponent as ChevronDown } from "../../../assets/icons/chevron-down.svg";
import { ReactComponent as WhiteChevronRight } from "../../../assets/icons/chevron-right-white.svg";
import { ReactComponent as Add } from "../../../assets/icons/add.svg";

import { useState } from "react";
import { MobileProviderMenu } from "./MobileProviderMenu";
import { AnimatePresence } from "framer-motion";
import { UserButton } from "../../../components/UserButton/UserButton";
import { clearCookies, getUser } from "../../../services/utils";
import { ReactComponent as HamburgerIcon } from "../../../assets/icons/hamburg.svg";
import { useUser } from "../../auth/context/user-context";
import { Dropdown } from "../../../components/Dropdown";
import { ProviderProfile } from "../profile/ProfileView";
import { ChangePassword } from "../profile/ChangePassword";
import { LogoutConfirm } from "./LogoutConfirm";
import { DeleteConfirm } from "./DeleteConfirm";
import { OnboardingSideMenu } from "./OnboardingSideMenu";

export function MenuItem({
  href,
  reloadDocument,
  active,
  title,
  Icon,
  extra,
}: {
  href: string;
  reloadDocument?: boolean;
  active?: boolean;
  title: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  extra?: React.ReactNode;
}) {
  return (
    <Link
      reloadDocument={reloadDocument}
      to={href}
      className={`p-4 hover:bg-secondary ${active ? "!bg-primary" : ""
        } w-full rounded-lg justify-start items-center gap-2.5 flex`}
    >
      <Icon
        className={`w-6 h-6 relative ${active ? "[&_path]:stroke-white" : ""}`}
      />
      <div className={`${active ? "text-white" : ""} text-lg font-normal`}>
        {title}
      </div>
      {extra}
    </Link>
  );
}

export function ProviderMenu(props) {
  const user = getUser();

  const pathname = window.location.pathname;
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const { userInfo = {}, unreadPatientConversationCount } = useUser() as any;
  const [openDropdown, setOpenDropdown] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const [logoutConfirm, setLogoutConfirm] = useState(false);

  const [collapseOnboardingTab, setCollapseOnboardingTab] = useState(false)

  const handleLogoutConfirm = () => {
    setLogoutConfirm(false);
    clearCookies();
    window.location.href = "/login";
  };

  const handleLogout = () => {
    setIsProfileOpen(false);
    setOpenDropdown(false);
    setLogoutConfirm(true);
  };

  const handleEditProfile = () => {
    setShowEditProfile(true);
  };

  const handleChangePassword = () => {
    setShowChangePassword(true);
    setIsProfileOpen(false);
  };

  const handleDeleteAccount = () => {
    setIsProfileOpen(false);
    setShowDeleteAccount(true);
  };

  const handleDeleteAccountClose = () => {
    setShowDeleteAccount(false);
  };

  const handleClose = () => {
    setIsSideMenuOpen(false);
  };

  const toogleSideMenu = () => {
    setIsSideMenuOpen((open) => !open);
  };

  const isOnboarding = pathname.startsWith("/provider/onboarding")
  return (
    <>
      <div className={`w-[${isOnboarding ? '276px' : '264px'}] flex-col bg-white justify-start items-start hidden lg:flex`} style={{ "zIndex": 1 }}>
        <div className="px-7 py-6 w-full border-b border-gray-200 flex-col justify-center items-start gap-2.5 flex">
          <Link to={`/provider/bulletin`} rel="noreferrer">
            <Logo className="w-[143px] h-7" />
          </Link>
        </div>
        <div className="self-stretch grow shrink basis-0 p-3 bg-white border-b border-black border-opacity-10 backdrop-blur-[27px] flex-col justify-start items-start gap-96 flex">
          <div className="self-stretch grow shrink basis-0 flex-col justify-between items-center flex">
            <div className="w-full flex-col justify-center items-start gap-1 flex">
              <MenuItem
                href="/provider/bulletin"
                title="Bulletin"
                Icon={BulletinIcon}
                active={pathname === "/provider/bulletin"}
              />
              {/* {(process.env.REACT_APP_ENV === "local" ||
                process.env.REACT_APP_ENV === "dev") && (
                  <>
                    <div className="w-full" onClick={() => setCollapseOnboardingTab(isOnboarding ? !collapseOnboardingTab : true)}>
                      <MenuItem
                        href={"/provider/onboarding"}
                        title="Onboarding"
                        Icon={PersonIcon}
                        active={isOnboarding}
                        extra={isOnboarding ?
                          !collapseOnboardingTab ?
                            <WhiteChevronRight style={{ width: "25px", height: "25px", marginLeft: "auto" }} /> :
                            <WhiteChevronDown style={{ width: "25px", height: "25px", marginLeft: "auto" }} />
                          :
                          <ChevronDown style={{ width: "25px", height: "25px", marginLeft: "auto" }} />
                        }
                      />
                    </div>
                    {isOnboarding && !collapseOnboardingTab && <OnboardingSideMenu />}
                    {isOnboarding && collapseOnboardingTab && <div className="mr-[240px]" />}
                  </>
                )} */}

              <MenuItem
                href="/provider/dashboard"
                title="My Tools"
                Icon={ToolsIcon}
                active={pathname === "/provider/dashboard"}
              />
              <MenuItem
                href="/provider/chat"
                title="Client Chat"
                Icon={PersonIcon}
                extra={
                  unreadPatientConversationCount ? (
                    <div
                      className={`w-7 h-5 ${pathname === "/provider/chat"
                        ? "bg-white"
                        : "bg-primary"
                        } rounded-3xl flex-col justify-start items-center gap-2.5 flex`}
                    >
                      <div
                        className={`${pathname === "/provider/chat"
                          ? "text-primary"
                          : "text-white"
                          } text-sm font-normal`}
                      >
                        {unreadPatientConversationCount}
                      </div>
                    </div>
                  ) : null
                }
                active={pathname === "/provider/chat"}
              />
              <MenuItem
                href="/provider/library"
                title="My Library"
                Icon={FolderIcon}
                active={pathname === "/provider/library"}
              />
              <MenuItem
                href="/provider/request-auth"
                title="Request Auth"
                Icon={AddTickTask}
                active={pathname === "/provider/request-auth"}
              />
              <MenuItem
                href="/provider/request-rbt"
                title="Request RBT"
                Icon={Add}
                active={pathname === "/provider/request-rbt"}
              />
            </div>
            <div className="hidden lg:block relative w-full">
              <UserButton
                className="w-full [&>div]:!mx-0 !mb-0"
                variant="sideMenu"
                user={{ ...user, ...userInfo }}
                onClick={() => setOpenDropdown(true)}
              />
              <Dropdown
                padding="15px"
                className="w-full !top-[-100px] !justify-start !items-start flex"
                isOpen={openDropdown}
                setIsOpen={() => setOpenDropdown(false)}
              >
                <button
                  className="h-8 text-[#212424] text-base"
                  onClick={() => {
                    setOpenDropdown(false);
                    setIsProfileOpen(true);
                  }}
                >
                  My Profile
                </button>
                <button
                  className="text-[#E3270E] h-8 text-left text-base"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed z-[1] top-0 left-0 right-0 px-4 lg:pl-10 lg:pr-5 py-[18px] bg-white border-b border-black border-opacity-10 backdrop-blur-3xl justify-between items-center flex lg:hidden">
        <a href="/" rel="noreferrer">
          <Logo />
        </a>
        <div className="justify-end items-center gap-3 flex flex-1">
          <button className="lg:hidden" onClick={toogleSideMenu}>
            {isSideMenuOpen ? (
              <CloseIcon className="h-8 w-8 [&_path]:stroke-primary" />
            ) : (
              <HamburgerIcon className="h-8 w-8" />
            )}
          </button>
        </div>
      </div>
      <div className="lg:hidden">
        <AnimatePresence mode="wait" initial={false}>
          {isSideMenuOpen && (
            <MobileProviderMenu
              onClose={handleClose}
              onLogout={handleLogout}
              onProfile={() => {
                setOpenDropdown(false);
                setIsProfileOpen(true);
              }}
              userInfo={{ ...user, ...userInfo }}
              patientCount={unreadPatientConversationCount}
            />
          )}
        </AnimatePresence>
      </div>
      <AnimatePresence mode="wait" initial={false}>
        {isProfileOpen && (
          <ProviderProfile
            isOpen={isProfileOpen}
            provider={{ ...user, ...userInfo }}
            onClose={() => setIsProfileOpen(false)}
            onChangePassword={handleChangePassword}
            onEdit={handleEditProfile}
            onDelete={handleDeleteAccount}
            onLogout={handleLogout}
            edit={showEditProfile}
            onEditClose={() => setShowEditProfile(false)}
          />
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait" initial={false}>
        {showChangePassword && (
          <ChangePassword
            onClose={() => setShowChangePassword(false)}
            user={user}
          />
        )}
      </AnimatePresence>

      <AnimatePresence mode="wait" initial={false}>
        {logoutConfirm && (
          <LogoutConfirm
            onCancel={() => setLogoutConfirm(false)}
            onConfirm={handleLogoutConfirm}
          />
        )}
      </AnimatePresence>

      <AnimatePresence mode="wait" initial={false}>
        {showDeleteAccount && (
          <DeleteConfirm onCancel={handleDeleteAccountClose} userId={user.id} />
        )}
      </AnimatePresence>
    </>
  );
}

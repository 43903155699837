import axios from "axios";
import { api } from "./api";

export const fileUpload = async (
  file: File,
  id: string,
  uploadType: string,
  fileName?: string
) => {
  let payload: any = {
    id,
    mimeType: file.type,
    uploadType,
  };
  if (fileName) {
    payload.fileName = fileName;
  }
  const { accessUrl, uploadUrl } = await api.getPredesignedUrl(payload);
  const response = await axios.put(uploadUrl, file, {
    headers: { "Content-Type": file.type },
  });
  return { response, url: accessUrl };
};

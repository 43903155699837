import Modal from "../../../components/Modal";
import { EditProfile } from "./EditProfile";
import { ProviderProfileView } from "./Profile";

export function ProviderProfile({
  onClose,
  isOpen,
  provider,
  onChangePassword,
  onEdit,
  onDelete,
  onLogout,
  edit,
  onEditClose,
}) {
  return (
    <Modal isOpen={isOpen} handleModal={onClose}>
      {edit ? (
        <EditProfile
          provider={provider}
          onUpdate={onEditClose}
          onBack={onEditClose}
          onLogout={onLogout}
        />
      ) : (
        <ProviderProfileView
          provider={provider}
          onClose={onClose}
          onChangePassword={onChangePassword}
          onEdit={onEdit}
          onDelete={onDelete}
          onLogout={onLogout}
        />
      )}
    </Modal>
  );
}

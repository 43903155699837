import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { acceptedInsurances, focusAreas } from "../../../constant";

function Chip({ label, value, onDelete }) {
  return (
    <div className="whitespace-nowrap pl-4 pr-3 py-2.5 bg-neutral-200 rounded-3xl justify-center items-center gap-1 flex flex-nowrap">
      <div className="whitespace-nowrap">
        <span className="text-black text-base font-normal">{label}: </span>
        <span className="text-black text-base font-medium">{value}</span>
      </div>
      <button type="button" onClick={onDelete}>
        <CloseIcon className="w-4 h-4 relative" />
      </button>
    </div>
  );
}
export function SelectedFilters({ filters, onClear, total, isLoading }) {
  const hasFilters = (filters) => {
    return Object.values(filters).some((value) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value;
    });
  };
  if (!hasFilters(filters)) return null;
  return (
    <>
      <div className="md:w-[610px] lg:w-auto mx-auto flex justify-between items-end lg:items-center mb-3 lg:mb-5 gap-12">
        <div className="flex-1 flex gap-3 items-center flex-nowrap max-w-full overflow-auto lg:flex-wrap lg:w-max-auto">
          {filters.addressState && (
            <Chip
              label={"Location"}
              value={filters.addressState}
              onDelete={() =>
                onClear((prev) => ({
                  ...prev,
                  addressState: "",
                }))
              }
            />
          )}

          {filters.showAvailable && (
            <Chip
              label={"Availability"}
              value="Only available"
              onDelete={() =>
                onClear((prev) => ({
                  ...prev,
                  showAvailable: false,
                }))
              }
            />
          )}
          {filters?.providerTypes?.length > 0 && (
            <Chip
              label={"Type"}
              value={filters.providerTypes.join(", ")}
              onDelete={() =>
                onClear((prev) => ({
                  ...prev,
                  providerTypes: [],
                }))
              }
            />
          )}
          {filters?.focusAreas?.length > 0 && (
            <Chip
              label={"Focus areas"}
              value={
                focusAreas.find((area) => area.value === filters.focusAreas[0])
                  ?.label || filters.focusAreas[0]
              }
              onDelete={() =>
                onClear((prev) => ({
                  ...prev,
                  focusAreas: [],
                }))
              }
            />
          )}
          {filters?.acceptedInsurances?.length > 0 && (
            <Chip
              label={"Accepted Insurances"}
              value={
                acceptedInsurances.find(
                  (insur) => insur.value === filters.acceptedInsurances[0]
                )?.label || filters.acceptedInsurances[0]
              }
              onDelete={() =>
                onClear((prev) => ({
                  ...prev,
                  acceptedInsurances: [],
                }))
              }
            />
          )}
          <button
            className="hidden lg:block text-primary text-base"
            onClick={() =>
              onClear({
                addressState: "",
                showAvailable: false,
                providerTypes: [],
                focusAreas: [],
              })
            }
          >
            Clear all
          </button>
        </div>
        {!isLoading && (
          <div className="hidden lg:block text-right text-black text-base font-normal">
            {total} result{total !== 1 ? "s" : ""} found
          </div>
        )}
      </div>
      <div className="md:w-[610px] lg:w-auto mx-auto mb-4 lg:hidden flex justify-between">
        <button
          className="lg:hidden text-primary text-base"
          onClick={() =>
            onClear({
              addressState: "",
              showAvailable: false,
              providerTypes: [],
              focusAreas: [],
            })
          }
        >
          Clear all
        </button>
        {!isLoading && (
          <div className="lg:hidden text-right text-black text-base font-normal">
            {total} result{total !== 1 ? "s" : ""} found
          </div>
        )}
      </div>
    </>
  );
}

import { ReactComponent as SuccessIcon } from "../../../../assets/icons/success.svg";

export function QrCodeSuccess() {
  return (
    <div className="flex flex-col items-center justify-center my-12">
      <SuccessIcon />
      <div className="font-bold text-[19px] text-black">Photo uploaded</div>
    </div>
  );
}

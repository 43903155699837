import { useState } from "react";
import { PopupModal } from "../../components/PopupModal";
import { Button } from "../../components/Button";
import { zipCodes } from "../../assets/data/zip_codes";
import ZipCodes from "zipcodes";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { ReactComponent as LocationIcon } from "../../assets/icons/location-target.svg";
import { Loading } from "../../components/Loading/Loading";

export function PromptZipModal({
  onCancel,
  onConfirm,
}: {
  onCancel: () => void;
  onConfirm: (zip: string) => void;

}) {
  const [zip, setZip] = useState("");
  const [locationLoading, setLocationLoading] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const confirmZip = async () => {
    onConfirm(zip);
  };

  const isZipValid = (code: string) => code && (code.trim().length === 5 && !isNaN(parseInt(code.trim(), 10)) && !!zipCodes[code.trim()])
  const allNumsRegex = /^[0-9\b]+$/;
  const handleZipChange = (e) => {
    const value = e.target.value.trim()
    if ((!allNumsRegex.test(value) && value !== "") || value.length > 5) {
      return;
    }
    setZip(value);
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter" && isZipValid(zip)) {
      confirmZip();
    }
  };

  const useCurrentLocation = () => {
    function errorCallback(error) {
      if (error.code === error.PERMISSION_DENIED) {
        setLocationLoading(false)
        setBlocked(true)
      }
    }
    if (navigator.geolocation) {
      setLocationLoading(true)
      navigator.geolocation.getCurrentPosition(function (position) {
        setLocationLoading(false)
        var lat = position.coords.latitude;
        var long = position.coords.longitude;
        const matched = ZipCodes.lookupByCoords(lat, long);
        if (matched && matched.zip) {
          setZip(matched.zip);
          onConfirm(matched.zip);
        }
        setBlocked(false)
      }, errorCallback);
    }
  }

  return (
    <PopupModal contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[442px] max-w-[442px]">
      <div className="flex flex-col w-full">
        <div className="justify-end items-center gap-3 flex">
          <button onClick={onCancel}>
            <CloseIcon className="w-6 h-6" />
          </button>
        </div>
        <p className="text-center text-xl text-black/80 font-bold mb-4">
          Find Providers Near You
        </p>
        <p className="text-center text-l text-black/80 font-medium">
          Please enter your zip code:
        </p>

        <form
          onKeyUp={onKeyPress}
          className="flex flex-col w-full"
        >
          <input
            id="zipInput"
            placeholder="12345"
            value={zip}
            onChange={handleZipChange}
            type="text"
            className="flex-1 !outline-none mx-4 !bg-transparent text-center mt-4 !rounded-full w-[50%] mx-auto border border-black/20 p-1"
          />

          {navigator.geolocation ?
            <p className="text-center text-l font-medium mt-1">
              <span onClick={useCurrentLocation}
                className={`${!locationLoading ? 'cursor-pointer text-primary' : 'text-gray-400'} inline-block`}>
                {locationLoading ? <Loading className="mb-[2px] inline-block" /> : <LocationIcon className="pb-[2px] ml-1 w-5 h-5 inline-block" />}
                {locationLoading ? 'Finding Your Location' : `Use My Location`}
              </span>
              {blocked && <div className="text-sm text-gray-500">(Location service blocked - please enable)</div>}
            </p>
            : null}

          <div className="flex gap-3 items-center w-full mt-6">
            <Button
              onClick={confirmZip}
              className="!rounded-full w-full flex-1 bg-primary text-white"
              variant="primary"
              disabled={!isZipValid(zip)}
            >
              Confirm Zip Code
            </Button>

          </div>
        </form>
      </div>
    </PopupModal >
  );
}

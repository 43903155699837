import { useState } from "react";
import { ShowToast } from "../../../../services/toast";
import { api } from "../../../../services/api";
import { PopupModal } from "../../../../components/PopupModal";
import { Button } from "../../../../components/Button";
import { AuthRequest } from "../RequestAuth.types";

export function CancelAuthRequestConfirm({
  onCancel,
  onDelete,
  request,
}: {
  onCancel: () => void;
  onDelete: () => void;
  request: AuthRequest;
}) {
  const [deleting, setDeleting] = useState(false);
  const performDelete = async () => {
    try {
      setDeleting(true);
      const response = await api.deleteAuthRequest(request.id!);
      if ([200, 201].includes(response.status)) {
        setDeleting(false);
        ShowToast({
          type: "success",
          message: "Auth Request Canceled!",
        });
      }
    } catch (error: any) {
      setDeleting(false);
      ShowToast({
        type: "error",
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
    onDelete()
  };

  return (
    <PopupModal contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[442px] max-w-[442px]">
      <div className="flex flex-col w-full">
        <p className="text-center text-xl text-black/80 font-medium">
          Are you sure you want to cancel Auth Request #{request.friendlyId}?
        </p>
        <div className="flex gap-3 items-center w-full mt-8">
          <Button
            onClick={onCancel}
            variant="outline"
            className="!rounded-full w-full flex-1"
            disabled={deleting}
          >
            Go Back
          </Button>
          <Button
            onClick={performDelete}
            className="!rounded-full w-full flex-1 bg-red-500 text-white"
            variant="primary"
            disabled={deleting}
            loading={deleting}
          >
            Cancel
          </Button>
        </div>
      </div>
    </PopupModal>
  );
}

import { OnboardingScreenWrapper } from "../OnboardingScreenWrapper";

export const ProviderParticipationAgreementScreen = () => {

  return (
    <OnboardingScreenWrapper title={"Provider Participation Agreement"}>
      <>
      </>
    </OnboardingScreenWrapper>
  );
}

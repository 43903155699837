import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getToken, getUser } from "../services/utils";
import { UserProvider } from "./auth/context/user-context";
import { FamilyHeaderMenu } from "./family/components/FamilyHeaderMenu";
import { ProviderMenu } from "./provider/components/ProviderMenu";
import { FamilyRegistration } from "./auth/register/FamilyRegistration";

function MainApp() {
  const user = getUser();

  const isFamily = user.type === "PF";
  const isProvider = user.type === "P";
  return (
    <div
      className="flex-1 flex bg-secondary"
      style={{
        backgroundImage: isFamily ? "url('/images/authpage-overlay.png')" : "",
      }}
    >
      {isFamily && (
        <>
          <FamilyHeaderMenu />
          <div className="flex-1 max-h-screen overflow-auto p-4 lg:p-8 !pt-[105px]">
            <Outlet />
          </div>
        </>
      )}
      {isProvider && (
        <>
          <ProviderMenu />
          <div
            className={`flex-1 max-h-screen overflow-auto p-2 md:p-4 lg:p-8 !pt-[105px] lg:!pt-8`}
            style={{ backgroundImage: "url('/images/provider-bg.png')" }}
          >
            <Outlet />
          </div>
        </>
      )}
    </div>
  );
}

export function Main() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const userData = getUser() || {};

  useEffect(() => {
    const token = getToken();
    const userData = getUser() || {};
    if (!token || !userData?.id) {
      navigate("/login", {
        state: { from: `${location.pathname}${location.search}` },
      });
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
      if (location.pathname === "/") {
        if (userData.type === "PF") {
          navigate("/family/dashboard");
        } else {
          navigate("/provider/dashboard");
        }
      }
      // don't navigate to any provider url if user is a family and vice versa
      // check with first part in url for /provider or /family
      const urlBreakup = location.pathname.split("/");
      if (urlBreakup[1] === "provider" && userData.type === "PF") {
        // replace with family urlBreakup 1
        navigate(
          `${urlBreakup[0]}/family/${urlBreakup.slice(2).join("/")}${location.search
          }`
        );
      } else if (urlBreakup[1] === "family" && userData.type === "P") {
        // replace with provider urlBreakup 1
        navigate(
          `${urlBreakup[0]}/provider/${urlBreakup.slice(2).join("/")}${location.search
          }`
        );
      }
    }
  }, [location]);

  if (!isLoggedIn) return null;

  if (userData.type === "PF" && !userData.isEmailVerified) {
    return <FamilyRegistration defaultStep={1} />;
  }

  return (
    <UserProvider>
      <MainApp />
    </UserProvider>
  );
}

import React, { useCallback, useEffect } from "react";
import { api } from "../../../services/api";
import { ShowToast } from "../../../services/toast";
import { useDebounce } from "../../../services/useDebounce";

export const ProviderContext = React.createContext({});

const LIMIT_ALL = 10_000;
const PAGE_SIZE = 16;

export function Provider({ children }) {
  const [allProviders, setAllProviders] = React.useState([]);
  const [providers, setProviders] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchByZip, setSearchByZip] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [filters, setFilters] = React.useState({
    showAvailable: false,
    providerTypes: [],
    focusAreas: [],
    addressState: "",
    acceptedInsurances: [],
  });
  const [search, setSearch] = React.useState("");
  const [total, setTotal] = React.useState(50);

  // use debounce to avoid making too many requests
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    setProviders(allProviders.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE));
  }, [page, allProviders]);

  const fetchProviders = useCallback(() => {
    setIsLoading(true);
    api
      .getProviders({
        page: 0,
        type: "P",
        ...filters,
        addressZip: searchByZip ? debouncedSearch : undefined,
        limit: LIMIT_ALL,
        searchKeyword: searchByZip ? '' : debouncedSearch,
      })
      .then((response) => {
        setIsLoading(false);
        setAllProviders(response.items);
        setTotal(response.totalRecords);
      })
      .catch((error) => {
        setIsLoading(false);
        ShowToast({
          message: "Something went wrong while fetching providers",
          type: "error",
        });
      });
  }, [filters, searchByZip, debouncedSearch]);

  useEffect(() => {
    fetchProviders();
  }, [fetchProviders]);
  const values = {
    isLoading,
    providers: providers,
    totalPages: Math.ceil(allProviders.length / PAGE_SIZE),
    total,
    setPage,
    setFilters,
    setSearch,
    page,
    search,
    filters,
    searchByZip, setSearchByZip
  };
  return (
    <ProviderContext.Provider value={values}>
      {children}
    </ProviderContext.Provider>
  );
}

export function useProvider() {
  const context = React.useContext(ProviderContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a Provider");
  }
  return context;
}

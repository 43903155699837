import { useNavigate } from "react-router-dom";
import { ReactComponent as SuccessIcon } from "../../../assets/icons/success.svg";
import { Button } from "../../../components/Button";
import { USER_TYPES } from "../../../constant";

export function ForgotPasswordSuccess({
  userType,
  email,
}: {
  userType: string;
  email: string;
}) {
  const navigate = useNavigate();
  const handleLoginClick = () => {
    navigate(userType === USER_TYPES.PROVIDER ? "/provider/login" : "/login");
  };
  return (
    <div className="rounded-lg w-[438px] p-8 bg-white flex flex-col items-center mx-3">
      <SuccessIcon />
      <div className="text-black text-[28px] font-semibold leading-loose text-center mt-1 mb-4">
        Reset link sent
      </div>
      <span className="text-black/80 text-sm font-normal text-center leading-normal ">
        We've sent a link to your registered email address, '{email}'. Please
        click the link and follow the steps to change your password.
      </span>

      <Button
        className="!rounded-full w-full mt-8"
        variant="primary"
        onClick={handleLoginClick}
      >
        Go to login
      </Button>
    </div>
  );
}

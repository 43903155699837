import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./Header";
import { getUser } from "../../../services/utils";
import { UserButton } from "../../../components/UserButton/UserButton";
import { Dropdown } from "../../../components/Dropdown";
import { WEBSITE_URL } from "../../../env";

const framerSidebarBackground = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0, transition: { delay: 0.1 } },
  transition: { duration: 0.3 },
};

const framerSidebarPanel = {
  initial: { x: "-100%" },
  animate: { x: 0 },
  exit: { x: "-100%" },
  transition: { duration: 0.3 },
};

export function MobileHeader({
  onClose,
  className = "",
  overlayClassName = "",
  onProfile,
  onLogout,
  userInfo = {},
}: {
  onClose: () => void;
  className?: string;
  overlayClassName?: string;
  onProfile: () => void;
  onLogout: () => void;
  userInfo: any;
}) {
  const user = getUser();
  const [openDropdown, setOpenDropdown] = useState(false);
  const pathname = window.location.pathname;
  const handleClose = () => {
    onClose();
  };

  const disableScroll = () => {
    document.body.style.overflow = "hidden";
  };

  const enableScroll = () => {
    document.body.style.overflow = "";
  };

  useEffect(() => {
    disableScroll();
    return () => enableScroll();
  }, []);
  const isPatient = user?.type === "PF";
  return (
    <>
      <motion.div
        {...framerSidebarBackground}
        className={`fixed z-[2] top-[78px] left-0 right-0 bottom-0 bg-black/40 ${overlayClassName}`}
        onClick={handleClose}
      />
      <motion.div
        {...framerSidebarPanel}
        className={`fixed z-[3] top-[78px] h-[calc(100%-78px)] left-0 right-0 flex flex-col bg-tintPrimary w-[80%] md:w-[40%] ${className}`}
      >
        <div className="flex-1 justify-start pl-6 flex-col flex">
          {!!user?.id && isPatient ? (
            <>
              <MenuItem
                title="My Chats"
                href="/family/dashboard"
                className="[&>div]:!font-bold"
                active={pathname === "/family/dashboard"}
                isMobile={true}
              />
              <MenuItem
                title="Browse Providers"
                href="/providers"
                className="[&>div]:!font-bold"
                active={pathname === "/providers"}
                isMobile={true}
              />
              {/* <MenuItem
                title="About"
                href={`${WEBSITE_URL}`}
                className="[&>div]:!font-bold"
                active={pathname === "/about"}
                isMobile={true}
              />
              <MenuItem
                title="Contact Us"
                href={`${WEBSITE_URL}/contact-us`}
                className="[&>div]:!font-bold"
                active={pathname === "/contact"}
                isMobile={true}
              /> */}
            </>
          ) : (
            <>
              <MenuItem
                title="About"
                href={`${WEBSITE_URL}`}
                className="[&>div]:!font-bold"
                active={pathname === "/about"}
                isMobile={true}
              />
              <MenuItem
                title="Browse Providers"
                href="/providers"
                className="[&>div]:!font-bold"
                active={pathname === "/providers"}
                isMobile={true}
              />
              <MenuItem
                title="For Providers"
                href={`${WEBSITE_URL}/for-providers`}
                className="[&>div]:!font-bold"
                isMobile={true}
                active={pathname === "/for-providers"}
              />
              <MenuItem
                title="Refer a Client"
                href={`${WEBSITE_URL}/refer-client`}
                className="[&>div]:!font-bold"
                isMobile={true}
                active={pathname === "/refer-client"}
              />
              <MenuItem
                title="Contact Us"
                href={`${WEBSITE_URL}/contact-us`}
                className="[&>div]:!font-bold"
                active={pathname === "/contact"}
                isMobile={true}
              />
            </>
          )}
          {!user?.id ||
            (!isPatient && (
              <>
                <MenuItem
                  title="Family Sign In"
                  href="/login"
                  className="[&>div]:!font-bold"
                  active={pathname === "/login"}
                  isMobile={true}
                />
                <MenuItem
                  title="Provider Sign In"
                  href="/provider/login"
                  className="[&>div]:!font-bold"
                  active={pathname === "/provider/login"}
                  isMobile={true}
                />
              </>
            ))}
        </div>
        {!!user?.id && isPatient && (
          <div className="relative">
            <UserButton
              variant="sideMenu"
              user={user}
              onClick={() => setOpenDropdown(true)}
            />
            <Dropdown
              padding="15px"
              className="w-[calc(100%-32px)] !top-[-100px] left-4 "
              isOpen={openDropdown}
              setIsOpen={() => setOpenDropdown(false)}
            >
              <button
                className="!pb-[2px] text-[#212424] text-base"
                onClick={() => {
                  onClose();
                  onProfile();
                }}
              >
                My Profile
              </button>
              <button
                className="text-[#E3270E] text-base pt-3"
                onClick={() => {
                  onClose();
                  onLogout();
                }}
              >
                Logout
              </button>
            </Dropdown>
          </div>
        )}
      </motion.div>
    </>
  );
}

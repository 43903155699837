
import { OnboardingScreenWrapper } from "../OnboardingScreenWrapper";

export const EntitySetupScreen = () => {

  return (
    <OnboardingScreenWrapper title={"Entity Formation"}>
      <>
      </>
    </OnboardingScreenWrapper>
  );
}

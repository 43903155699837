import { useParams } from "react-router-dom";
import { OnboardingScreen } from "../../../auth/context/user-context";
import { useEffect, useState } from "react";
import { allScreens, getSectionName, isSectionName, sectionNames } from "../ScreenData";



export const OnboardingProgressBar = () => {
  const params = useParams();
  const [labelPositions, setLabelPositions] = useState<Record<string, { top: number; left: number; }>>({ "Getting Started": { top: 0, left: 0 } })

  const calcLabelPositions = () => {
    const positions = sectionNames.map(name => {
      const sectionElem = document.getElementById(`section-${name}`)
      const sectionElmRect = sectionElem?.getBoundingClientRect();
      const label = document.getElementById(`label-${name}`)
      const labelRect = label?.getBoundingClientRect();
      if (sectionElmRect && label && labelRect) {
        return {
          [name]: { top: sectionElmRect.top - 30, left: 15 + sectionElmRect.left - (labelRect.width / 2) }
        }
      } else {
        return {
          [name]: { top: 0, left: 0 }
        }
      }
    })
    setLabelPositions(Object.assign({}, ...positions))
  }

  useEffect(() => {
    calcLabelPositions()
    window.addEventListener('resize', calcLabelPositions);
  }, []);


  const currentScreen = OnboardingScreen.parse(params?.screen ?? OnboardingScreen.CONFIRM_CONTACT_INFO)

  const pageCir = (empty, key) =>
    <div key={key} className="inline-block mx-[3px] sm:mx-2  md:mx-3 xl:mx-5 align-middle" style={{ height: "8px", width: "8px", backgroundColor: empty ? "#FFFFFF" : "#00AE9B", borderRadius: "50%" }} />

  const sectionCir = (empty, name) =>
    <div key={name} id={`section-${name}`} className="inline-block align-middle mx-1" style={{ height: "25px", width: "25px", borderWidth: empty ? "1.5px" : "4px", borderColor: empty ? "#FFFFFF" : "#00AE9B", borderRadius: "50%" }} />


  let completedScreensOrSections: Array<string> = []
  for (const screen of allScreens) {
    const { section } = screen
    if (!completedScreensOrSections.includes(section)) completedScreensOrSections.push(section)
    if (screen.title) completedScreensOrSections.push(screen.title)
    if (screen.screen === currentScreen) break;
  }
  let remainingScreensOrSections: Array<string> = []
  for (const screen of allScreens) {
    if (!screen.title) continue;
    const { section } = screen
    if (completedScreensOrSections.includes(screen.title)) continue;
    if (![...completedScreensOrSections, ...remainingScreensOrSections].includes(section)) {
      remainingScreensOrSections.push(section)
    }
    remainingScreensOrSections.push(screen.title)
    if (screen.screen === currentScreen) break;
  }
  if (!completedScreensOrSections.includes("Launch Your Practice")) {
    remainingScreensOrSections.push("Launch Your Practice")
  }




  return <div className="mb-5 lg:my-10">

    <div className="inline-block rounded-full bg-white" style={{ paddingBottom: "1px" }}>
      <div className="inline-block rounded-full bg-primary" style={{ paddingBottom: "1px" }}>
        {completedScreensOrSections.map((name) => isSectionName(name) ? sectionCir(true, name) : pageCir(true, name))}
      </div>

      <div className="inline-block" style={{ paddingBottom: "1px", marginLeft: '' }}>
        {remainingScreensOrSections.map((name) => isSectionName(name) ? sectionCir(false, name) : pageCir(false, name))}
      </div>
    </div>
    {sectionNames.map((sectionName, i) =>
      <div key={`progressLabel-${i}`} className={`text-primary text-sm absolute hidden lg:block ${sectionName === getSectionName(currentScreen) ? "font-bold" : ""}`} id={`label-${sectionName}`} style={{ top: labelPositions?.[sectionName]?.top, left: labelPositions?.[sectionName]?.left }}>{sectionName}</div>
    )}

  </div >
}
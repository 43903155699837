export enum HowDidYouHearAboutUs {
  HeadstartCareProvider = "Headstart care provider",
  ReferralFromAnotherCareProvider = "Referral from another care provider",
  Insurance = "Insurance",
  SchoolOrDaycare = "School or daycare",
  ParentSupportGroupOrResourceCenter = "Parent support group or resource center",
  Google = "Google",
  SocialMedia = "Social media",
  Other = "Other",
}

import { Loading } from "../Loading/Loading";

type Props = {
  variant: "primary" | "gray" | "outline" | "link";
  loading?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;
export function Button({ variant, className, loading, ...props }: Props) {
  return (
    <button
      className={`transition duration-75 rounded-md text-sm text-white font-medium leading-normal py-3 px-5 flex gap-1.5 items-center justify-center
      ${variant === "outline" ? "border border-primary !text-primary" : ""}
      ${variant === "primary" ? "bg-primary" : ""}
      ${variant === "gray" ? "bg-gray-400" : ""}
      ${variant === "link" ? "!bg-transparent hover:!text-black !p-0" : ""}
      ${props.disabled || loading
          ? "cursor-not-allowed !bg-[#C0C0C0]"
          : "hover:bg-black"
        }
      ${className || ""}
    `}
      {...props}
    >
      {props.children}
      {loading && <Loading />}
    </button>
  );
}

import { useState } from "react";
import Autosuggest from 'react-autosuggest';
import { Button } from "../../../components/Button";
import { PopupModal } from "../../../components/PopupModal";
import { useProvider } from "../../providers/context/provider-context";

export function SelectProviderPopup({
  onBrowserProviders,
  onSchedule,
}: {
  onBrowserProviders: () => void;
  onSchedule: (provider: any) => void;
}) {
  const [loading, setLoading] = useState(false);
  const [providerInMind, setProviderInMind] = useState(false);
  const [autoSuggestRequests, setAutoSuggestRequests] = useState<Array<any> | undefined>()
  const { providers } = useProvider() as any;
  const [selectedText, setSelected] = useState<any>(undefined);
  const [userText, setUserText] = useState('');

  const providerNameList = providers.map((provider: any) => `${provider?.firstName} ${provider?.lastName}`)

  const getProviderSuggestions = value => {
    const inputValue = value?.trim()?.toLowerCase() ?? '';
    const inputLength = inputValue.length;
    if (inputLength === 0) return []
    if (providerNameList.includes(value)) return []

    const matching = providers.filter(provider => provider.isAvailable &&
      `${provider?.firstName} ${provider?.lastName}`.toLowerCase().indexOf(`${inputValue}`) !== -1
    )
    matching.sort((a, b) => `${a?.firstName} ${a?.lastName}`?.localeCompare(`${b?.firstName} ${b?.lastName}`) ?? 0)

    return matching.slice(0, 5)

  };

  return (
    <>
      <PopupModal contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[642px] max-w-[642px]">
        <div className="w-full justify-between items-start flex">
          <div className="text-black text-2xl font-bold font-['Outfit'] leading-7">
            Connect with Provider
          </div>
          {/* <div className="justify-end items-center gap-3 flex">
            <button onClick={onCancel}>
              <CloseIcon className="w-6 h-6" />
            </button>
          </div> */}
        </div>

        <div className="flex flex-col w-full">
          <p className="text-left text-l text-black/80 font-bold font-medium">
            Is there a specific Headstart provider you'd like to be connected with? You can always change your Headstart provider later.
          </p>
          <div className="flex gap-3 items-center w-full mt-8">

            <Button
              onClick={() => {
                setProviderInMind(!providerInMind)
              }}
              variant="primary"
              className="!rounded-full w-full flex-1"
              disabled={loading}
              loading={loading}
            >
              Yes
            </Button>
            <Button
              onClick={(e) => onBrowserProviders()}
              className="!rounded-full w-full flex-1"
              variant={providerInMind ? "outline" : "primary"}
              disabled={loading}
              loading={loading}
            >
              No, I'd like to browse providers
            </Button>

          </div>

          {providerInMind && (<>
            <div className="flex flex-col w-full mt-8">
              <p className="text-left text-l text-black/80 font-bold font-medium">
                Please enter the name of the provider you have in mind.
              </p>
              <Autosuggest

                suggestions={autoSuggestRequests?.filter(r => r.isAvailable).map(p => ({ label: `${p.firstName} ${p.lastName}`, value: p })) || []}
                onSuggestionsFetchRequested={({ value }) => setAutoSuggestRequests(getProviderSuggestions(value))}
                getSuggestionValue={(provider) => provider.label}
                renderSuggestion={(item) => <div className="inline-block border p-1 m-[1px] rounded-md cursor-pointer">{item.label}</div>}
                onSuggestionSelected={(e, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
                  if (suggestionValue) {
                    setSelected(suggestionValue)
                  }
                }}
                inputProps={{
                  className: "pl-3 py-3 !outline-none w-full bg-ultraLightGray focus:bg-white rounded-lg border border-lightGray focus-within:border-grey2 focus:border-grey2 text-base text-black [&>div]:!border-none",
                  placeholder: 'Provider Name',
                  value: userText,
                  onChange: (e, { newValue, method }) => {
                    console.log(newValue)

                    if (providerNameList.includes(newValue)) {
                      setSelected(newValue)
                    } else if (selectedText && (newValue.length !== selectedText.length)) {
                      setSelected(undefined)
                    }
                    setUserText(newValue)
                  }
                }}
              />
              {selectedText && (
                <Button
                  onClick={() => {
                    setLoading(true)
                    const provider = providers.filter(p => `${p.firstName} ${p.lastName}` === selectedText)?.[0]
                    console.log(provider)
                    onSchedule(provider)
                  }}
                  className="!rounded-full w-full flex-1 mt-8"
                  variant={"primary"}
                  disabled={loading}
                  loading={loading}
                >
                  Connect with Provider
                </Button>
              )}

            </div>
          </>
          )}
        </div>
      </PopupModal >
    </>
  );
}

import React from "react";
import { api } from "../../../services/api";
import { ShowToast } from "../../../services/toast";
import passwordValidation from "../../auth/register/Step1";
import { Button } from "../../../components/Button";
import { ResetPasswordSuccess } from "../../auth/components/ResetPasswordSuccess";
import { Password } from "../../auth/components/Password";
import { PopupModal } from "../../../components/PopupModal";
import { ReactComponent as Close } from "../../../assets/icons/x.svg";

export function ChangePassword({
  onClose,
  user,
}: {
  onClose: () => void;
  user: any;
}) {
  const [disabled, setDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [isPasswordSame, setIsPasswordSame] = React.useState<string>("");
  const [errors, setErrors] = React.useState({} as any);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // get the form data
    const formData = new FormData(e.currentTarget);
    // convert the form data to an object
    const data = Object.fromEntries(formData);
    // log the results
    try {
      setLoading(true);
      const response = await api.changePassword(user.id, {
        oldPassword: data.oldPassword,
        newPassword: data.password,
      });
      if ([200, 201].includes(response.status)) {
        ShowToast({
          type: "success",
          message: "Password Changed!",
        });
        setSuccess(true);
      }
      setLoading(false);
    } catch (error: any) {
      ShowToast({
        type: "error",
        message:
          error?.response?.data?.message ||
          "There is some problem to reset password. Please try again",
      });
      setLoading(false);
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData);
    if (
      e.target.name === "password" &&
      !passwordValidation(data.password.toString())
    ) {
      setErrors((prev) => ({
        ...prev,
        password:
          "Password should contain at least 8 characters, 1 letter, 1 number and 1 special character",
      }));
    } else {
      setErrors((prev) => ({ ...prev, password: "" }));
    }
    if (
      e.target.name === "confirmPassword" &&
      !passwordValidation(data.confirmPassword.toString())
    ) {
      setErrors((prev) => ({
        ...prev,
        confirmPassword:
          "Password should contain at least 8 characters, 1 letter, 1 number and 1 special character",
      }));
    } else {
      setErrors((prev) => ({ ...prev, confirmPassword: "" }));
    }
    if (
      data.password &&
      data.confirmPassword &&
      data.password === data.confirmPassword &&
      passwordValidation(data.oldPassword.toString()) &&
      passwordValidation(data.password.toString()) &&
      passwordValidation(data.confirmPassword.toString()) &&
      data.oldPassword !== data.password
    ) {
      setIsPasswordSame("");
      setDisabled(false);
    } else {
      if (
        data.password &&
        data.confirmPassword &&
        data.password !== data.confirmPassword
      ) {
        setIsPasswordSame("Password does not match");
      } else {
        setIsPasswordSame("");
      }
      if (data.oldPassword === data.password) {
        setErrors((prev) => ({
          ...prev,
          password: "New password should be different from old password",
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          password: "",
        }));
      }
      setDisabled(true);
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <PopupModal
      onClose={handleClose}
      contentClassName="p-8 !pt-3 z-[2] bg-white rounded-2xl flex-col justify-start items-center gap-6 flex mx-auto w-[98%] md:w-[442px] max-w-[442px]"
      shouldStopPropagation={false}
    >
      <div className="flex flex-col w-full">
        <div className="flex justify-center items-center w-full">
          {success ? (
            <ResetPasswordSuccess userType={""} />
          ) : (
            <form
              onSubmit={handleSubmit}
              className="flex flex-col w-full"
              onChange={handleChange}
            >
              <div className="rounded-lg flex flex-col">
                <div className="flex justify-between items-center">
                  <div className="text-black text-[28px] font-bold leading-loose">
                    Change Password
                  </div>
                  <button type="button" onClick={handleClose}>
                    <Close className="w-6 h-6 relative" />
                  </button>
                </div>
                <span className="text-black/80 text-sm font-normal leading-normal ">
                  Change your password below.
                </span>
                <div className="my-6 flex flex-col gap-5">
                  <div className="flex flex-col gap-1.5">
                    <span className="text-black text-xs font-medium">
                      Old Password
                    </span>
                    <Password
                      placeholder="Enter old password"
                      className="!p-[14px]"
                      name="oldPassword"
                    />
                    {errors.password && (
                      <div className="text-red-500 text-xs font-medium">
                        {errors.oldPassword}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col gap-1.5">
                    <span className="text-black text-xs font-medium">
                      New Password
                    </span>
                    <Password
                      placeholder="Create a new password"
                      className="!p-[14px]"
                      name="password"
                    />
                    {errors.password && (
                      <div className="text-red-500 text-xs font-medium">
                        {errors.password}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col gap-1.5">
                    <span className="text-black text-xs font-medium">
                      Confirm Password
                    </span>
                    <Password
                      type="password"
                      placeholder="Re-enter the password"
                      className="!p-[14px]"
                      name="confirmPassword"
                    />

                    {isPasswordSame && (
                      <span className="text-red-500 text-xs font-medium">
                        {isPasswordSame}
                      </span>
                    )}
                  </div>
                </div>
                <Button
                  disabled={disabled || loading}
                  loading={loading}
                  className="!rounded-full w-full"
                  variant="primary"
                >
                  Reset
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
    </PopupModal>
  );
}

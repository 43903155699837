import { useState } from "react";
import { Header } from "../components/Header";
import { ProgressStep } from "./ProgressStep";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";
import { api } from "../../../services/api";
import { ShowToast } from "../../../services/toast";
import { ReferralSuccess } from "./ReferralSuccess";



export type ReferralFormType = {

  // Family Information
  parentFirstName: string;
  parentLastName: string;
  clientFirstName: string;
  clientLastName: string;
  clientState: string;
  parentEmail: string;
  parentPhone: string;

  // Provider Information
  providerFirstName: string;
  providerLastName: string;
  providerPracticeName: string;
  providerEmail: string;
  providerPhone: string;

  // Diagnosis Information
  cdeUrl: string;
  comments: string;
}

export function ClientReferral() {
  const [step, setStep] = useState(1);

  const [referralForm, setReferralForm] = useState<ReferralFormType>({
    parentFirstName: "",
    parentLastName: "",
    clientFirstName: "",
    clientLastName: "",
    clientState: "",
    parentEmail: "",
    parentPhone: "",
    providerFirstName: "",
    providerLastName: "",
    providerPracticeName: "",
    providerEmail: "",
    providerPhone: "",
    cdeUrl: "",
    comments: "",
  });

  const handleNext = () => {
    setStep((prev) => prev + 1);
  }

  const handleSubmit = async (form: ReferralFormType) => {
    try {
      const response = await api.submitReferral(form);
      if ([200, 201].includes(response.status)) {
        ShowToast({
          message: "Referral submitted successfully!",
          type: "success",
        });
        setStep(4);
      }
    } catch (error) {
      console.log(error)
      ShowToast({
        message: "Error submitting referral.",
        type: "error",
      });
      setStep(1);
    }
    setReferralForm(form)
  }

  return (
    <div
      className="h-screen bg-secondary overflow-auto"
      style={{ backgroundImage: "url('/images/authpage-overlay.png')" }}
    >
      <Header showSignIn />
      <div className="py-10 px-4 md:px-0">
        <div className="flex justify-center items-center">
          <div className="max-w-[820px] lg:w-[63vw]">
            {step !== 4 && (
              <ProgressStep
                currentStep={step}
              />
            )}
            {step === 1 && (
              <Step1
                onNext={handleNext}
                referralForm={referralForm}
                setReferralForm={setReferralForm}
              />
            )}
            {step === 2 && (
              <Step2
                onNext={handleNext}
                referralForm={referralForm}
                setReferralForm={setReferralForm}
              />
            )}
            {step === 3 && (
              <Step3
                onSubmit={handleSubmit}
                referralForm={referralForm}
                setReferralForm={setReferralForm}
              />
            )}
            {step === 4 && (
              <ReferralSuccess
                referralForm={referralForm}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import { MenuItem } from "./ProviderMenu";
import { ReactComponent as FolderIcon } from "../../../assets/icons/folder.svg";
import { ReactComponent as PersonIcon } from "../../../assets/icons/person.svg";
import { ReactComponent as ToolsIcon } from "../../../assets/icons/tools.svg";
import { ReactComponent as BulletinIcon } from "../../../assets/icons/bulletin.svg";
import { ReactComponent as Add } from "../../../assets/icons/add.svg";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { UserButton } from "../../../components/UserButton/UserButton";
import { getUser } from "../../../services/utils";
import { Dropdown } from "../../../components/Dropdown";

const framerSidebarBackground = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0, transition: { delay: 0.1 } },
  transition: { duration: 0.3 },
};

const framerSidebarPanel = {
  initial: { x: "-100%" },
  animate: { x: 0 },
  exit: { x: "-100%" },
  transition: { duration: 0.3 },
};

export function MobileProviderMenu({
  onClose,
  className = "",
  overlayClassName = "",
  onProfile,
  onLogout,
  userInfo = {},
  patientCount,
}: {
  onClose: () => void;
  className?: string;
  overlayClassName?: string;
  onProfile: () => void;
  onLogout: () => void;
  userInfo: any;
  patientCount: number;
}) {
  const user = getUser();
  const pathname = window.location.pathname;
  const [openDropdown, setOpenDropdown] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const disableScroll = () => {
    document.body.style.overflow = "hidden";
  };

  const enableScroll = () => {
    document.body.style.overflow = "";
  };
  useEffect(() => {
    disableScroll();
    return () => enableScroll();
  }, []);
  return (
    <>
      <motion.div
        {...framerSidebarBackground}
        className={`fixed z-[2] top-[73px] h-[100vh] left-0 right-0 bottom-0 bg-black/40 ${overlayClassName}`}
        onClick={handleClose}
      />
      <motion.div
        {...framerSidebarPanel}
        className={`fixed z-[3] top-[73px] h-[calc(100%-73px)] left-0 right-0 flex flex-col justify-between bg-tintPrimary w-[70%] md:w-[40%] ${className}`}
      >
        <div className="self-stretch grow shrink basis-0 p-3 bg-white border-b border-black border-opacity-10 backdrop-blur-[27px] flex-col justify-start items-start gap-96 flex">
          <div className="self-stretch grow shrink basis-0 flex-col justify-between items-center flex">
            <div
              onClick={handleClose}
              className="w-full flex-col justify-center items-start gap-1 flex"
            >
              <MenuItem
                href="/provider/bulletin"
                title="Bulletin"
                Icon={BulletinIcon}
                active={pathname === "/provider/bulletin"}
              />
              {/* {(process.env.REACT_APP_ENV === "local" ||
                process.env.REACT_APP_ENV === "dev") && (
                  <MenuItem
                    href={"/provider/onboarding"}
                    title="Onboarding"
                    Icon={PersonIcon}
                    active={pathname.startsWith("/provider/onboarding")}
                  />
                )} */}
              <MenuItem
                href="/provider/dashboard"
                title="My Tools"
                Icon={ToolsIcon}
                active={pathname === "/provider/dashboard"}
              />
              <MenuItem
                href="/provider/chat"
                title="Client Chat"
                Icon={PersonIcon}
                active={pathname === "/provider/chat"}
                extra={
                  patientCount ? (
                    <div
                      className={`w-7 h-5 ${pathname === "/provider/chat"
                        ? "text-primary bg-white"
                        : "text-white bg-primary"
                        } rounded-3xl flex-col justify-start items-center gap-2.5 flex`}
                    >
                      <div className="text-sm font-normal">{patientCount}</div>
                    </div>
                  ) : null
                }
              />
              <MenuItem
                href="/provider/library"
                title="My Library"
                Icon={FolderIcon}
                active={pathname === "/provider/library"}
              />
              <MenuItem
                href="/provider/request-rbt"
                title="Request RBT"
                Icon={Add}
                active={pathname === "/provider/request-rbt"}
              />
              <MenuItem
                href="/provider/request-auth"
                title="Request Auth"
                Icon={Add}
                active={pathname === "/provider/request-auth"}
              />
            </div>

            <div className="w-full relative">
              <UserButton
                user={user}
                variant="sideMenu"
                onClick={() => setOpenDropdown(true)}
              />
              <Dropdown
                padding="15px"
                className="w-[calc(100%-32px)] !top-[-100px] left-4"
                isOpen={openDropdown}
                setIsOpen={() => setOpenDropdown(false)}
              >
                <button
                  className="!pb-[2px] text-[#212424] text-base"
                  onClick={() => {
                    onClose();
                    onProfile();
                  }}
                >
                  My Profile
                </button>
                <button
                  className="text-[#E3270E] text-base pt-3"
                  onClick={() => {
                    onClose();
                    onLogout();
                  }}
                >
                  Logout
                </button>
              </Dropdown>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}

import { useCallback, useEffect, useState } from "react";
import { ChatBox } from "../../mailbox/components/ChatBox";
import { ContactList } from "../../mailbox/components/ContactList";
import { MobileChatBox } from "../../mailbox/components/MobileChatBox";
import { AnimatePresence } from "framer-motion";
import { ContactCard } from "../../mailbox/components/ContactCard";
import { useMailBox } from "../../mailbox/context/mailbox-context";
import useResponsive from "../../../services/useResponsive";
import { Loading } from "../../../components/Loading/Loading";
import { ContactRemoveConfirm } from "./components/ContactRemoveConfirm";
import { useApp } from "../../../services/app-context";
import { Button } from "../../../components/Button";
import { DifferentProviderConfirm } from "./components/DifferentProviderConfirm";
import { useNavigate, useSearchParams } from "react-router-dom";

export function FamilyChat() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isSmallDevice } = useResponsive();
  const [mobileChatBox, setMobileChatBox] = useState(false);
  const [confirmRemoveContact, setConfirmRemoveContact] = useState(false);
  const navigate = useNavigate();
  const { selectedProvider, setSelectedProvider } = useApp() as any;
  const {
    selectedContact,
    setSelectedContact,
    contactList,
    sendMessage,
    chatMessages,
    contactListLoading,
    messagesLoading,
    removeSchedule,
    removingSchedule,
    selectedContactStatus,
  } = useMailBox() as any;
  const [oldProviderToLeave, setOldProviderToLeave] = useState<any>(undefined);
  const handleRemoveContact = () => {
    // setConfirmRemoveContact(true);
    onTalkToDifferentProvider()
  };

  useEffect(() => {
    if (isSmallDevice) {
      if (selectedContact?.id) {
        setMobileChatBox(true);
      } else {
        setMobileChatBox(false);
      }
    } else {
      setMobileChatBox(false);
    }
  }, [isSmallDevice, selectedContact]);

  useEffect(() => {
    if (selectedProvider) {
      setSelectedContact(selectedProvider);
      setSelectedProvider(null);
    }
  }, [selectedProvider]);

  const onTalkToDifferentProvider = useCallback(() => {
    const oldProvider = contactList.filter(contact => contact.isAdminUser === false)?.[0]
    setOldProviderToLeave(oldProvider);
  }, [contactList])

  useEffect(() => {
    const openSwitch = async () => {
      const switchProvider = searchParams.get('switch')
      if (switchProvider && switchProvider === 'true') {
        setSelectedProvider(null);
        setSelectedContact(undefined);
        while (!contactList || contactList.length === 0) {
          await new Promise(r => setTimeout(r, 1000));
        }
        onTalkToDifferentProvider()
        setSearchParams(undefined)
      }
    }
    openSwitch()
  }, [contactList, onTalkToDifferentProvider, searchParams, setSearchParams, setSelectedContact, setSelectedProvider]);



  return (
    <div>
      <div className="flex gap-2.5 items-center lg:max-w-[1240px] mx-auto">
        {contactList.length > 0 && (
          <ContactList containerClassName="!flex-1 lg:flex-[0] !h-[calc(100vh-200px)] lg:!h-[calc(100vh-125px)]">
            <div className="p-3 text-[23px] font-bold">Active Chats</div>
            <div className="flex flex-col gap-3 max-h-[calc(100vh-275px)] overflow-auto">
              {contactListLoading && (
                <div className="flex justify-center items-center [&>svg]:w-8 [&>svg]:h-8 mt-16">
                  <Loading />
                </div>
              )}
              {contactList.map((contact: any) => (
                <ContactCard
                  key={contact.id}
                  active={selectedContact?.id === contact.id}
                  data={{
                    ...contact,
                  }}
                  unreadCount={contact?.unReadMessagesCount}
                  cardType="family"
                  onClick={() => {
                    setSelectedContact(contact);
                    if (isSmallDevice) {
                      setMobileChatBox(true);
                    }
                  }}
                />
              ))}
              {contactList.filter(contact => contact.isAdminUser === false).length > 0 &&
                <div className="mt-16 max-w-96 mx-auto">
                  <Button
                    onClick={onTalkToDifferentProvider}
                    className="!rounded-full w-full flex-1"
                    variant="primary"
                  >
                    Switch Your Care Provider
                  </Button>

                </div>
              }
              {contactList.filter(contact => contact.isAdminUser === false).length === 0 &&
                <div className="mt-16 max-w-96 mx-auto">
                  <Button
                    onClick={() => navigate('/providers')}
                    className="!rounded-full w-full flex-1"
                    variant="primary"
                  >
                    Select a Care Provider
                  </Button>

                </div>
              }
            </div>
          </ContactList>
        )}
        <div className="hidden lg:block flex-1">
          <ChatBox
            selectedContact={selectedContact}
            selectedContactStatus={selectedContactStatus}
            messages={chatMessages}
            messagesLoading={messagesLoading}
            containerClassName="h-[calc(100vh-170px)] lg:h-[calc(100vh-125px)]"
            onSend={(data: any) => {
              sendMessage(data);
            }}
            type="PF"
            onRemove={handleRemoveContact}
          />
        </div>
      </div>
      <AnimatePresence mode="wait" initial={false}>
        {mobileChatBox && (
          <MobileChatBox
            onClose={() => setMobileChatBox(false)}
            messages={chatMessages}
            onSend={(data: any) => {
              sendMessage(data);
            }}
            type="PF"
            selectedContact={selectedContact}
            messagesLoading={messagesLoading}
            onRemove={handleRemoveContact}
            selectedContactStatus={selectedContactStatus}
          />
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait" initial={false}>
        {confirmRemoveContact && (
          <ContactRemoveConfirm
            onCancel={() => setConfirmRemoveContact(false)}
            onConfirm={() => {
              removeSchedule(selectedContact.scheduleId).then(() => {
                setConfirmRemoveContact(false);
              });
            }}
            deleting={removingSchedule}
          />
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait" initial={false}>
        {oldProviderToLeave &&
          <DifferentProviderConfirm
            oldProviderId={oldProviderToLeave?.id}
            oldProviderName={`${oldProviderToLeave?.firstName} ${oldProviderToLeave?.lastName}`}
            onCancel={() => setOldProviderToLeave(undefined)}
          />
        }
      </AnimatePresence>


    </div>
  );
}


import { OnboardingScreenWrapper } from "../OnboardingScreenWrapper";

export const BookHeadstartCallScreen = () => {

  return (
    <OnboardingScreenWrapper title={"Book Headstart Call"}>
      <>
      </>
    </OnboardingScreenWrapper>
  );
}

import { motion } from "framer-motion";
import { ReactComponent as CloseIcon } from "../assets/icons/x.svg";
import { useEffect } from "react";

const framerSidebarPanel = {
  initial: { y: "100%" },
  animate: { y: 0 },
  exit: { y: "100%" },
  transition: { duration: 0.1 },
};

export default function Modal({
  children,
  isOpen,
  handleModal,
  title,
  secondaryTitle,
  customTitle,
  zIndex,
  className,
  width,
  noTitle,
  contentClassName = "",
}: {
  children: React.ReactNode;
  isOpen: boolean;
  handleModal?: () => void;
  title?: string;
  secondaryTitle?: React.ReactNode;
  customTitle?: React.ReactNode;
  zIndex?: string;
  className?: string;
  width?: string;
  noTitle?: boolean;
  contentClassName?: string;
}) {
  // stop scroll when modal is open
  const enableScroll = () => {
    document.body.style.overflow = "";
  };
  const disabledScroll = () => {
    document.body.style.overflow = "hidden";
  };

  useEffect(() => {
    if (isOpen) {
      disabledScroll();
    } else {
      enableScroll();
    }
    return () => {
      enableScroll();
    };
  }, [isOpen]);

  return (
    <motion.div
      animate={{
        opacity: isOpen ? 1 : 0,
        display: isOpen ? "block" : "none",
      }}
      exit={{ display: "none" }}
      transition={{ delay: 0.125 }}
      className={className}
    >
      <motion.div
        className={`w-full h-[100vh] fixed top-0 left-0 z-[999] overflow-auto`}
        {...framerSidebarPanel}
      >
        <div
          style={{
            position: "absolute",
            zIndex: zIndex || "101",
            backgroundImage: "url('/images/authpage-overlay.png')",
          }}
          className="bg-[#E8F3F2] top-0 left-0 h-full w-full p-4 md:p-7"
        >
          {!noTitle && (
            <div className="flex justify-between header mb-3 lg:mb-0 lg:absolute lg:right-4">
              {title && <h2 className="text-[1.125rem] font-[700]">{title}</h2>}
              <div className="flex gap-[0.625rem] ml-auto">
                {secondaryTitle && secondaryTitle}
                {handleModal && (
                  <button onClick={handleModal}>
                    <CloseIcon className="w-8 h-8" />
                  </button>
                )}
              </div>
            </div>
          )}
          <div className={`${contentClassName}`}>{children}</div>
        </div>
        {/* all over behind dropdown click closes the dropdown */}
        {isOpen && (
          <div
            className="fixed top-0 left-0 w-full z-[100] h-[100vh] opacity-[0.3] bg-[black]"
            onClick={handleModal}
          />
        )}
      </motion.div>
    </motion.div>
  );
}

export const GOOGLE_OAUTH_CLIENT_ID = "799934358315-8a677t2mrpg56po2okc9jkqa4sgucju2.apps.googleusercontent.com";

export const USER_TYPES = {
  FAMILY: "family",
  PROVIDER: "provider",
};

export const DOCUMENT_OWNER_TYPES = {
  USER: "U",
  ADMIN: "S",
};

export const focusAreas = [
  {
    label: "Naturalistic Developmental Behavioral Interventions (NDBI)",
    value: "Naturalistic Developmental Behavioral Interventions",
  },
  {
    label: "Early Intensive Behavioral Intervention (EIBI)",
    value: "Early Intensive Behavioral Intervention",
  },
  {
    label: "Early Start Denver Model (ESDM)",
    value: "Early Start Denver Model",
  },
  {
    label: "Pivotal Response Treatment (PRT)",
    value: "Pivotal Response Treatment",
  },
  {
    label: "Incidental Teaching (IT)",
    value: "Incidental Teaching",
  },
  {
    label: "Verbal Behavior Intervention (VBI)",
    value: "Verbal Behavior Intervention",
  },
  {
    label: "Social Skills Group",
    value: "Social Skills Group",
  },
  {
    label: "Challenging Behavior",
    value: "Challenging Behavior",
  },
];

export const acceptedInsurances = [
  {
    label: "Aetna",
    value: "Aetna",
  },
  {
    label: "Anthem (Blue Cross Blue Shield)",
    value: "Anthem Blue Cross Blue Shield",
  },
  {
    label: "Evernorth (Cigna)",
    value: "Evernorth Cigna",
  },
  {
    label: "Optum (United Healthcare)",
    value: "Optum United Healthcare",
  },
  {
    label: "Physicians Health Plan",
    value: "Physicians Health Plan",
  },
  {
    label: "Children's Special Health Care Services (CSHCS)",
    value: "CSHCS",
  },
  {
    label: "Anthem (Medicaid)",
    value: "Anthem Medicaid",
  },
  {
    label: "MDwise (Medicaid)",
    value: "MDwise Medicaid",
  },
  {
    label: "Managed Health Services (Medicaid)",
    value: "Managed Health Services Medicaid",
  },
  {
    label: "Optum (Medicaid)",
    value: "Optum Medicaid",
  },
  {
    label: "Amerigroup (GA Medicaid)",
    value: "Amerigroup GA Medicaid",
  },
  {
    label: "Peach State (GA Medicaid)",
    value: "Peach State GA Medicaid",
  },
  {
    label: "Caresource (GA Medicaid)",
    value: "Caresource GA Medicaid",
  },
  {
    label: "Medicaid",
    value: "Medicaid",
  },
].sort((a, b) => a.label.localeCompare(b.label));

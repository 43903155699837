
import { useEffect, useRef, useState } from "react";
import { TextInput } from "../../../../components/TextInput/TextInput";
import { useUser } from "../../../auth/context/user-context";
import { SelectInput } from "../../../../components/SelectInput/SelectInput";
import { getLocationListForSelect, setUser } from "../../../../services/utils";
import { ReactComponent as FilledCheckboxIcon } from "../../../../assets/icons/checkbox-filled.svg";
import { ReactComponent as EmptyCheckboxIcon } from "../../../../assets/icons/checkbox-empty.svg";

import { OnboardingScreenWrapper } from "../OnboardingScreenWrapper";
import { api } from "../../../../services/api";
import { ShowToast } from "../../../../services/toast";

export const ConfirmContactInfoScreen = () => {
  const { userInfo, refreshUserInfo } = useUser();
  const [form, setForm] = useState<{
    firstName: string;
    lastName: string;
    profession: string;
    addressState: string;
    mobile: string;
    email: string;
    confirmContactInfo: boolean;
    loading: boolean;
  }>({
    firstName: userInfo?.firstName,
    lastName: userInfo?.lastName,
    profession: userInfo?.profession?.join(","),
    addressState: userInfo?.addressState,
    mobile: userInfo?.mobile,
    email: userInfo?.email,
    confirmContactInfo: false,
    loading: false
  });


  useEffect(() => {
    setForm({
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
      profession: userInfo?.profession?.join(","),
      addressState: userInfo?.addressState,
      mobile: userInfo?.mobile,
      email: userInfo?.email,
      confirmContactInfo: false,
      loading: false
    })
  }, [userInfo])

  const handleSubmit = async () => {
    setForm({ ...form, loading: true })
    const payload = {
      firstName: form.firstName,
      lastName: form.lastName,
      addressState: form.addressState,
      profession:
        form?.profession?.toString()?.split(",")?.map((p: string) => p.trim()) || [],
      mobile: form.mobile
    };
    try {
      const response = await api.saveUserProfile(userInfo.id, payload);
      const result = response;
      if ([200, 201].includes(result.status)) {
        ShowToast({
          type: "success",
          message: "Contact Information Confirmed",
        });
      }

    } catch (error: any) {
      ShowToast({
        type: "error",
        message: error?.response?.data?.message || "Something went wrong",
      });
      setForm({ ...form, loading: false })
      return false;
    }

    setForm({ ...form, loading: false })
    return true
  }


  return (
    <OnboardingScreenWrapper title={"Contact Information"} disableBack={form.loading} disableNext={!form.confirmContactInfo || form.loading} onNext={handleSubmit}>
      <div className="flex flex-col md:flex-row items-center justify-center gap-3 mb-5">
        <div className="flex-1 w-full flex flex-col gap-1.5">
          <label className="text-sm text-black/80">First Name</label>
          <TextInput
            name="firstName"
            value={form.firstName}
            onChange={(e) =>
              setForm((prev: typeof form) => ({
                ...prev,
                firstName: e.target.value,
              }))}
            placeholder="Enter first name"
            maxLength={128}
            disabled={form.loading}
          />
        </div>
        <div className="flex-1 w-full flex flex-col gap-1.5">
          <label className="text-sm text-black/80">Last Name</label>
          <TextInput
            name="lastName"
            value={form.lastName}
            onChange={(e) =>
              setForm((prev: typeof form) => ({
                ...prev,
                lastName: e.target.value,
              }))}
            placeholder="Enter last name"
            maxLength={128}
            disabled={form.loading}
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-center gap-3 mb-5">
        <div className="flex-1 w-full flex flex-col gap-1.5">
          <label className="text-sm text-black/80">Your Qualifications</label>
          <TextInput
            name="profession"
            value={form.profession}
            onChange={(e) =>
              setForm((prev: typeof form) => ({
                ...prev,
                profession: e.target.value,
              }))}
            placeholder="Enter role"
            maxLength={128}
            disabled={form.loading}
          />
        </div>
        <div className="flex-1 w-full flex flex-col gap-1.5">
          <label className="text-sm text-black/80">Location</label>
          <SelectInput
            data={[...getLocationListForSelect()]}
            value={form.addressState}
            onChange={(e) =>
              setForm((prev: typeof form) => ({
                ...prev,
                addressState: e.target.value,
              }))
            }
            disabled={form.loading}
          />
        </div>
      </div>
      <div className="flex-1 w-full flex flex-col gap-1.5 mb-5">
        <label htmlFor="mobile" className="text-sm text-black/80">
          Phone Number
        </label>
        <TextInput
          type="tel"
          placeholder="Enter your phone number"
          value={form.mobile}
          onChange={(e) =>
            setForm((prev: typeof form) => ({
              ...prev,
              mobile: e.target.value,
            }))}
          name="mobile"
          maxLength={10}
          disabled={form.loading}
        />
      </div>
      <div className="flex-1 w-full flex flex-col gap-1.5 mb-5">
        <label htmlFor="email" className="text-sm text-black/80">
          Email
        </label>
        <TextInput
          type="email"
          value={form.email}
          name="email"
          maxLength={10}
          disabled={true}
        />
      </div>

      <span className="whitespace-nowrap">
        {form.confirmContactInfo ? (
          <FilledCheckboxIcon className="w-5 h-5 inline-block" onClick={() => !form.loading && setForm({ ...form, confirmContactInfo: !form.confirmContactInfo })} />
        ) : <EmptyCheckboxIcon className="w-5 h-5 inline-block" onClick={() => !form.loading && setForm({ ...form, confirmContactInfo: !form.confirmContactInfo })} />}
        <div className="inline-block pl-3 whitespace-normal align-middle">
          I confirm that the above information is correct.
        </div>

      </span>
    </OnboardingScreenWrapper>
  );
}

import Select from "react-select";

export function MultiSelect({
  options,
  onChange,
  value,
  name,
}: {
  options: { label: string; value: string }[];
  onChange: any;
  value: string[];
  name: string;
}) {
  return (
    <div>
      <Select
        isMulti
        name={name}
        options={options}
        value={value.map(
          (v) => options.find((o) => o.value === v) || { label: v, value: v }
        )}
        formatOptionLabel={(data) => data.label}
        classNamePrefix="select"
        isClearable={false}
        onChange={(selectedOptions) => onChange(selectedOptions)}
      />
    </div>
  );
}

import { useSearchParams } from "react-router-dom";
import { ReactComponent as UploadIcon } from "../../assets/icons/upload.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { ReactComponent as LogoIcon } from "../../assets/icons/Logo.svg";
import { useEffect, useState } from "react";
import React from "react";
import { Button } from "../../components/Button";
import { fileUpload } from "../../services/file-upload";
import { api } from "../../services/api";
import { ShowToast } from "../../services/toast";
import { setToken } from "../../services/utils";

function CaptureView({ text, onChange, value, onRemove }) {
  const url = value ? URL.createObjectURL(value) : "";
  const inputRef = React.useRef<HTMLInputElement>(null);
  if (url) {
    return (
      <div className="flex relative flex-col items-center justify-center h-[225px] gap-2 bg-[rgba(196,243,229,0.40)] p-[18px] border border-dashed border-primary rounded-[11px]">
        <div className="relative flex flex-col h-full justify-center">
          <img
            src={url}
            alt="insurance card"
            loading="lazy"
            className="h-auto object-contain max-h-[-webkit-fill-available] rounded-lg border border-[#BFD8BD]"
          />
          <button
            className="bg-tintPrimary rounded-full p-2.5 absolute -right-4 -top-4"
            onClick={onRemove}
          >
            <CloseIcon className="[&_path]:w-[18px] [&_path]:h-[18px]" />
          </button>
        </div>
      </div>
    );
  }
  return (
    <div
      role="button"
      onClick={() => {
        if (inputRef.current) {
          inputRef.current.click();
        }
      }}
      className="flex flex-col cursor-pointer items-center justify-center h-[225px] gap-2 bg-[rgba(196,243,229,0.40)] p-[18px] border border-dashed border-primary rounded-[11px]"
    >
      <div className="bg-white flex justify-center items-center rounded-full w-[56px] h-[56px]">
        <UploadIcon />
      </div>
      <div className="text-center text-base text-black">{text}</div>
      {/** capture input to get image from camera */}
      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        capture="environment"
        onChange={(e) => {
          if (e.target.files && e.target.files[0]) {
            onChange(e.target.files[0]);
          }
        }}
        className="hidden"
      />
    </div>
  );
}

export function QrMobileImageUpload() {
  const [params] = useSearchParams();
  const userId = params.get("userId");
  const token = params.get("t");
  const isPrimary = params.get("isPrimary") === "true";
  const [frontSide, setFrontSide] = useState(null);
  const [backSide, setBackSide] = useState(null);
  const [saving, setSaving] = useState(false);

  const handleUpload = async () => {
    try {
      if (frontSide && backSide && userId && token) {
        // upload the files
        setSaving(true);
        const frontSideResponse = await fileUpload(
          frontSide,
          userId,
          "INSURANCE"
        );
        const backSideResponse = await fileUpload(
          backSide,
          userId,
          "INSURANCE"
        );

        const payload = {
          frontImg: frontSideResponse.url,
          backImg: backSideResponse.url,
          isPrimary,
        };
        const response = await api.saveInsurance(userId, payload);
        setSaving(false);
        if ([200, 201].includes(response.status)) {
          ShowToast({
            message: "Documents uploaded successfully.",
            type: "success",
          });
          setTimeout(() => {
            window.close();
          }, 2000);
        }
      } else {
        setSaving(false);
        ShowToast({
          message:
            "Please upload both front and back side of the insurance card",
          type: "error",
        });
      }
    } catch (error) {
      setSaving(false);
      ShowToast({
        message: "Something went wrong while uploading the files",
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (token) {
      setToken(token);
    }
  }, [token]);

  return (
    <div className="flex flex-col gap-6 p-5">
      <div>
        <LogoIcon />
      </div>
      <div className="flex flex-col gap-5">
        <CaptureView
          text="Upload Insurance Card Photo (Front)"
          onChange={(file) => {
            setFrontSide(file);
          }}
          value={frontSide}
          onRemove={() => setFrontSide(null)}
        />
        <CaptureView
          text="Upload Insurance Card Photo (Back)"
          onChange={(file) => {
            setBackSide(file);
          }}
          value={backSide}
          onRemove={() => setBackSide(null)}
        />
      </div>
      <Button
        onClick={handleUpload}
        variant="primary"
        className="!rounded-full py-5"
        disabled={saving || !frontSide || !backSide}
        loading={saving}
      >
        Upload
      </Button>
    </div>
  );
}

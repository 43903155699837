
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../components/Button/Button";
import { OnboardingScreen, useUser } from "../../../auth/context/user-context";
import { api } from "../../../../services/api";

export const NextBackBottomNav = ({ disableNext, disableBack, onNext }: { disableNext?: boolean; disableBack?: boolean; onNext?: () => Promise<boolean>; }) => {
  const { refreshUserInfo } = useUser();
  const navigate = useNavigate();
  const params = useParams();
  const currentScreen = OnboardingScreen.parse(params?.screen);

  let nextScreen = currentScreen && OnboardingScreen.nextScreen(currentScreen)
  let previousScreen = currentScreen && OnboardingScreen.previousScreen(currentScreen)

  const progressToNextScreen = async () => {
    if (currentScreen) {
      currentScreen && (await api.actionCompletedOnboardingScreen(currentScreen))
      await refreshUserInfo()
      navigate(`/provider/onboarding/${nextScreen}`)
    }
  }

  return (
    <div className="mt-2 grid grid-cols-2 gap-4 flex">
      <Link reloadDocument={!previousScreen} to={`/provider/onboarding/${previousScreen ?? ''}`} >
        <Button
          // onClick={onBack}
          variant="primary"
          className="!rounded-full w-full mt-5 max-w-40"
          disabled={disableBack}
        >
          <div className="font-normal text-l">
            Back
          </div>
        </Button>
      </Link>

      <Button
        onClick={async (e) => {
          e.preventDefault()
          if (onNext && !(await onNext())) return;
          progressToNextScreen()
        }}
        variant="primary"
        className="!rounded-full w-full mt-5 max-w-40 ml-auto"
        disabled={!nextScreen || disableNext}
      >
        <div className="font-normal text-l">
          Next
        </div>
      </Button>
    </div>);
}

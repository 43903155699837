
import { useEffect, useState } from "react";
import { TextInput } from "../../../../components/TextInput/TextInput";
import { useUser } from "../../../auth/context/user-context";
import { SelectInput } from "../../../../components/SelectInput/SelectInput";
import { getLocationListForSelect } from "../../../../services/utils";
import { ReactComponent as FilledCheckboxIcon } from "../../../../assets/icons/checkbox-filled.svg";
import { NextBackBottomNav } from "../components/NextBackBottomNav";
import { OnboardingScreenWrapper } from "../OnboardingScreenWrapper";

export const CredentialingScreen = () => {
  const { userInfo } = useUser();
  const [form, setForm] = useState<any>({});

  useEffect(() => {
    setForm({
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
      profession: userInfo.profession,
      addressState: userInfo?.addressState,
      mobile: userInfo?.mobile,
      email: userInfo?.email,
    })
  }, [userInfo])

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setForm((prev: typeof form) => ({ ...prev, [name]: value }));
  };


  return (
    <OnboardingScreenWrapper title={"Credentialing Setup"}>
      <>
      </>
    </OnboardingScreenWrapper>
  );
}


import { OnboardingScreenWrapper } from "../OnboardingScreenWrapper";

export const MSAScreen = () => {

  return (
    <OnboardingScreenWrapper title={"Master Service Agreement"}>
      <>
      </>
    </OnboardingScreenWrapper>
  );
}

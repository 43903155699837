import { Button } from "../../../components/Button";
import { PopupModal } from "../../../components/PopupModal";

export function LogoutConfirm({
  onConfirm,
  onCancel,
}: {
  onConfirm: () => void;
  onCancel: () => void;
}) {
  return (
    <PopupModal contentClassName="p-8 z-[2] w-[95%] md:w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[442px] max-w-[442px]">
      <div className="flex flex-col w-full">
        <p className="text-center text-xl text-black/80 font-medium">
          Are you sure you want to logout?
        </p>
        <div className="flex gap-3 items-center w-full mt-8">
          <Button
            onClick={onCancel}
            variant="outline"
            className="!rounded-full w-full flex-1"
          >
            Go Back
          </Button>
          <Button
            onClick={onConfirm}
            className="!rounded-full w-full flex-1"
            variant="primary"
          >
            Logout
          </Button>
        </div>
      </div>
    </PopupModal>
  );
}

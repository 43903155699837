import { PopupModal } from "../../../../components/PopupModal";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/x.svg";
import { ReactComponent as PinIcon } from "../../../../assets/icons/pin.svg";
import { TextInput } from "../../../../components/TextInput";
import React from "react";
import { FileUploader } from "react-drag-drop-files";
import { Button } from "../../../../components/Button";
import { useFiles } from "../context/file-context";

export function CreateFolderDialog({ onClose }) {
  const { createFolder, creatingFolder, currentFolder, allFiles } =
    useFiles() as any;
  const [folderName, setFolderName] = React.useState<string>("");
  const [errors, setErrors] = React.useState<any>({});
  const [files, setFiles] = React.useState<File[]>([]);

  const handleFileChange = (fileList) => {
    setFiles(fileList);
  };

  const handleSubmit = async () => {
    if (!folderName) {
      setErrors({ ...errors, folderName: "Please enter folder name" });
      return;
    }
    await createFolder(folderName, files);
    onClose();
  };

  const list = currentFolder ? currentFolder.children || [] : allFiles || [];
  const isDuplicateFolder = !!list?.find(
    (folder) => folder.name?.toLowerCase() === folderName?.toLowerCase()
  );
  const disabled = !folderName || isDuplicateFolder;
  return (
    <PopupModal
      contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[442px] max-w-[442px]"
      onClose={onClose}
      shouldStopPropagation={false}
    >
      <>
        <div className="w-full justify-between items-start flex">
          <div className="text-black text-2xl font-bold font-['Outfit'] leading-7">
            Create a folder
          </div>
          <div className="justify-end items-center gap-3 flex">
            <button onClick={onClose}>
              <CloseIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
        <div className="w-full">
          <div className="flex flex-col gap-1.5">
            <span className="text-black/80 text-sm">Folder Name</span>
            <TextInput
              placeholder="Enter Folder Name"
              className="!p-[14px]"
              name="folderName"
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
            />
            {isDuplicateFolder ? (
              <div className="text-red-500 text-xs font-medium">
                Duplicate Folder name not allowed
              </div>
            ) : (
              errors.folderName && (
                <div className="text-red-500 text-xs font-medium">
                  {errors.folderName}
                </div>
              )
            )}
          </div>
          <div className="flex flex-col gap-1.5 mt-[22px] mb-8">
            <span className="text-black/80 text-sm">Add Documents/Images</span>
            <FileUploader handleChange={handleFileChange} name="file" multiple>
              <div className="cursor-pointer p-4 bg-[#E8F3F2] w-full rounded-xl border border-primary justify-center items-center gap-1 flex">
                <PinIcon className="w-5 h-5" />
                <div className="text-primary text-base font-normal font-['Outfit'] leading-normal">
                  {files.length > 0
                    ? `${files.length} files added`
                    : "Click to add or drag and drop"}
                </div>
              </div>
            </FileUploader>
            {errors.files && (
              <div className="text-red-500 text-xs font-medium">
                {errors.files}
              </div>
            )}
          </div>
          <Button
            disabled={disabled || creatingFolder}
            variant="primary"
            className="!rounded-full w-full"
            onClick={handleSubmit}
            loading={creatingFolder}
          >
            Create
          </Button>
        </div>
      </>
    </PopupModal>
  );
}


import { NextBackBottomNav } from "./components/NextBackBottomNav";
import { OnboardingProgressBar } from "./components/OnboardingProgressBar";

export const OnboardingScreenWrapper = ({ title, children, disableNext, disableBack, onNext }:
  { title: string; children: any; disableNext?: boolean; disableBack?: boolean; onNext?: () => Promise<boolean>; }) => {

  return (
    <div className="text-center">
      <div
        className={`h-full inline-block md:w-full`}
        style={{ minHeight: "300px", width: "90%", maxWidth: "800px" }}
      >
        <OnboardingProgressBar />
        <div className="bg-white rounded-[16px] pb-6 flex flex-row justify-center items-center pt-1">
          <div style={{ position: "relative", width: "90%", maxWidth: "750px", textAlign: "left" }}>
            <div className="text-black text-3xl font-bold text-center mb-3 mt-5 text-left" style={{ textAlign: "left" }}>{title}</div>
            {children}
          </div>
        </div>
        <NextBackBottomNav disableBack={disableBack} disableNext={disableNext} onNext={onNext} />
      </div>

    </div >
  );
}

import PaginationComponent from "../../../components/Pagination/Pagination";
import { ProviderCard } from "./ProviderCard";

export function ProviderList({
  providers = [],
  page,
  onPageChange,
  onView,
  onSchedule,
  totalPages,
}) {
  return (
    <div>
      <div className="flex flex-wrap gap-5 justify-center">
        {providers.map((provider: any) => (
          <ProviderCard
            key={provider.id}
            provider={provider}
            onClick={() => onSchedule(provider)}
            onView={onView}
          />
        ))}
      </div>
      <div className="mt-16 flex justify-center">
        <PaginationComponent
          total={totalPages}
          currentPage={page}
          onPageChange={onPageChange}
          className="[&_ul]:flex [&_ul]:gap-2.5 [&_li]:bg-[#E3F1F0] hover:[&_li]:text-white hover:[&_li]:bg-black [&_.previous]:!bg-transparent [&_.next]:!bg-transparent [&_li>a]:flex [&_li>a]:justify-center [&_li>a]:items-center [&_li]:rounded-full [&_li>a]:w-11 [&_li>a]:h-11  [&_.active]:!text-white/80 [&_li_a]:text-sm [&_li_a]:font-medium [&_.active]:!bg-primary"
        />
      </div>
    </div>
  );
}

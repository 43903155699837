import { ReactComponent as SuccessIcon } from "../../../assets/icons/success.svg";
import { ReferralFormType } from "./ClientReferral";

export function ReferralSuccess({ referralForm }: { referralForm: ReferralFormType; }) {

  return (
    <>
      <div className="bg-white p-8 rounded-[16px]">
        <div>
          <h1 className="text-3xl font-bold mb-5">Client Referral - ABA Therapy</h1>
        </div>
        <div className="flex flex-col items-center">
          <SuccessIcon className="w-64 h-auto max-h-96 mx-auto my-4" />
          <h2 className="text-2xl font-semibold mb-2">Referral Submitted Successfully!</h2>
          <p className="text-center mb-4">
            Thank you for your referral. Our team will review the information and be in touch with {referralForm.parentFirstName ?? ''} {referralForm.parentLastName ?? ''} shortly.
          </p>

          <a href="https://headstart.health" className="underline">Return to Homepage</a>
        </div>
      </div>
    </>
  );
}

import { OnboardingScreen } from "../../auth/context/user-context";
import { BankAccountSetupScreen } from "./screens/BankAccountSetup";
import { BookHeadstartCallScreen } from "./screens/BookHeadstartCall";
import { ConfirmContactInfoScreen } from "./screens/ConfirmContactInfo";
import { CredentialingScreen } from "./screens/Credentialing";
import { EntitySetupScreen } from "./screens/EntitySetup";
import { JoinderAgreementScreen } from "./screens/JoinderAgreement";
import { LaunchYourPracticeScreen } from "./screens/LaunchYourPractice";
import { MSAScreen } from "./screens/MSA";
import { ProfileSetupScreen } from "./screens/ProfileSetup";
import { ProviderParticipationAgreementScreen } from "./screens/ProviderParticipationAgreement";
import { SetupCompleteScreen } from "./screens/SetupComplete";
import { SoftwareOnboardingScreen } from "./screens/SoftwareOnboarding";
import { TransitionToLegalSetupScreen } from "./screens/TransitionToLegalSetup";
import { TransitionToPracticeSystemSetupScreen } from "./screens/TransitionToPracticeSystemSetup";
import { UploadEntityDocumentsScreen } from "./screens/UploadEntityDocuments";
import { UploadResumeScreen } from "./screens/UploadResume";
import { InsuranceSetupScreen } from "./screens/UploadResumeInsuranceSetup";

type ScreenList = Array<{
  screen: OnboardingScreen;
  title?: string;
  component?: () => JSX.Element;
}>;

export type ScreenListWithSectionName = Array<{
  screen: OnboardingScreen;
  title?: string;
  component?: () => JSX.Element;
  section: string;
}>;

export const gettingStarted: ScreenList = [
  {
    screen: OnboardingScreen.CONFIRM_CONTACT_INFO,
    title: "Confirm Contact Information",
    component: ConfirmContactInfoScreen,
  },
  {
    screen: OnboardingScreen.CREDENTIALING_SETUP,
    title: "Credentialing Setup",
    component: CredentialingScreen,
  },
  { screen: OnboardingScreen.UPLOAD_RESUME, title: " Upload Resume", component: UploadResumeScreen },
  { screen: OnboardingScreen.MSA, title: "Master Service Agreement", component: MSAScreen },
  { screen: OnboardingScreen.BOOK_HEADSTART_CALL, title: "Book Headstart Call", component: BookHeadstartCallScreen },
];

export const legalSetup: ScreenList = [
  { screen: OnboardingScreen.TRANSITION_ENTITY_SET_UP, title: undefined, component: TransitionToLegalSetupScreen },
  { screen: OnboardingScreen.ENTITY_SETUP, title: "Entity Setup", component: EntitySetupScreen },
  {
    screen: OnboardingScreen.UPLOAD_ENTITY_DOCUMENTS,
    title: "Upload Entity Documents",
    component: UploadEntityDocumentsScreen,
  },
  { screen: OnboardingScreen.JOINDER_AGREEMENT, title: "Joinder Agreement", component: JoinderAgreementScreen },
  {
    screen: OnboardingScreen.PROVIDER_PARTICIPATION_AGREEMENT,
    title: "Provider Participation Agreement",
    component: ProviderParticipationAgreementScreen,
  },
];

export const practiceSystemsSetup: ScreenList = [
  {
    screen: OnboardingScreen.TRANSITION_PRACTICE_SYSTEMS_SETUP,
    title: undefined,
    component: TransitionToPracticeSystemSetupScreen,
  },
  { screen: OnboardingScreen.BANK_ACCOUNT_SETUP, title: "Bank Account Setup", component: BankAccountSetupScreen },
  { screen: OnboardingScreen.INSURANCE_SETUP, title: "Insurance Setup", component: InsuranceSetupScreen },
  { screen: OnboardingScreen.PROFILE_SETUP, title: "Profile Setup", component: ProfileSetupScreen },
  { screen: OnboardingScreen.SOFTWARE_ONBOARDING, title: "Software Onboarding", component: SoftwareOnboardingScreen },
  {
    screen: OnboardingScreen.SET_UP_COMPLETE,
    title: undefined,
    component: SetupCompleteScreen,
  },
];

export const allScreens: ScreenListWithSectionName = [
  ...gettingStarted.map((screen) => ({ ...screen, section: "Getting Started" })),
  ...legalSetup.map((screen) => ({ ...screen, section: "Legal Setup" })),
  ...practiceSystemsSetup.map((screen) => ({ ...screen, section: "Practice Systems Setup" })),
  {
    screen: OnboardingScreen.LAUNCH_YOUR_PRACTICE,
    title: undefined,
    component: LaunchYourPracticeScreen,
    section: "Launch Your Practice",
  },
];

export const getSectionName = (screen: OnboardingScreen | undefined) => {
  if (!screen) return undefined;
  return allScreens.filter((s) => s.screen === screen)[0].section;
};

export const sectionNames = ["Getting Started", "Legal Setup", "Practice Systems Setup", "Launch Your Practice"];

export const isSectionName = (name: string) => sectionNames.includes(name);

import { useEffect, useState } from "react";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { ReactComponent as RightIcon } from "../../../assets/icons/right-chevron.svg";
import { ListView } from "./components/ListView";
import { useBulletins } from "./context/bulletin-context";

export function BulletinList() {
  const { bulletins, refreshBulletins } = useBulletins();
  const [searchKeyword, onSearch] = useState("");

  useEffect(() => {
    refreshBulletins()
  }, [refreshBulletins])

  const handleSearchChange = (e: any) => {
    onSearch(e.target.value);
  };

  const filteredBulletins = bulletins.filter((b) =>
    b.description.toLowerCase().includes(searchKeyword.toLowerCase())
    || b.title.toLowerCase().includes(searchKeyword.toLowerCase())
    || b.attachedDocs?.map(d => d.name).join().toLowerCase().includes(searchKeyword.toLowerCase())
  );

  return (
    <div>
      <div className="mb-7 flex flex-col gap-5 lg:gap-0 lg:flex-row justify-between lg:items-center">
        <div className="flex justify-between lg:justify-start lg:items-center gap-5">
          <span className="text-black text-3xl font-bold ">Bulletin</span>
          <div className="hidden lg:block absolute right-10">
            {/** search bar */}
            <div className="relative">
              <div className="absolute left-3 top-4">
                <SearchIcon className="w-5 h-5 text-black/60 [&_path]:stroke-[#8D8E92]" />
              </div>
              <input
                type="text"
                value={searchKeyword}
                onChange={handleSearchChange}
                placeholder="Search"
                className="w-full py-3 pl-10 pr-4 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
              />
            </div>
          </div>
        </div>
        <div className="flex-1 flex gap-2.5 justify-end flex-wrap">
          <div className="lg:hidden w-full">
            {/** search bar */}
            <div className="relative">
              <div className="absolute left-3 top-4">
                <SearchIcon className="w-5 h-5 text-black/60 [&_path]:stroke-[#8D8E92]" />
              </div>
              <input
                type="text"
                value={searchKeyword}
                onChange={handleSearchChange}
                placeholder="Search"
                className="w-full py-3 pl-10 pr-4 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center flex-wrap gap-2">
        {searchKeyword && (
          <>
            <button
              onClick={() => onSearch("")}
              className="text-primary hover:text-black font-medium ml-5 text-lg"
            >
              Search
            </button>
            <div>
              <RightIcon className="w-6 h-6" />
            </div>
            <div>
              {searchKeyword}
            </div>
          </>
        )}
      </div>
      <div className="mt-5">
        <ListView bulletins={filteredBulletins || []} />
      </div>
    </div>
  )
};

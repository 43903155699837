export enum LeaveProviderReason {
  SCHEDULE_AVAILABILITY = "SCHEDULE_AVAILABILITY",
  INSURANCE_COVERAGE = "INSURANCE_COVERAGE",
  GEOGRAPHY_LOCATION = "GEOGRAPHY_LOCATION",
  UNRESPONSIVE_PROVIDER = "UNRESPONSIVE_PROVIDER",
  NOT_GOOD_FIT = "NOT_GOOD_FIT",
  OTHER = "OTHER",
}

export const prettyLeaveReason = (reason: LeaveProviderReason) => {
  switch (reason) {
    case LeaveProviderReason.SCHEDULE_AVAILABILITY:
      return "Schedule availability";
    case LeaveProviderReason.INSURANCE_COVERAGE:
      return "Insurance coverage";
    case LeaveProviderReason.GEOGRAPHY_LOCATION:
      return "Geography / location";
    case LeaveProviderReason.UNRESPONSIVE_PROVIDER:
      return "Provider was unresponsive";
    case LeaveProviderReason.NOT_GOOD_FIT:
      return "Provider was not a good fit";
    case LeaveProviderReason.OTHER:
      return "Other";
  }
};

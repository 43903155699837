import { ReactComponent as Logo } from "../../../assets/icons/Logo.svg";
import { MenuItem } from "./Header";
import { WEBSITE_URL } from "../../../env";

import "./footer.css";

export function Footer() {
  return (
    <div className="flex-col justify-start items-center gap-16 flex">
      <div className="w-full px-5 md:px-14 py-10 border-t border-neutral-300 flex-col justify-start items-center gap-6 flex">
        <div className="flex-col justify-start items-center gap-9 flex">
          <div className="w-48 h-9 justify-center items-center gap-2 inline-flex">
            <a href={`${WEBSITE_URL}`} rel="noreferrer">
              <Logo />
            </a>
          </div>
          <div className="justify-center md:justify-start items-start gap-5 md:gap-12 flex flex-wrap">
            <MenuItem title="About" href={`${WEBSITE_URL}`} />
            <MenuItem title="Browse Providers" href="/providers" />
            <MenuItem
              title="For Providers"
              href={`${WEBSITE_URL}/for-providers`}
            />
            <MenuItem title="Contact Us" href={`${WEBSITE_URL}/contact-us`} />
          </div>
        </div>
        <div className="footer-menu-list socials">
          <a id="linkedIn" href="https://www.linkedin.com/company/headstart-hlth/" target="_blank" className="w-inline-block">
            <img loading="lazy" src="https://cdn.prod.website-files.com/65ea96b8c2677fa90abbd37f/66d9d16a3cdea58cfffed08f_linkedin-icon-svgrepo-com.svg" alt="" className="image-6" />
          </a>
          <a id="linkedIn" href="https://www.facebook.com/profile.php?id=61561059007520" target="_blank" className="link-block-2 w-inline-block">
            <img loading="lazy" src="https://cdn.prod.website-files.com/65ea96b8c2677fa90abbd37f/66d9cff7300a4834d6ffe148_facebook-color-svgrepo-com.svg" alt="" className="image-6 fbimg" />
          </a>
        </div>
        <div className="border-b border-[#DDD] w-full"></div>

        <div className="text-neutral-800 text-base font-medium justify-center">
          ©Headstart Health, Inc. 2024.
        </div>

      </div>
    </div>
  );
}

import { AnimatePresence } from "framer-motion";
import { ReactComponent as Logo } from "../../../assets/icons/Logo.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { ReactComponent as HamburgerIcon } from "../../../assets/icons/hamburg.svg";
import { MenuItem } from "../../providers/components/Header";
import { MobileFamilyHeaderMenu } from "./MobileFamilyHeaderMenu";
import { useEffect, useState } from "react";
import { UserButton } from "../../../components/UserButton/UserButton";
import { clearCookies, getUser } from "../../../services/utils";
import { FamilyProfile } from "../profile/FamilyProfile";
import { ChangePassword } from "../profile/ChangePassword";
import { Dropdown } from "../../../components/Dropdown";
import { LogoutConfirm } from "./LogoutConfirm";
import { DeleteConfirm } from "./DeleteConfirm";
import { api } from "../../../services/api";
import { useUser } from "../../auth/context/user-context";

export function FamilyHeaderMenu() {
  const pathname = window.location.pathname;
  const { userInfo = {}, setUserInfo } = useUser() as any;
  const user = getUser() || {};
  const [secondaryInsuranceData, setSecondaryInsuranceData] = useState(
    {} as any
  );
  const [insuranceData, setInsuranceData] = useState({} as any);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const [logoutConfirm, setLogoutConfirm] = useState(false);

  const handleLogoutConfirm = () => {
    setLogoutConfirm(false);
    clearCookies();

    window.location.href = "/login";
  };

  const handleLogout = () => {
    setIsProfileOpen(false);
    setOpenDropdown(false);
    setLogoutConfirm(true);
  };

  const handleEditProfile = () => {
    setShowEditProfile(true);
  };

  const handleChangePassword = () => {
    setShowChangePassword(true);
    setIsProfileOpen(false);
  };

  const handleDeleteAccount = () => {
    setIsProfileOpen(false);
    setShowDeleteAccount(true);
  };

  const handleDeleteAccountClose = () => {
    setShowDeleteAccount(false);
  };

  const handleClose = () => {
    setIsSideMenuOpen(false);
  };

  const toogleSideMenu = () => {
    setIsSideMenuOpen((open) => !open);
  };

  const fetchInsuranceData = () => {
    api.getInsurance(user.id).then((response) => {
      setSecondaryInsuranceData(
        (response?.data || []).find((e) => e.isActive && !e.isPrimary) || {}
      );
      setInsuranceData(
        (response?.data || []).find((e) => e.isActive && e.isPrimary) || {}
      );
    });
  };

  useEffect(() => {
    if (user?.id) {
      api.getUserProfile(user.id).then((response) => {
        setUserInfo(response);
      });
      fetchInsuranceData();
    }
  }, [user?.id]);

  return (
    <>
      <div className="fixed z-[1] top-0 left-0 right-0 px-4 lg:pl-10 lg:pr-5 py-[18px] bg-white border-b border-black border-opacity-10 backdrop-blur-3xl justify-between items-center flex">
        <a href="/" rel="noreferrer">
          <Logo />
        </a>
        <div className="justify-end items-center gap-3 flex flex-1">
          <div className="justify-start items-center hidden lg:flex">
            <MenuItem
              active={pathname === "/family/dashboard"}
              title="My Chats"
              href="/family/dashboard"
            />
            <MenuItem
              active={pathname === "/providers"}
              title="Browse Providers"
              href="/providers"
            />
          </div>
          <div className="hidden lg:block relative">
            <UserButton
              user={{ ...user, ...userInfo }}
              onClick={() => setOpenDropdown(true)}
            />

            <Dropdown
              padding="15px"
              className="!mt-[26px] w-[110px]"
              isOpen={openDropdown}
              setIsOpen={() => setOpenDropdown(false)}
            >
              <button
                className="!pb-[2px] text-[#212424] text-base"
                onClick={() => {
                  setOpenDropdown(false);
                  setIsProfileOpen(true);
                }}
              >
                My Profile
              </button>
              <button
                className="text-[#E3270E] text-base pt-3"
                onClick={handleLogout}
              >
                Logout
              </button>
            </Dropdown>
          </div>

          <button className="lg:hidden" onClick={toogleSideMenu}>
            {isSideMenuOpen ? (
              <CloseIcon className="h-8 w-8 [&_path]:stroke-primary" />
            ) : (
              <HamburgerIcon />
            )}
          </button>
        </div>
      </div>
      <div className="lg:hidden">
        {isSideMenuOpen && (
          <MobileFamilyHeaderMenu
            onClose={handleClose}
            userInfo={{ ...user, ...userInfo }}
            onLogout={handleLogout}
            onProfile={() => {
              setOpenDropdown(false);
              setIsProfileOpen(true);
            }}
          />
        )}
      </div>
      <AnimatePresence mode="wait" initial={false}>
        {isProfileOpen && (
          <FamilyProfile
            user={{ ...user, ...userInfo }}
            onClose={() => setIsProfileOpen(false)}
            onChangePassword={handleChangePassword}
            onEdit={handleEditProfile}
            onDelete={handleDeleteAccount}
            onLogout={handleLogout}
            insurance={insuranceData}
            secondaryInsurance={secondaryInsuranceData}
            edit={showEditProfile}
            onEditClose={() => setShowEditProfile(false)}
            fetchInsuranceData={fetchInsuranceData}
          />
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait" initial={false}>
        {showChangePassword && (
          <ChangePassword
            onClose={() => setShowChangePassword(false)}
            user={user}
          />
        )}
      </AnimatePresence>

      <AnimatePresence mode="wait" initial={false}>
        {logoutConfirm && (
          <LogoutConfirm
            onCancel={() => setLogoutConfirm(false)}
            onConfirm={handleLogoutConfirm}
          />
        )}
      </AnimatePresence>

      <AnimatePresence mode="wait" initial={false}>
        {showDeleteAccount && (
          <DeleteConfirm onCancel={handleDeleteAccountClose} userId={user.id} />
        )}
      </AnimatePresence>
    </>
  );
}

import React, { useEffect, useMemo, useState } from "react";
import { api } from "../../../../services/api";
import { getUser } from "../../../../services/utils";

export type BulletinDoc = {
  id: string;
  name: string;
  type: "youtubelink" | "file" | "folder";
  mimeType: string;
  url?: string;
  createdAt: Date;
}

export type Bulletin = {
  id: string;
  title: string;
  description: string;
  createdAt: Date;
  updatedAt?: Date;
  isActive: boolean;
  previewImg?: string;
  attachedDocs?: Array<BulletinDoc>;
};


export const BulletinContext = React.createContext<{ bulletins: Array<Bulletin>; refreshBulletins: () => {} }>({
  bulletins: [],
  refreshBulletins: () => ({})
});

export function BulletinProvider({ children }) {
  const user = getUser();
  const [bulletins, setBulletins] = useState<Array<Bulletin>>([]);

  const fetchBulletins = useMemo(() => async () => {
    setBulletins(await api.getBulletins())
  }, []);

  useEffect(() => {
    if (user?.id && user?.type === 'P') {
      fetchBulletins();
    }
  }, [user?.id]);

  const values = useMemo(() => ({
    bulletins,
    refreshBulletins: fetchBulletins,
  }), [bulletins, fetchBulletins]);

  return <BulletinContext.Provider value={values}>{children}</BulletinContext.Provider>;
}

export function useBulletins() {
  const context = React.useContext<{ bulletins: Array<Bulletin>; refreshBulletins: () => {} }>(BulletinContext);
  if (context === undefined) {
    throw new Error("useBulletins must be used within a BulletinProvider");
  }
  return context;
}

import { Button } from "../../../../components/Button";
import { PopupModal } from "../../../../components/PopupModal";

export function ContactRemoveConfirm({
  onCancel,
  deleting,
  onConfirm,
}: {
  onCancel: () => void;
  deleting: boolean;
  onConfirm: () => void;
}) {
  return (
    <PopupModal contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[442px] max-w-[442px]">
      <div className="flex flex-col w-full">
        <p className="text-center text-xl text-black/80 font-medium">
          Are you sure you want to remove convesation?
        </p>
        <div className="flex gap-3 items-center w-full mt-8">
          <Button
            onClick={onCancel}
            variant="outline"
            className="!rounded-full w-full flex-1"
            disabled={deleting}
          >
            Go Back
          </Button>
          <Button
            onClick={onConfirm}
            className="!rounded-full w-full flex-1"
            variant="primary"
            disabled={deleting}
            loading={deleting}
          >
            Delete
          </Button>
        </div>
      </div>
    </PopupModal>
  );
}

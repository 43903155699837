import { useEffect, useState } from "react";
import { ChatBox } from "../mailbox/components/ChatBox";
import { ContactList } from "../mailbox/components/ContactList";
import { MobileChatBox } from "../mailbox/components/MobileChatBox";
import { AnimatePresence } from "framer-motion";
import { useMailBox } from "../mailbox/context/mailbox-context";
import useResponsive from "../../services/useResponsive";
import { ContactCard } from "../mailbox/components/ContactCard";
import { Loading } from "../../components/Loading/Loading";
import { ContactRemoveConfirm } from "../family/chat/components/ContactRemoveConfirm";
import { ProviderChatContactTabs } from "./components/ProviderChatContactTabs";
import { SearchBar } from "./components/SearchBar";

const filterContacts = (contacts: any[], search: string) => {
  return contacts.filter((contact: any) => {
    const fullName = `${contact.firstName} ${contact.lastName}`;
    const guardianName = `${contact.guardianFirstName} ${contact.guardianLastName}`;
    return (
      fullName.toLowerCase().includes(search.toLowerCase()) ||
      guardianName.toLowerCase().includes(search.toLowerCase())
    );
  });
};

export function ProviderChat() {
  const { isSmallDevice } = useResponsive();
  const [confirmRemoveContact, setConfirmRemoveContact] = useState(false);
  const [mobileChatBox, setMobileChatBox] = useState(false);
  const [search, setSearch] = useState("");
  const {
    selectedContact,
    setSelectedContact,
    contactList,
    sendMessage,
    chatMessages,
    contactListLoading,
    messagesLoading,
    removeSchedule,
    removingSchedule,
    selectedContactStatus,
    inquiries,
    inquiryLoading,
    insuranceDetails,
    secondaryInsuranceDetails,
    onInquiryDecline,
    onInquiryAccept,
    activeTab,
    setActiveTab,
  } = useMailBox() as any;
  const { numOfUnreadCurrentClients } = useMailBox() as any;

  const handleRemoveContact = () => {
    setConfirmRemoveContact(true);
  };

  const handleSearchChange = (e: any) => {
    setSearch(e.target.value);
  };

  const handleStartChat = () => {
    // start chat with selected inquiry
    if (
      activeTab === "newInquiries" &&
      !!selectedContact &&
      !!selectedContact.id
    ) {
      onInquiryAccept(selectedContact.scheduleId);
    }
  };

  const handleDecline = () => {
    // decline selected inquiry
    if (
      activeTab === "newInquiries" &&
      !!selectedContact &&
      !!selectedContact.id
    ) {
      onInquiryDecline(selectedContact.scheduleId);
    }
  };

  useEffect(() => {
    if (isSmallDevice) {
      if (selectedContact?.id) {
        setMobileChatBox(true);
      } else {
        setMobileChatBox(false);
      }
    } else {
      setMobileChatBox(false);
    }
  }, [isSmallDevice, selectedContact]);
  return (
    <div>
      <div>
        <span className="text-black text-3xl font-bold ">Client Chat</span>
      </div>
      <div className="flex gap-1 items-center mt-5">
        <ContactList containerClassName="!flex-1 lg:flex-[0] gap-2.5 !h-[calc(100vh-170px)] lg:!h-[calc(100vh-110px)]">
          <ProviderChatContactTabs
            onTabChange={(tab: string) => {
              setActiveTab(tab);
              setSelectedContact(null);
            }}
            activeTab={activeTab}
            inquiryCount={inquiries.filter(inquiry => inquiry?.unReadMessagesCount > 0).length ?? 0}
            numOfUnreadCurrentClients={numOfUnreadCurrentClients}
          />
          <SearchBar onChange={handleSearchChange} />
          <div>
            {activeTab === "newInquiries" ? (
              <div className="flex flex-col gap-3 max-h-[calc(100vh-330px)] lg:max-h-[calc(100vh-275px)] overflow-auto">
                {filterContacts([...inquiries], search).map((contact: any) => (
                  <ContactCard
                    key={contact.id}
                    active={selectedContact?.id === contact.id}
                    data={{
                      ...contact,
                      clientName: `${contact?.guardianFirstName} ${contact?.guardianLastName}`,
                    }}
                    unreadCount={contact?.unReadMessagesCount}
                    cardType="provider"
                    onClick={() => {
                      setSelectedContact(contact);
                      if (isSmallDevice) {
                        setMobileChatBox(true);
                      }
                    }}
                  />
                ))}
                {inquiryLoading && (
                  <div className="flex justify-center items-center [&>svg]:w-8 [&>svg]:h-8 mt-16">
                    <Loading />
                  </div>
                )}
                {filterContacts([...inquiries], search).length === 0 && (
                  <div className="flex justify-center items-center [&>svg]:w-8 [&>svg]:h-8 mt-16">
                    <p>No New Inquiries</p>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex flex-col gap-3 max-h-[calc(100vh-330px)] overflow-auto">
                {filterContacts(contactList, search).map((contact: any) => (
                  <ContactCard
                    key={contact.id}
                    active={selectedContact?.id === contact.id}
                    data={{
                      ...contact,
                      clientName: `${contact?.guardianFirstName} ${contact?.guardianLastName}`,
                    }}
                    unreadCount={contact?.unReadMessagesCount}
                    cardType="provider"
                    onClick={() => {
                      setSelectedContact(contact);
                      if (isSmallDevice) {
                        setMobileChatBox(true);
                      }
                    }}
                  />
                ))}
                {contactListLoading && (
                  <div className="flex justify-center items-center [&>svg]:w-8 [&>svg]:h-8 mt-16">
                    <Loading />
                  </div>
                )}
                {filterContacts(contactList, search).length === 0 && (
                  <div className="flex justify-center items-center [&>svg]:w-8 [&>svg]:h-8 mt-16">
                    <p>No Current Clients</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </ContactList>
        <div className="hidden lg:block flex-1">
          <ChatBox
            containerClassName="h-[calc(100vh-170px)] lg:h-[calc(100vh-110px)]"
            onSend={(data: any) => {
              sendMessage(data);
            }}
            type="P"
            selectedContact={selectedContact}
            messages={chatMessages}
            messagesLoading={messagesLoading}
            onRemove={handleRemoveContact}
            isInquiry={activeTab === "newInquiries"}
            onDecline={handleDecline}
            onStartChat={handleStartChat}
            selectedContactStatus={selectedContactStatus}
            insuranceDetails={insuranceDetails}
            secondaryInsuranceDetails={secondaryInsuranceDetails}
          />
        </div>
      </div>
      <AnimatePresence mode="wait" initial={false}>
        {mobileChatBox && (
          <MobileChatBox
            onClose={() => setMobileChatBox(false)}
            messages={chatMessages}
            onSend={(data: any) => {
              sendMessage(data);
            }}
            type="P"
            selectedContact={selectedContact}
            messagesLoading={messagesLoading}
            onRemove={handleRemoveContact}
            isInquiry={activeTab === "newInquiries"}
            onStartChat={handleStartChat}
            onDecline={handleDecline}
            selectedContactStatus={selectedContactStatus}
            insuranceDetails={insuranceDetails}
            secondaryInsuranceDetails={secondaryInsuranceDetails}
          />
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait" initial={false}>
        {confirmRemoveContact && (
          <ContactRemoveConfirm
            onCancel={() => setConfirmRemoveContact(false)}
            onConfirm={() => {
              removeSchedule(selectedContact.scheduleId).then(() => {
                setConfirmRemoveContact(false);
              });
            }}
            deleting={removingSchedule}
          />
        )}
      </AnimatePresence>
    </div>
  );
}

import ReactPaginate from "react-paginate";
import { ReactComponent as LeftIcon } from "../../assets/icons/left-chevron.svg";
import { ReactComponent as RightIcon } from "../../assets/icons/right-chevron.svg";

function PaginationComponent({
  total,
  onPageChange,
  currentPage,
  className,
}: {
  total: number;
  onPageChange: (e: any) => void;
  currentPage: number;
  className?: string;
}) {
  return (
    <div className={`${className}`}>
      <ReactPaginate
        previousLabel={<div style={currentPage === 1 ? { opacity: 0.1, cursor: "default" } : {}} ><LeftIcon /></div>}
        nextLabel={<div style={currentPage === total ? { opacity: 0.1, cursor: "default" } : {}} ><RightIcon /></div>}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={total}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={onPageChange}
        containerClassName={"pagination"}
        activeClassName={"active"}
        forcePage={currentPage - 1}
      />
    </div>
  );
}
export default PaginationComponent;


import { OnboardingScreenWrapper } from "../OnboardingScreenWrapper";

export const SetupCompleteScreen = () => {

  return (
    <OnboardingScreenWrapper title={"Set Up Complete"}>
      <>
      </>
    </OnboardingScreenWrapper>
  );
}

import { PopupModal } from "../../../components/PopupModal";
import { ReactComponent as Email } from "../../../assets/icons/email.svg";
import { ReactComponent as Call } from "../../../assets/icons/call.svg";
import { ReactComponent as Check } from "../../../assets/icons/check.svg";
import { ReactComponent as Close } from "../../../assets/icons/x.svg";
import { ReactComponent as MessageIcon } from "../../../assets/icons/message.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download.svg";
import moment from "moment";
import { Button } from "../../../components/Button";
import { ImageView } from "../../../components/Image";
import { getInitial } from "../../../services/utils";
import React, { useEffect, useMemo } from "react";
import { ShowToast } from "../../../services/toast";
import { api } from "../../../services/api";
import { Loading } from "../../../components/Loading/Loading";
import axios from "axios";
import { zipCodes } from "../../../assets/data/zip_codes";

export function FamilyProfileView({
  user,
  insurance,
  secondaryInsurance,
  onClose,
  onMessage,
  fetchInsurance = false,
}: {
  user: any;
  onMessage: () => void;
  onClose: () => void;
  insurance: any;
  fetchInsurance?: boolean;
  secondaryInsurance: any;
}) {
  const [loading, setLoading] = React.useState(false);
  const [insuranceDetails, setInsuranceDetails] = React.useState<any>({});
  const [previewInsurance, setPreviewInsurance] = React.useState<{ url: string, fileName: string } | undefined>();
  const [secondaryInsuranceDetails, setSecondaryInsuranceDetails] =
    React.useState<any>({});


  const isZipValid = (code: string) => code && (code.trim().length === 5 && !isNaN(parseInt(code, 10)) && !!zipCodes[code])
  const [zipCity, zipState] = useMemo(() => {
    return isZipValid(user.zipCode) ? [zipCodes[user.zipCode]?.city, zipCodes[user.zipCode]?.state_short] : [undefined, undefined];
  }, [user.zipCode]);


  const handleClose = () => {
    onClose();
  };

  const handlePreviewInsuranceDownload = async () => {
    // download file instead of opening it
    const url = previewInsurance?.url;
    try {
      if (url) {
        // download file here
        const response = await axios.get(url, {
          responseType: "blob",
          headers: {
            Accept: "*",
          },
        });
        const newURL = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = newURL;
        link.setAttribute("download", `${previewInsurance.fileName}${decodeURIComponent(previewInsurance.url.substring(previewInsurance.url.lastIndexOf('.')))}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      ShowToast({
        type: "error",
        message: "Unable to download file. Please try again.",
      });
    }
  }

  const fetchInsuranceDetails = React.useCallback(async () => {
    try {
      setLoading(true);
      const insuranceResponse = await api.getInsurance(user?.id);
      setLoading(false);
      if ([200, 201].includes(insuranceResponse.status)) {
        setInsuranceDetails(
          (insuranceResponse?.data || []).find(
            (e) => e.isActive && e.isPrimary
          ) || {}
        );
        setSecondaryInsuranceDetails(
          (insuranceResponse?.data || []).find(
            (e) => e.isActive && !e.isPrimary
          ) || {}
        );
      }
    } catch (error: any) {
      setLoading(false);
      ShowToast({
        message: "Something went wrong while fetching insurance details",
        type: "error",
      });
    }
  }, [user?.id]);

  useEffect(() => {
    if (fetchInsurance) {
      // fetch insurance details
      fetchInsuranceDetails();
    }
  }, [fetchInsurance, fetchInsuranceDetails]);

  const insuranceInfo = fetchInsurance ? insuranceDetails : insurance;
  const secondaryInsuranceInfo = fetchInsurance
    ? secondaryInsuranceDetails
    : secondaryInsurance;
  return (
    <PopupModal
      onClose={handleClose}
      contentClassName="p-8 !z-[10] bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-[98%] md:w-[602px] md:max-w-[602px] mx-auto max-h-[calc(100vh-50px)]"
      exit={{ opacity: 0 }}
      overlayClassName="!z-[9]"
      shouldStopPropagation={false}
    >
      {loading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <>
          <div className="w-full justify-between items-start flex">
            <div className="text-black text-2xl font-bold font-['Outfit'] leading-7">
              Client's Profile
            </div>
            <div className="justify-end items-center gap-3 flex">
              <button onClick={handleClose}>
                <Close className="w-6 h-6" />
              </button>
            </div>
          </div>
          <div className="w-full  max-h-[calc(100vh-150px)] overflow-auto flex-col justify-start items-start gap-5 flex">
            <div className="self-stretch p-3 bg-white rounded-xl border border-neutral-200 flex-col justify-center items-start gap-3.5 flex">
              <div className="self-stretch justify-start items-center gap-3.5 inline-flex">
                <div className="flex-1 justify-start items-center gap-3.5 flex">
                  {user?.profileImg ? (
                    <ImageView
                      className="w-24 h-24 rounded-[10px] object-cover"
                      src={user?.profileImg}
                      alt="user"
                      loading="lazy"
                    />
                  ) : (
                    <div className="uppercase w-24 h-24 rounded-[10px] flex justify-center items-center text-3xl text-primary bg-secondary">
                      {getInitial(user?.guardianFirstName, user?.guardianLastName)}
                    </div>
                  )}
                  <div className="flex-col justify-start items-start gap-2 inline-flex">
                    <div className="text-black text-lg font-medium font-['Outfit']">
                      {user?.guardianFirstName} {user?.guardianLastName}
                    </div>
                    <div className="justify-start items-center gap-1.5 inline-flex">
                      <Email className="w-5 h-5 relative" />
                      <div className="text-black text-opacity-80 text-sm font-normal font-['Outfit'] leading-tight">
                        {user?.email}
                      </div>
                    </div>
                    <div className="justify-start items-center gap-1.5 inline-flex">
                      <Call className="w-5 h-5 relative"></Call>
                      <div className="text-black text-opacity-80 text-sm font-normal font-['Outfit'] leading-tight">
                        {user?.mobile}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="justify-center items-center gap-3 hidden md:flex">
                  <Button
                    variant="primary"
                    onClick={onMessage}
                    className="!rounded-full text-primary text-sm font-medium font-['Outfit'] flex items-center gap-1.5"
                  >
                    <MessageIcon />
                    Message
                  </Button>
                </div>
              </div>
            </div>
            {user?.profileSummary && (
              <div className="self-stretch flex-col justify-start items-start gap-3 flex">
                <div className="text-neutral-500 text-sm font-semibold font-['Outfit'] uppercase">
                  About me
                </div>
                <div className="self-stretch p-4 bg-white rounded-xl border border-neutral-200 flex-col justify-center items-start gap-1.5 flex">
                  <span className="text-black opacity-80 text-sm font-normal">
                    {user?.profileSummary}
                  </span>
                </div>
              </div>
            )}
            <div className="self-stretch flex-col justify-start items-start gap-3 flex">
              <div className="text-neutral-500 text-sm font-semibold font-['Outfit'] uppercase">
                Client's details
              </div>
              <div className="self-stretch p-4 bg-white rounded-xl border border-neutral-200 flex-col justify-center items-start gap-1.5 flex">
                <div className="justify-start items-center gap-1.5 inline-flex">
                  <div className="w-24 opacity-80 text-black/80 text-sm font-normal font-['Outfit']">
                    Name:{" "}
                  </div>
                  <div className="opacity-80 text-black text-sm font-medium font-['Outfit']">
                    {user?.firstName} {user?.lastName}
                  </div>
                </div>
                <div className="justify-start items-center gap-1.5 inline-flex">
                  <div className="w-24 opacity-80 text-black text-sm font-normal font-['Outfit']">
                    Date of birth:{" "}
                  </div>
                  <div className="opacity-80 text-black text-sm font-medium font-['Outfit']">
                    {moment(user?.dob).format("MMM DD, YYYY")}
                  </div>
                </div>
                {user?.zipCode && (
                  <div className="justify-start items-center gap-1.5 inline-flex">
                    <div className="w-32 opacity-80 text-black text-sm font-normal font-['Outfit']">
                      Location (Zip Code):{" "}
                    </div>
                    <div className="opacity-80 text-black text-sm font-medium font-['Outfit']">
                      {(zipCity && zipState) ? `${zipCity}, ${zipState} (${user?.zipCode})` : user?.zipCode}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {user?.cdeUrl &&
              <>
                <div className="text-neutral-500 text-sm font-semibold uppercase block">
                  Diagnostic Evaluation (DE)
                </div>
                <div
                  className="w-full p-4 bg-white rounded-xl border border-neutral-200 justify-center text-center items-center gap-1.5 flex flex-col lg:flex-row"
                  onClick={() => setPreviewInsurance({ url: user?.cdeUrl, fileName: `DiagnosticEvaluation` })}
                >
                  <ImageView
                    className="w-full h-[210px] lg:w-56 lg:h-[140px] object-contain rounded-lg cursor-pointer"
                    containerClassName="w-full h-[215px] lg:w-56 lg:h-36 rounded-lg border border-[#BFD8BD] flex flex-col justify-center"
                    src={user.cdeUrl.includes(".pdf") ? "/images/pdf.png" : user.cdeUrl}
                    style={user.cdeUrl.includes(".pdf") ? { height: "140px", textAlign: "left", padding: "20px" } : {}}
                    alt="Diagnostic Evaluation"
                    loading="lazy"
                  />
                </div>
              </>
            }

            <div className="self-stretch flex-col justify-center items-start gap-3 flex">
              <div className="self-stretch justify-between items-center inline-flex">
                <div className="text-neutral-500 text-sm font-semibold font-['Outfit'] uppercase">
                  Insurance
                </div>
                {insuranceInfo.frontImg && insuranceInfo.backImg && (
                  <div className="px-3 py-1 bg-emerald-100 rounded-3xl border border-emerald-200 justify-start items-center gap-1 flex">
                    {insuranceInfo.isVerified && (
                      <Check className="w-3.5 h-3.5 relative" />
                    )}
                    <div className="text-black text-sm font-normal font-['Outfit']">
                      {insuranceInfo.isVerified
                        ? "Verified"
                        : "Verification In-Process"}
                    </div>
                  </div>
                )}
              </div>
              {!!insuranceInfo.verifyNotes ? (
                <p>{insuranceInfo.verifyNotes.split("\n").map((note, index) => <p key={index}>{note}</p>)}</p>
              ) : null}
              <div className="w-full p-4 bg-white rounded-xl border border-neutral-200 justify-center items-center gap-1.5 flex flex-col lg:flex-row">
                <ImageView
                  className="w-full h-[210px] lg:w-56 lg:h-[140px] object-contain rounded-lg cursor-pointer"
                  containerClassName="w-full h-[215px] lg:w-56 lg:h-36 rounded-lg border border-[#BFD8BD] flex flex-col justify-center"
                  src={insuranceInfo.frontImg}
                  alt="insurance"
                  loading="lazy"
                  onClick={() => setPreviewInsurance({ url: insuranceInfo.frontImg, fileName: `${user?.firstName}-insurance-primary-front` })}
                />
                <ImageView
                  className="w-full h-[210px] lg:w-56 lg:h-[140px] object-contain rounded-lg cursor-pointer"
                  containerClassName="w-full h-[215px] lg:w-56 lg:h-36 rounded-lg border border-[#BFD8BD] flex flex-col justify-center"
                  src={insuranceInfo.backImg}
                  alt="insurance"
                  loading="lazy"
                  onClick={() => setPreviewInsurance({ url: insuranceInfo.backImg, fileName: `${user?.firstName}-insurance-primary-back` })}
                />
              </div>
            </div>

            {insuranceInfo?.vobImg &&
              <>
                <div className="text-neutral-500 text-sm font-semibold uppercase block">
                  Verification of Benefits (VOB)
                </div>
                <div
                  className="w-full p-4 bg-white rounded-xl border border-neutral-200 justify-center text-center items-center gap-1.5 flex flex-col lg:flex-row"
                  onClick={() => setPreviewInsurance({ url: insuranceInfo?.vobImg, fileName: `${user?.firstName}-insurance-primary-vob` })}
                >
                  <ImageView
                    className="w-full h-[210px] lg:w-56 lg:h-[140px] object-contain rounded-lg cursor-pointer"
                    containerClassName="w-full h-[215px] lg:w-56 lg:h-36 rounded-lg border border-[#BFD8BD] flex flex-col justify-center"
                    src={insuranceInfo?.vobImg?.includes(".pdf") ? "/images/pdf.png" : insuranceInfo?.vobImg}
                    style={insuranceInfo?.vobImg?.includes(".pdf") ? { height: "140px", textAlign: "left", padding: "20px" } : {}}
                    alt="insurance vob"
                    loading="lazy"
                  />
                </div>
              </>
            }

            {/** Secondary Insurance */}
            {(secondaryInsuranceInfo.frontImg ||
              secondaryInsuranceInfo.backImg) && (
                <div className="self-stretch mt-5 flex-col justify-start items-start gap-3 flex">
                  <div className="self-stretch justify-between items-center inline-flex">
                    <div className="text-neutral-500 text-sm font-semibold font-['Outfit'] uppercase">
                      Secondary Insurance
                    </div>
                    {secondaryInsuranceInfo.frontImg &&
                      secondaryInsuranceInfo.backImg && (
                        <div className="px-3 py-1 bg-emerald-100 rounded-3xl border border-emerald-200 justify-start items-center gap-1 flex">
                          {secondaryInsuranceInfo.isVerified && (
                            <Check className="w-3.5 h-3.5 relative" />
                          )}
                          <div className="text-black text-sm font-normal font-['Outfit']">
                            {secondaryInsuranceInfo.isVerified
                              ? "Verified"
                              : "Verification In-Process"}
                          </div>
                        </div>
                      )}
                  </div>
                  {!!secondaryInsuranceInfo.verifyNotes ? (
                    <p>{secondaryInsuranceInfo.verifyNotes.split("\n").map((note, index) => <p key={index}>{note}</p>)}</p>
                  ) : null}
                  <div className="w-full p-4 bg-white rounded-xl border border-neutral-200 justify-center items-center gap-1.5 flex flex-col lg:flex-row">
                    <ImageView
                      className="w-full h-[210px] lg:w-56 lg:h-[140px] object-contain rounded-lg cursor-pointer"
                      containerClassName="w-full h-[215px] lg:w-56 lg:h-36 rounded-lg border border-[#BFD8BD] flex flex-col justify-center"
                      src={secondaryInsuranceInfo.frontImg}
                      alt="insurance"
                      loading="lazy"
                      onClick={() => setPreviewInsurance({ url: secondaryInsuranceInfo.frontImg, fileName: `${user?.firstName}-insurance-secondary-front` })}
                    />
                    <ImageView
                      className="w-full h-[210px] lg:w-56 lg:h-[140px] object-contain rounded-lg cursor-pointer"
                      containerClassName="w-full h-[215px] lg:w-56 lg:h-36 rounded-lg border border-[#BFD8BD] flex flex-col justify-center"
                      src={secondaryInsuranceInfo.backImg}
                      alt="insurance"
                      loading="lazy"
                      onClick={() => setPreviewInsurance({ url: secondaryInsuranceInfo.backImg, fileName: `${user?.firstName}-insurance-secondary-back` })}
                    />
                  </div>
                </div>
              )}

            {secondaryInsuranceInfo.frontImg && secondaryInsuranceInfo?.vobImg && <>
              <div className="text-neutral-500 text-sm font-semibold uppercase block">
                Verification of Benefits (VOB)
              </div>
              <div
                className="w-full p-4 bg-white rounded-xl border border-neutral-200 justify-center text-center items-center gap-1.5 flex flex-col lg:flex-row"
                onClick={() => setPreviewInsurance({ url: secondaryInsuranceInfo?.vobImg, fileName: `${user?.firstName}-insurance-secondary-vob` })}
              >
                <ImageView
                  className="w-full h-[210px] lg:w-56 lg:h-[140px] object-contain rounded-lg cursor-pointer"
                  containerClassName="w-full h-[215px] lg:w-56 lg:h-36 rounded-lg border border-[#BFD8BD] flex flex-col justify-center"
                  src={secondaryInsuranceInfo?.vobImg?.includes(".pdf") ? "/images/pdf.png" : secondaryInsuranceInfo?.vobImg}
                  style={secondaryInsuranceInfo?.vobImg?.includes(".pdf") ? { height: "140px", textAlign: "left", padding: "20px" } : {}}
                  alt="secondary insurance vob"
                  loading="lazy"
                />
              </div>
            </>}

          </div>
        </>
      )}

      {
        previewInsurance &&
        <PopupModal
          contentClassName="p-8 !z-[10] bg-white rounded-2xl flex-col justify-start items-center !gap-4 flex w-[98%] md:w-[60vw] mx-auto min-h-[80vh] max-h-[calc(100vh-50px)]"
          onClose={() => setPreviewInsurance(undefined)}
        >
          <div className="flex justify-between items-center w-full">
            <div className="flex items-center gap-2">
              <div
                className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap"
                style={{ wordBreak: "break-word" }}
              >

              </div>
              <button onClick={handlePreviewInsuranceDownload}>
                <DownloadIcon />
              </button>
            </div>
            <button onClick={() => setPreviewInsurance(undefined)}>
              <Close className="w-8 h-8" />
            </button>
          </div>

          <div className="flex justify-center items-center flex-1 w-full">

            {previewInsurance.url.includes(".pdf") ?
              <iframe
                src={previewInsurance.url}
                className="w-full h-full object-contain"
                title="file"
                frameBorder="0"
              />
              :
              <div className="h-[calc(100vh-200px)]">
                <ImageView
                  src={previewInsurance?.url}
                  className="w-full h-full object-contain"
                  alt="file"
                />
              </div>
            }

          </div>


        </PopupModal>
      }

    </PopupModal>
  );
}

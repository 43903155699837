import { ReactComponent as Location } from "../../../assets/icons/location.svg";
import { ImageView } from "../../../components/Image";
import React from "react";
import { FamilyProfileView } from "./FamilyProfileView";
import { ProviderDetails } from "../../providers/ProviderDetails";
import { AnimatePresence } from "framer-motion";
import { useUser } from "../../auth/context/user-context";

export function ContactCard({
  data,
  unreadCount,
  active,
  cardType,
  onClick,
}: {
  active: boolean;
  data: any;
  unreadCount: number;
  cardType: string;
  onClick?: () => void;
}) {
  const { userInfo } = useUser();
  const [isProfileOpen, setIsProfileOpen] = React.useState(false);
  const isFamily = cardType === "family";
  return (
    <div
      className={`p-3 ${active ? "bg-[#E0F0EB]" : "hover:bg-[rgba(210,226,213,0.30)]"
        } rounded-[15px] justify-start items-center gap-3.5 flex`}
      onClick={onClick}
      role="button"
    >
      <div className="flex-1 justify-start items-center gap-3.5 flex">
        {data?.profileImg || data?.isAdminUser ? (
          <ImageView
            className={`${isFamily ? "w-[73px] h-[73px]" : "w-[61px] h-[61px]"
              } rounded-[10px] object-cover`}
            src={
              data?.isAdminUser ? "/images/admin-chat.png" : data?.profileImg
            }
            alt="user"
            loading="lazy"
            onClick={(e) => {
              if (data.isAdminUser === true) {
                return;
              }
              e.stopPropagation();
              e.preventDefault();
              setIsProfileOpen(true);
            }}
          />
        ) : (
          <div
            role="button"
            onClick={(e) => {
              if (data.isAdminUser === true) {
                return;
              }
              e.stopPropagation();
              e.preventDefault();
              setIsProfileOpen(true);
            }}
            className={`uppercase ${isFamily ? "w-[73px] h-[73px]" : "w-[61px] h-[61px]"
              } rounded-[10px] flex justify-center items-center text-3xl text-primary bg-secondary`}
          >
            {(cardType === "family" ? data?.firstName?.[0] : data?.clientName?.[0]) || ""}
          </div>
        )}

        <div className="flex-col justify-start items-start gap-1.5 flex">
          <div className="text-black text-lg font-medium font-['Outfit'] leading-none">
            {data.isAdminUser && "Headstart Admin"}
            {!data.isAdminUser && isFamily && `${data?.firstName} ${data?.lastName}`}
            {!data.isAdminUser && !isFamily && `${data?.clientName}`}
          </div>
          {isFamily ? (
            !data.isAdminUser ? (
              <>
                <div className="text-black text-sm font-normal ">
                  {data?.profession?.join(", ")}
                </div>
                <div className="justify-start items-start gap-1.5 flex">
                  {/* <Location className="w-4 h-4" /> */}
                  <div className="text-black text-sm font-normal">
                    {userInfo?.firstName ? `${userInfo.firstName}'s Provider` : "Your Provider"}
                  </div>
                </div>
              </>
            ) : null
          ) : (
            <>
              {data.isAdminUser !== true && (
                <div className="w-36 h-4 justify-start items-start gap-1 inline-flex">
                  <div className="text-black text-opacity-80 text-sm font-normal ">
                    Client:
                  </div>
                  <div className="text-black text-opacity-80 text-sm font-normal text-nowrap">
                    {!data.firstName ? `[Deleted User]` : `${data?.firstName} ${data?.lastName}`}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {active || !unreadCount ? null : (
        <div
          className={`w-9 h-9 bg-teal-500 rounded-3xl flex-col justify-center items-center gap-2.5 flex`}
        >
          <div className="text-[#FBF5E8] text-[12px] font-bold">
            {unreadCount}
          </div>{" "}
          {/** font-['Manrope'] */}
        </div>
      )}
      <AnimatePresence mode="wait" initial={false}>
        {!isFamily ? (
          isProfileOpen ? (
            <FamilyProfileView
              user={data}
              fetchInsurance={true}
              insurance={undefined}
              secondaryInsurance={undefined}
              onClose={() => setIsProfileOpen(false)}
              onMessage={() => setIsProfileOpen(false)}
            />
          ) : null
        ) : (
          <>
            {isProfileOpen ? (
              <ProviderDetails
                provider={data}
                onClose={() => setIsProfileOpen(false)}
                isOpen={isProfileOpen}
                onSchedule={() => { }}
              />
            ) : null}
          </>
        )}
      </AnimatePresence>
    </div>
  );
}

import moment from "moment";
import { Bulletin, BulletinDoc } from "../context/bulletin-context";
import axios from "axios";
import { ShowToast } from "../../../../services/toast";
import { PopupModal } from "../../../../components/PopupModal";
import { useState } from "react";
import { ImageView } from "../../../../components/Image";
import { ReactComponent as DownloadIcon } from "../../../../assets/icons/download.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/x.svg";
import { youtubeParser } from "../../../../services/utils";

const dateFormatted = (date) => {

  // check for today and yesterday
  let dateText = moment(date).format("MMM DD, YYYY");
  if (new Date(date).toDateString() === new Date().toDateString()) {
    dateText = "Today";
  } else if (
    new Date(date).toDateString() ===
    new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()
  ) {
    dateText = "Yesterday";
  }
  return dateText
}

const isWithinTwoDays = (date) => {
  let hours = moment().diff(moment(date), 'hours');
  return hours < 48;
}

export function ListView({ bulletins }: { bulletins: Bulletin[] }) {
  const [previewBulletinDoc, setPreviewBulletinDoc] = useState<BulletinDoc | undefined>(undefined);

  const handleDownload = async (doc: BulletinDoc) => {
    // download file instead of opening it
    try {
      if (doc.url) {
        // download file here
        const response = await axios.get(doc.url, {
          responseType: "blob",
        });
        const newURL = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = newURL;
        link.setAttribute("download", `${doc?.name}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      ShowToast({
        type: "error",
        message: "Unable to download file. Please try again.",
      });
    }
  };

  const renderPreview = () => {
    return (
      <>
        {previewBulletinDoc && (
          <PopupModal
            contentClassName="p-8 !z-[10] bg-white rounded-2xl flex-col justify-start items-center !gap-4 flex w-[98%] md:w-[60vw] mx-auto min-h-[80vh] max-h-[calc(100vh-50px)]"
            onClose={() => setPreviewBulletinDoc(undefined)}
          >
            <div className="flex justify-between items-center w-full">
              <div className="flex items-center gap-2">
                <div
                  className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap"
                  style={{ wordBreak: "break-word" }}
                >
                  {previewBulletinDoc?.name}
                </div>
                {previewBulletinDoc?.type !== "youtubelink" && (
                  <button onClick={() => handleDownload(previewBulletinDoc)}>
                    <DownloadIcon />
                  </button>)}
              </div>
              <button onClick={() => setPreviewBulletinDoc(undefined)}>
                <CloseIcon className="w-8 h-8" />
              </button>
            </div>
            {
              previewBulletinDoc?.type === "youtubelink" ?
                (<iframe width="100%" height="100%"
                  src={`https://www.youtube.com/embed/${youtubeParser(previewBulletinDoc?.url)}`}
                  title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; 
                              encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen>

                </iframe>)
                : previewBulletinDoc?.mimeType?.includes("image") ? (
                  <div className="flex justify-center items-center flex-1 w-full">
                    <div className="h-[calc(100vh-200px)]">
                      <ImageView
                        src={previewBulletinDoc.url}
                        className="w-full h-full object-contain"
                        alt="file"
                      />
                    </div>

                  </div>
                ) : (
                  <iframe
                    src={previewBulletinDoc.url}
                    className="w-full h-full object-contain"
                    title="file"
                    frameBorder="0"
                  />
                )
            }
          </PopupModal >
        )
        }
      </>
    );
  };


  return (
    <div className="flex flex-col">
      {bulletins.map((bulletin) => {

        const orderedDocs = (bulletin?.attachedDocs ?? []).sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

        const rightFeatureUrl = bulletin?.previewImg;

        const isNew = isWithinTwoDays(bulletin.createdAt)

        return <div
          className="pl-10 pr-5 md:pr-6 py-3 bg-white rounded-2xl hover:shadow-[0px_18px_52px_0px_rgba(46,84,61,0.25)] justify-start items-center gap-9 mb-8"
        >

          <div className="lg:grid lg:grid-cols-12 lg:gap-4">
            <div className={rightFeatureUrl ? "lg:col-span-9" : "lg:col-span-12"}>
              {isNew &&
                <div className="mr-4 mb-[-15px] mt-2 w-12 h-5 bg-primary rounded-lg flex-col justify-start items-center gap-2.5 flex self-center">
                  <div className="text-white text-sm font-normal">NEW</div>
                </div>
              }
              <div className="grid grid-cols-12">
                <div className="col-span-12 lg:col-span-9">
                  <div className="text-black text-3xl font-bold font-['Outfit'] leading-relaxed mt-3 inline-block">
                    {bulletin?.title}
                  </div>
                </div>
                <div className="lg:col-span-3 self-center pb-3">
                  <div className="lg:float-right lg:pr-5 lg:pt-5 text-nowrap" style={{ color: "#8D8E92", opacity: "0.5" }}>{dateFormatted(bulletin?.createdAt)}</div>
                </div>
              </div>

              <div className="pl-3 message-content mt-5 pb-3" dangerouslySetInnerHTML={{ __html: bulletin?.description }}>
              </div>

              {(orderedDocs.length > 0) && (
                <div>
                  {orderedDocs.map(doc =>
                  (<div className="rounded border my-2 ml-5 mr-10 cursor-pointer" style={{ color: "#00AE9B" }} onClick={() => setPreviewBulletinDoc(doc)}>
                    <div className="ml-10 my-1">{doc.name} {(doc.type === "youtubelink" && <small style={{ transform: "translateY(-2px)" }} className="text-zinc-700 inline-block align-middle">(YouTube)</small>)}</div>
                  </div>))}
                </div>
              )}
            </div>
            {rightFeatureUrl && <div className="hidden lg:block lg:col-span-3">
              {
                youtubeParser(rightFeatureUrl) ?
                  (<iframe width="100%" height="100%"
                    src={`https://www.youtube.com/embed/${youtubeParser(rightFeatureUrl)}`}
                    title="YouTube video player" allow="accelerometer; clipboard-write; 
                              encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen>
                  </iframe>)
                  : <div className="relative" style={{ top: "50%", transform: "translateY(-50%)" }}>
                    <ImageView
                      src={rightFeatureUrl}
                      className="max-h-60 mx-auto"
                      alt={rightFeatureUrl}
                    />
                  </div>
              }
            </div>}


          </div>
        </div>
      })}
      {renderPreview()}
    </div >
  );
}


import { OnboardingScreenWrapper } from "../OnboardingScreenWrapper";

export const SoftwareOnboardingScreen = () => {

  return (
    <OnboardingScreenWrapper title={"Software Onboarding"}>
      <>
      </>
    </OnboardingScreenWrapper>
  );
}

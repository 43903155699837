import { PopupModal } from "../../../../components/PopupModal";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/x.svg";
import { ReactComponent as RightChevron } from "../../../../assets/icons/right-chevron.svg";
import { ReactComponent as RadioButton } from "../../../../assets/icons/radio.svg";
import { ReactComponent as FolderIcon } from "../../../../assets/icons/file-folder.svg";
import React, { useEffect } from "react";
import { Button } from "../../../../components/Button";
import { useFiles } from "../context/file-context";
import { DOCUMENT_OWNER_TYPES } from "../../../../constant";

function MoveItem({ name, checked, setChecked, id, onClick, hasSubFolder }) {
  return (
    <div className="pl-3 pr-4 py-3 bg-white rounded-xl border border-neutral-200 justify-start items-center gap-5 inline-flex">
      <button
        disabled={!hasSubFolder}
        onClick={onClick}
        className="grow shrink basis-0 h-8 justify-start items-center gap-3 flex"
      >
        <div className="p-3 bg-[#E8F3F2] rounded-full justify-center items-center gap-2.5 flex">
          <FolderIcon className="w-4 h-4" />
        </div>
        <div className="flex items-center gap-[2px]">
          <div className="grow shrink basis-0 text-black text-base font-normal font-['Outfit'] leading-snug">
            {name}
          </div>
          {hasSubFolder && (
            <div>
              <RightChevron className="[&_path]:stroke-primary" />
            </div>
          )}
        </div>
      </button>
      <div className="w-5 h-5 relative">
        <div className="flex">
          <input
            type="radio"
            id="1"
            name="file"
            value={id}
            checked={checked === id}
            className={`w-[22px] h-[22px] cursor-pointer ${
              checked === id ? "opacity-[0] !h-0 !w-0" : ""
            }`}
            onChange={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setChecked(id);
            }}
          />
          {checked === id ? <RadioButton /> : null}
        </div>
      </div>
    </div>
  );
}

export function MoveFileDialog({ onClose, document }) {
  const { currentFolder, allFiles, moveFile, movingFile } = useFiles() as any;
  const [breadcrumb, setBreadcrumb] = React.useState<any>([]);
  const [folders, setFolders] = React.useState<any>([]);
  const [checked, setChecked] = React.useState<string>("");

  const disabled = !checked;

  const handleMove = async () => {
    if (!checked) return;
    const response = await moveFile(document, {
      id: checked === "-1" ? null : checked,
    });
    if (response) {
      onClose();
    }
  };

  const handleFolderClick = async (folder: any) => {
    setChecked("");
    setFolders(
      (folder.children || []).filter((item) => item.type === "folder")
    );
    setBreadcrumb([...breadcrumb, folder]);
  };

  const handleBreadcrumbClick = (index: number) => {
    // when breadcrumb is clicked, remove all folders after that index
    // also set current folder to that folder
    setChecked("");
    if (index === -1) {
      const list = (allFiles || []).filter(
        (item) =>
          item.type === "folder" &&
          item.ownerType !== DOCUMENT_OWNER_TYPES.ADMIN &&
          item.id !== document.id &&
          item.id !== document?.folderId
      );
      setFolders(list);
      setBreadcrumb([]);
    } else {
      const newBreadcrumb = breadcrumb.slice(0, index + 1);
      setFolders(
        (newBreadcrumb[index].children || []).filter(
          (item) => item.type === "folder"
        )
      );
      setBreadcrumb(newBreadcrumb);
    }
  };

  useEffect(() => {
    const list = (allFiles || []).filter(
      (item) =>
        item.type === "folder" &&
        item.ownerType !== DOCUMENT_OWNER_TYPES.ADMIN &&
        item.id !== document.id &&
        item.id !== document?.folderId
    );
    setFolders(list);
  }, [document, allFiles, currentFolder]);

  return (
    <PopupModal
      contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[442px] max-w-[442px]"
      onClose={onClose}
    >
      <>
        <div className="w-full justify-between items-start flex">
          <div className="text-black text-2xl font-bold font-['Outfit'] leading-7">
            Move to
          </div>
          <div className="justify-end items-center gap-3 flex">
            <button onClick={onClose}>
              <CloseIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
        <div className="w-full flex items-center flex-wrap gap-2">
          {breadcrumb.length > 0 && (
            <button onClick={() => handleBreadcrumbClick(-1)}>
              <div className="text-primary text-lg font-medium">My Library</div>
            </button>
          )}
          {breadcrumb.map((item: any, index: number) => (
            <React.Fragment key={item.id}>
              <div>
                <RightChevron className="w-6 h-6" />
              </div>
              <button
                disabled={breadcrumb.length - 1 === index}
                key={item.id}
                onClick={() => handleBreadcrumbClick(index)}
              >
                {breadcrumb.length - 1 === index ? (
                  <div className="text-black text-lg font-medium">
                    {item.name}
                  </div>
                ) : (
                  <div className="text-primary text-lg font-medium">
                    {item.name}
                  </div>
                )}
              </button>
            </React.Fragment>
          ))}
        </div>
        <div className="w-full">
          <div className="flex mb-8 flex-col gap-[13px] max-h-[calc(100vh-250px)] overflow-auto">
            {breadcrumb.length === 0 && document.folderId && (
              <MoveItem
                name={"My Library"}
                id={"-1"}
                key={"-1"}
                checked={checked}
                setChecked={setChecked}
                onClick={() => {}}
                hasSubFolder={false}
              />
            )}
            {folders.map((item) => (
              <MoveItem
                name={item.name}
                id={item.id}
                key={item.id}
                checked={checked}
                setChecked={setChecked}
                onClick={() => handleFolderClick(item)}
                hasSubFolder={
                  item.children &&
                  item.children.length > 0 &&
                  item.children.filter((i) => i.type === "folder").length > 0
                }
              />
            ))}
          </div>
          <Button
            disabled={disabled || movingFile}
            variant="primary"
            className="!rounded-full w-full"
            onClick={handleMove}
            loading={movingFile}
          >
            Move
          </Button>
        </div>
      </>
    </PopupModal>
  );
}

import { Folder } from "./Document";

export function GridView({ data }) {
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-[15px] overflow-auto max-h-[calc(100vh-300px)] md:max-h-[calc(100vh-210px)] lg:max-h-[calc(100vh-150px)]">
      {data.map((item) => (
        <Folder type="grid" key={item.id} data={item} />
      ))}
    </div>
  );
}

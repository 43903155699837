export function ProviderChatContactTabs({
  onTabChange,
  activeTab,
  inquiryCount,
  numOfUnreadCurrentClients
}: {
  activeTab: string;
  inquiryCount: number;
  numOfUnreadCurrentClients: number;
  onTabChange: (tab: string) => void;
}) {
  return (
    <div className="p-1.5 mb-[11px] rounded-lg border border-neutral-200 justify-start items-start flex w-full">
      <button
        onClick={() => onTabChange("currentClients")}
        className={`h-12 flex-1 px-4 py-[13px] ${activeTab === "currentClients"
          ? "bg-[#E8F3F2]"
          : "hover:bg-whiteButtonHover"
          } rounded-md justify-center items-center gap-2.5 flex`}
      >
        <div className="text-black text-base font-normal font-['Outfit'] leading-none whitespace-nowrap">
          Current Clients
        </div>
        {numOfUnreadCurrentClients > 0 && (
          <div className="w-5 h-5 p-2.5 mt-1 bg-primary rounded-3xl flex-col justify-center items-center gap-2.5 inline-flex">
            <div className="text-[#FBF5E8]  text-xs font-bold ">
              {numOfUnreadCurrentClients}
            </div>
          </div>
        )}
      </button>
      <button
        onClick={() => onTabChange("newInquiries")}
        className={`h-12 flex-1 px-4 py-[13px] rounded-md justify-center items-center gap-2.5 flex ${activeTab === "newInquiries"
          ? "bg-[#E8F3F2]"
          : "hover:bg-whiteButtonHover"
          }`}
      >
        <div className="text-black text-base font-normal">New Inquiries </div>
        {inquiryCount > 0 && (
          <div className="w-5 h-5 p-2.5 mt-1 bg-primary rounded-3xl flex-col justify-center items-center gap-2.5 inline-flex">
            <div className="text-[#FBF5E8]  text-xs font-bold ">
              {/* font-['Manrope'] */}
              {inquiryCount}
            </div>
          </div>
        )}
      </button>
    </div>
  );
}

import Modal from "../../../components/Modal";
import { ReactComponent as Logo } from "../../../assets/icons/Logo.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { Loading } from "../../../components/Loading/Loading";
import { useState } from "react";
import { ImageView } from "../../../components/Image";
import { WEBSITE_URL } from "../../../env";

export function ToolsView({ tool, isOpen, onClose }) {
  const [loading, setLoading] = useState(true);
  return (
    <Modal
      noTitle
      isOpen={isOpen}
      handleModal={onClose}
      contentClassName="flex flex-col h-full"
    >
      <div className="relative flex justify-between items-center mb-6">
        <a href={`${WEBSITE_URL}`} rel="noreferrer">
          <Logo />
        </a>

        <div className="absolute left-0 right-0 hidden md:flex justify-center items-center gap-2">
          <ImageView
            className="w-[42px] h-[42px] rounded-full object-cover"
            alt="tool"
            src={tool.image}
            loading="lazy"
          />
          <span className="text-3xl font-bold text-black">{tool.title}</span>
        </div>
        <button className="z-[2]" onClick={onClose}>
          <CloseIcon className="w-8 h-8" />
        </button>
      </div>
      <div className="flex md:hidden justify-center items-center gap-2 mb-6">
        <ImageView
          className="w-[42px] h-[42px] rounded-full object-cover"
          alt="tool"
          src={tool.image}
          loading="lazy"
        />
        <span className="text-3xl font-bold text-black">{tool.title}</span>
      </div>
      <div className="relative flex-1 flex">
        <iframe
          className="w-full flex-1 border border-[rgba(0,0,0,0.30)] rounded-[18px] bg-white"
          src={tool.url}
          title={tool.title}
          onLoad={() => setLoading(false)}
        />
        {loading && (
          <div className="absolute border border-[rgba(0,0,0,0.30)] rounded-[18px] bg-white top-0 left-0 h-full w-full flex-1 [&>svg]:w-8 [&>svg]:h-8 flex justify-center items-center">
            <Loading />
          </div>
        )}
      </div>
    </Modal>
  );
}

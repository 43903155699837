import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/icons/Logo.svg";
import { Button } from "../../../components/Button";
import { WEBSITE_URL } from "../../../env";

export function Header({
  showSignIn = false,
  showSignUp = false,
  isProvider = false,
}) {
  return (
    <div className="px-4 md:px-10 py-4 bg-white/[0.64] backdrop-blur-[27px] border-b border-black/10 justify-between items-center flex">
      <div className=" justify-center items-center flex">
        <a href={`${WEBSITE_URL}`} rel="noreferrer">
          <Logo />
        </a>
      </div>
      <div className="justify-start items-start gap-3 flex">
        {showSignIn && (
          <Link to={isProvider ? "/provider/login" : "/login"}>
            <Button
              variant="primary"
              className="px-4 py-2 !rounded-full bg-primary justify-center items-center gap-2.5 flex"
            >
              Sign In
            </Button>
          </Link>
        )}

        {showSignUp && (
          <Link to={isProvider ? "/provider/register" : "/register"}>
            <Button
              variant="primary"
              className="px-4 py-2 !rounded-full bg-primary justify-center items-center gap-2.5 flex"
            >
              Sign Up
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
}

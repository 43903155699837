import React, { useState } from "react";

export const AppContext = React.createContext({});

export function AppProvider({ children }) {
  const [selectedProvider, setSelectedProvider] = React.useState<any>(null);

  // dashboard data
  const [loading, setLoading] = useState(false);

  const values = {
    selectedProvider,
    setSelectedProvider,
    loading,
    setLoading,
  };

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
}

export function useApp() {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error("useApp must be used within a AppProvider");
  }
  return context;
}

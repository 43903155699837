export const locationData = [
  "Indiana",
  "New York",
  "California",
  "Illinois",
  "Texas",
  "Pennsylvania",
  "Arizona",
  "Florida",
  "Ohio",
  "North Carolina",
  "Michigan",
  "Tennessee",
  "Massachusetts",
  "Washington",
  "Colorado",
  "District of Columbia",
  "Maryland",
  "Kentucky",
  "Oregon",
  "Oklahoma",
  "Wisconsin",
  "Nevada",
  "New Mexico",
  "Missouri",
  "Virginia",
  "Georgia",
  "Nebraska",
  "Minnesota",
  "Kansas",
  "Louisiana",
  "Hawaii",
  "Alaska",
  "New Jersey",
  "Idaho",
  "Alabama",
  "Iowa",
  "Arkansas",
  "Utah",
  "Rhode Island",
  "Mississippi",
  "South Dakota",
  "Connecticut",
  "South Carolina",
  "New Hampshire",
  "North Dakota",
  "Montana",
  "Delaware",
  "Maine",
  "Wyoming",
  "West Virginia",
  "Vermont",
];

import Routes from "./pages/routes";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "./App.css";
import ReactGA from 'react-ga4';
import { GOOGLE_OAUTH_CLIENT_ID } from "./constant";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from "./pages/providers/context/provider-context";
import { AppProvider } from "./services/app-context";
import ImageCropProvider from "./components/ImageCropper/provider/ImageCropProvider";
import { BulletinProvider } from "./pages/provider/bulletin/context/bulletin-context";
import { RbtRequestProvider } from "./pages/provider/request-rbt/context/rbt-request-context";
import { AuthRequestProvider } from "./pages/provider/request-auth/context/RequestAuthContext";
import { UserProvider } from "./pages/auth/context/user-context";

// Create a client
const queryClient = new QueryClient();

function App() {
  ReactGA.initialize('G-FXEGBVEFDV');
  return (
    <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
      <QueryClientProvider client={queryClient}>
        <Toaster />
        <Provider>
          <AppProvider>
            <div className="font-outfit min-h-screen flex flex-col">
              <RbtRequestProvider>
                <AuthRequestProvider>
                  <BulletinProvider>
                    <ImageCropProvider>
                      <UserProvider>
                        <Routes />
                      </UserProvider>
                    </ImageCropProvider>
                  </BulletinProvider>
                </AuthRequestProvider>
              </RbtRequestProvider>
            </div>
          </AppProvider>
        </Provider>
      </QueryClientProvider>
    </GoogleOAuthProvider >
  );
}

export default App;

import QRCode from "react-qr-code";

export function QrCodeUpload({ userId, token, isPrimary }) {
  // /qr-image-upload with current domain
  const origin = window.location.origin.includes("localhost")
    ? "http://192.168.0.103:" + window.location.port
    : window.location.origin;
  const url = `${origin}/qr-image-upload?userId=${userId}&t=${token}&isPrimary=${isPrimary}`;
  return (
    <div>
      <p>
        Scan the QR code below and upload a clear photo of both sides of your
        insurance card.
      </p>
      <div className="flex justify-center mt-[22px]">
        <div className="w-[267px] h-[267px]">
          <QRCode value={url} />
        </div>
      </div>
    </div>
  );
}

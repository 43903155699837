import { ReactComponent as AdminIcon } from "../../../../assets/icons/check-logo.svg";
import { Tooltip } from "react-tooltip";

export function AdminTooltip() {
  return (
    <>
      <AdminIcon className="w-6 h-6 my-anchor-element" />
      <Tooltip
        anchorSelect=".my-anchor-element"
        content="Added by Headstart Admin"
        className="!w-[228px] !border-0 !text-black !rounded-lg !p-4 !bg-white !shadow-[0px_4px_44px_0px_rgba(0,0,0,0.21)]"
        noArrow
      />
    </>
  );
}

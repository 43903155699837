import { PopupModal } from "../../../../components/PopupModal";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/x.svg";
import { ReactComponent as SuccessIcon } from "../../../../assets/icons/success.svg";
import { TextInput } from "../../../../components/TextInput";
import { useState } from "react";
import { Button } from "../../../../components/Button";
import { TextArea } from "../../../../components/Textarea";
import { SelectInput } from "../../../../components/SelectInput";
import { DateTime } from "luxon";
import { api } from "../../../../services/api";
import { Link } from "react-router-dom";
import { RbtCandidate, RbtCandidateStatus } from "../RbtCandidate.types";
import { useRbtRequests } from "../context/rbt-request-context";

export function RbtCandidateReviewModal({ rbtCandidate, onClose, onCloseAll }: { rbtCandidate?: RbtCandidate; onClose: () => void; onCloseAll: () => void; }) {
  const { refreshRequests } = useRbtRequests();
  const [loading, setLoading] = useState(false);
  const [offerMade, setOfferMade] = useState<boolean | undefined>(rbtCandidate?.status === RbtCandidateStatus.NEGOTIATING ? true : undefined)
  const [offerAccepted, setOfferAccepted] = useState<boolean | undefined>()
  const [rejectReason, setRejectReason] = useState<string>("")
  async function candidateReceivedOffer() {
    setLoading(true)
    setOfferMade(true);
    await api.offerMadeToCandidate(rbtCandidate?.id);
    setLoading(false)
    refreshRequests();
  }

  async function candidateAccepted() {
    setLoading(true)
    await api.candidateReviewFeedback(rbtCandidate?.id, { accept: true })
    setOfferAccepted(true)
    setLoading(false)
  }

  function handleNo(): void {
    setOfferMade(false)
  }

  const handleReject = async () => {
    setLoading(true)
    await api.candidateReviewFeedback(rbtCandidate?.id, { reject: true, rejectReason: rejectReason });
    onCloseAll()
    setLoading(false)
  }

  const editMode = false;

  const prettyTime = (date: Date | string) => {
    const d = new Date(date)
    const mins = d.getMinutes() < 10 ? `${d.getMinutes()}0` : d.getMinutes()
    if (isNaN(d.getMinutes())) return date;
    return `${d.getHours()}:${mins}`
  }

  return (
    <PopupModal
      contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[842px] max-w-[842px]"
      onClose={onClose}
      shouldStopPropagation={false}
    >
      <>
        <div className="w-full justify-between items-start flex">
          <div className="inline-block text-black text-2xl font-bold font-['Outfit'] leading-7">
            {rbtCandidate?.firstName} {rbtCandidate?.lastName} Profile
          </div>
          <div className="justify-end items-center gap-3 flex">
            <button onClick={onClose}>
              <CloseIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
        <div className="w-full max-h-[calc(100vh-210px)] lg:max-h-[calc(100vh-205px)] overflow-auto" id="provider-scroll">
          <div className="grid grid-cols-2 gap-10">
            <div className="flex flex-col mb-5">
              <span className="text-black/80 text-sm">First Name</span>
              <TextInput
                className={`!p-[14px] ${!editMode && 'text-gray-400'}`}
                name="firstName"
                value={rbtCandidate?.firstName}
                disabled={!editMode}
              />
            </div>
            <div className="flex flex-col mb-5">
              <span className="text-black/80 text-sm">Last Name</span>
              <TextInput
                className={`!p-[14px] ${!editMode && 'text-gray-400'}`}
                name="lastName"
                value={rbtCandidate?.lastName}
                disabled={!editMode}
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-10">
            <div className="flex flex-col mb-5">
              <span className="text-black/80 text-sm">Email</span>
              <TextInput
                className={`!p-[14px] ${!editMode && 'text-gray-400'}`}
                name="email"
                value={rbtCandidate?.email}
                disabled={!editMode}
              />
            </div>
            <div className="flex flex-col mb-5">
              <span className="text-black/80 text-sm">Phone</span>
              <TextInput
                className={`!p-[14px] ${!editMode && 'text-gray-400'}`}
                name="mobile"
                value={rbtCandidate?.mobile}
                disabled={!editMode}
              />
            </div>
          </div>

          <div className="flex flex-col">
            <div className="grid grid-cols-2 gap-10 px-1">
              <div className="mb-3">
                <span className="text-black/80 text-sm">Preferred Method of Contact</span>
                <SelectInput
                  data={[
                    { value: "EMAIL", label: "Email" },
                    { value: "PHONE", label: "Phone" },
                    { value: "OTHER", label: "Other" }
                  ]}
                  name="preferredContact"
                  value={rbtCandidate?.preferredContact}
                  disabledOptionText={"Select Preferred Method of Contact"}
                  className={`h-16 ${!editMode && 'text-gray-400'}`}
                  disabled={!editMode}
                />
              </div>

              <div className="mb-3">
                <span className="text-black/80 text-sm">Work Preference</span>
                <SelectInput
                  data={[
                    { value: "FULLTIME", label: "Full Time" },
                    { value: "PARTTIME", label: "Part Time" },
                    { value: "EITHER", label: "Either" }
                  ]}
                  name="workPreference"
                  value={rbtCandidate?.workPreference}
                  disabledOptionText={"Select Work Preference"}
                  className={`h-16 ${!editMode && 'text-gray-400'}`}
                  disabled={!editMode}
                />
              </div>
            </div>


            <div className="grid grid-cols-2 gap-10 px-1">

              <div className="flex flex-col mb-5">
                <span className="text-black/80 text-sm">Schedule Availability</span>
                <div className={`border rounded-lg min-h-[155px] p-2 relative ${(!editMode) && 'text-gray-400'}`} style={(editMode) ? {} : { "backgroundColor": "rgba(239, 239, 239, 0.3)" }}>
                  {rbtCandidate?.scheduleAvailability?.map((shift, i) =>
                  (<div key={i} className="text-bold">{`${shift?.day}: ${prettyTime(shift?.startTime)} - ${prettyTime(shift?.endTime)}`}
                    {shift.timeZone && ` (${DateTime.local().setZone(shift.timeZone).offsetNameShort})`}
                  </div>)
                  )}
                  {rbtCandidate?.scheduleAvailability?.length === 0 && <div>No sessions added</div>}
                </div>
              </div>

              <div>
                <div className="flex flex-col mb-3">
                  <span className="text-black/80 text-sm">Target Weekly Hours</span>
                  <TextInput
                    type="number"
                    className={`!p-[14px] ${!editMode && 'text-gray-400'}`}
                    name="clientZip"
                    value={rbtCandidate?.targetWeeklyHours}
                    disabled={!editMode}
                  />
                </div>


                <div className="mb-5">
                  <span className="text-black/80 text-sm block">Resume</span>
                  <div className="inline-block">
                    <a target="_blank" href={rbtCandidate?.resumeUrl} rel="noreferrer"><div className="text-primary cursor-pointer inline-block">
                      {rbtCandidate?.resumeUrl && decodeURIComponent(rbtCandidate?.resumeUrl?.substring(rbtCandidate?.resumeUrl?.lastIndexOf('/') + 1))}
                    </div></a>
                  </div>
                </div>

              </div>

            </div>
          </div>



          <div className="flex flex-col">
            <span className="text-black/80 text-sm">Comments</span>
            <TextArea
              className={`!p-[14px] ${!editMode && 'text-gray-400'}`}
              name="comments"
              rows={3}
              value={rbtCandidate?.comments}
              disabled={!editMode}
            />
          </div>

        </div>



        {rbtCandidate?.status === RbtCandidateStatus.PROVIDER_REVIEW && !offerMade &&
          <div className="w-full">
            <div className="px-1 pb-1 md:px-5 md:pb-5">
              <div className="font-bold text-xl">Do you plan on making an offer to this candidate?</div>
              <div className="text-xl">Please only answer this <u>after</u> you've completed the interview process</div>
            </div>

            <div className="grid grid-cols-2 gap-10 px-1">
              <Button
                variant="primary"
                className="!rounded-full w-full mt-0 bg-red-500 text-white"
                onClick={handleNo}
                loading={loading}
              >
                No
              </Button>
              <Button
                variant="primary"
                className="!rounded-full w-full mt-0"
                onClick={candidateReceivedOffer}
                loading={loading}
              >
                Yes
              </Button>
            </div>

          </div>}

        {(rbtCandidate?.status === RbtCandidateStatus.NEGOTIATING || offerMade) &&
          <div className="w-full">
            <div className="px-1 pb-1 md:px-5 md:pb-5">
              <div className="font-bold text-xl">Did {rbtCandidate?.firstName} {rbtCandidate?.lastName} accept your employment offer?</div>
              <div className="text-xl">Please only answer this <u>after</u> you've received a definitive response from the candidate.</div>
            </div>

            <div className="grid grid-cols-2 gap-10 px-1">
              <Button
                variant="primary"
                className="!rounded-full w-full mt-0 bg-red-500 text-white"
                onClick={handleNo}
                loading={loading}
              >
                No
              </Button>
              <Button
                variant="primary"
                className="!rounded-full w-full mt-0"
                onClick={candidateAccepted}
                loading={loading}
              >
                Yes
              </Button>
            </div>

          </div>}

        {offerAccepted === true && (
          <PopupModal
            contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[642px] max-w-[642px]"
            onClose={onCloseAll}
            shouldStopPropagation={false}
          >
            <>
              <div id="provider-scroll" className="w-full justify-between items-start flex">
                <div />
                <div className="justify-end items-center gap-3 flex float-right">
                  <button onClick={onCloseAll}>
                    <CloseIcon className="w-6 h-6" />
                  </button>
                </div>
              </div>
              <div className="w-full text-center">
                <SuccessIcon className="w-64 h-auto max-h-96 mx-auto" />
                <p className="text-xl mb-5">
                  Thank you for letting us know!
                </p>
                <p className="text-l mb-5">
                  Please refer to the <Link className='text-primary' to={'/provider/library?filelink=2.%20RBT%20Resources____New%20RBT%20Onboarding%20Guide.pdf'}>RBT Onboarding Guide</Link> in the content library if you have any questions about onboarding.
                </p>
              </div>
            </>
          </PopupModal >)
        }

        {(offerMade === false || offerAccepted === false) && (
          <PopupModal
            contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[642px] max-w-[642px]"
            onClose={() => setOfferMade(undefined)}
            shouldStopPropagation={false}
          >
            <>
              <div id="provider-scroll" className="w-full justify-between items-start flex">
                <div />
                <div className="justify-end items-center gap-3 flex float-right">
                  <button onClick={() => setOfferMade(undefined)}>
                    <CloseIcon className="w-6 h-6" />
                  </button>
                </div>
              </div>
              <div className="w-full text-center">
                <p className="text-xl mb-5">
                  We're sorry to hear that!
                </p>
                <p className="text-l mb-5">
                  Please provide brief feedback on why the candidate was not a good fit
                </p>
                <div className="flex flex-col">
                  <TextArea
                    className={`!p-[14px]`}
                    name="rejectReason"
                    rows={4}
                    value={rejectReason}
                    onChange={(e) => setRejectReason(e.target.value)}
                  />
                </div>
                <Button
                  variant="primary"
                  className="!rounded-full w-full mt-10"
                  onClick={handleReject}
                  disabled={rejectReason.length < 1}
                  loading={loading}
                >
                  Submit
                </Button>
              </div>
            </>
          </PopupModal >)}




        {/* {deleteRequestId && <CancelRbtRequestConfirm
          onCancel={() => setDeleteRequest(undefined)}
          onDelete={onClose}
          request={deleteRequestId} />} */}

      </>
    </PopupModal >
  );
}

import { OnboardingScreen } from "../pages/auth/context/user-context";
import { ReferralFormType } from "../pages/auth/refer/ClientReferral";
import { AuthRequest } from "../pages/provider/request-auth/RequestAuth.types";
import { ReviewRbtCandidateDto } from "../pages/provider/request-rbt/RbtCandidate.types";
import { RbtRequest, RbtRequestStatus } from "../pages/provider/request-rbt/RequestRbt.types";
import apiClient from "./axios";

export const api = {
  login: (data: any) => {
    return apiClient.post("/auth/login", data);
  },
  socialLogin: (data: any) => {
    return apiClient.post("/auth/login/social", data);
  },
  markUserAsActive: (id: string) => {
    return apiClient.post(`/users/${id}/active`);
  },
  fetchUserStatus: (id: string) => {
    return apiClient.get(`/users/${id}/active-status`);
  },
  getPatientsCount: (id: string) => {
    return apiClient.get(`/users/${id}/patients-count`);
  },
  getAllUnreadPatientConversationCount: (id: string) => {
    return apiClient.get(`/users/${id}/unread-all-conversation-count`);
  },
  getProviders: (data: any) => {
    let url = `/users?type=${data.type}&page=${data.page}&limit=${data.limit}`;
    if (data.searchKeyword) {
      url += `&searchKeyword=${data.searchKeyword}`;
    }
    if (data.showAvailable) {
      url += `&showAvailable=${data.showAvailable}`;
    }
    if (data.providerTypes && data.providerTypes.length > 0) {
      url += `&providerTypes=${data.providerTypes.join(",")}`;
    }
    if (data.focusAreas && data.focusAreas.length > 0) {
      url += `&focusAreas=${data.focusAreas.join(",")}`;
    }
    if (data.acceptedInsurances && data.acceptedInsurances.length > 0) {
      url += `&acceptedInsurances=${data.acceptedInsurances.join(",")}`;
    }
    if (data.addressState) {
      url += `&addressState=${data.addressState}`;
    }
    if (data.addressZip) {
      url += `&addressZip=${data.addressZip}`;
    }
    return apiClient.get(url).then((res) => res.data);
  },
  getFamilyProviders: (id: string) => {
    return apiClient.get(`/users/${id}/providers`);
  },
  getProviderClients: (id: string, params: any) => {
    let url = `/users/${id}/patients?page=${params.page}&limit=${params.limit}&type=${params.type}`;
    return apiClient.get(url);
  },
  updateScheduleStatus: (id: string, data: any) => {
    return apiClient.patch(`/schedules/${id}/status`, data);
  },
  getMessages: (patientId: string, providerId: string) => {
    return apiClient.get(`/messages?patientId=${patientId}&providerId=${providerId}`);
  },
  removeSchedules: (id: string) => {
    return apiClient.delete(`/schedules/${id}`);
  },
  switchAwayFromProvider: (providerId: string, reason: string, comments?: string) => {
    return apiClient.post(`/messages/leaveProvider`, { providerId, reason, comments });
  },
  markAsRead: (data: any) => {
    return apiClient.patch("/messages", data);
  },
  sendMessage: (data: any) => {
    return apiClient.post("/messages", data);
  },
  deleteAccount: (id: string) => {
    return apiClient.delete(`/users/${id}`);
  },
  getSchedules: (id: string, field: string) => {
    return apiClient.get(`/schedules?${field}=${id}`);
  },
  scheduleAppointment: (data: any) => {
    return apiClient.post("/schedules", data);
  },
  getScheduleByPatientId: (id: string) => {
    const url = `/schedules?patientId=${id}`;
    return apiClient.get(url).then((res) => res.data);
  },
  getScheduleByProviderId: (id: string) => {
    const url = `/schedules?providerId=${id}`;
    return apiClient.get(url).then((res) => res.data);
  },
  getDocuments: (userId: string) => {
    let url = `/documents?userId=${userId}`;
    return apiClient.get(url);
  },
  moveDocument: (documentId: string, data: any) => {
    return apiClient.patch(`/documents/${documentId}`, data);
  },
  renameDocument: (documentId: string, data: any) => {
    return apiClient.patch(`/documents/${documentId}`, data);
  },
  deleteDocument: (documentId: string) => {
    return apiClient.delete(`/documents/${documentId}`);
  },
  createDocument: (data: any) => {
    return apiClient.post("/documents", data);
  },
  getBulletins: () => {
    let url = `/bulletin`;
    return apiClient.get(url).then((res) => res.data);
  },
  contentFeedback: (userId: any, data: any) => {
    return apiClient.post(`/users/${userId}/content-feedback`, data);
  },
  initEmailVerification: (id: any) => {
    return apiClient.post(`/users/${id}/email/verify/initiate`);
  },
  verifyEmail: (id: any, data: any) => {
    return apiClient.post(`/users/${id}/email/verify`, data);
  },
  submitReferral: (referralData: ReferralFormType) => {
    return apiClient.post("/users/refer-client", referralData);
  },
  signup: (data: any) => {
    return apiClient.post("/auth/register", data).then((res) => res.data);
  },
  signupFamily: (data: any) => {
    return apiClient.post("/auth/patient/register", data).then((res) => res.data);
  },
  saveInsurance: (userId: string, data: any) => {
    return apiClient.post(`/users/${userId}/insurances`, data);
  },
  saveCDE: (userId: string, data: any) => {
    return apiClient.post(`/users/${userId}/cde`, data);
  },
  validateEmail: (value: string) => {
    return apiClient.post(`/auth/validate-field`, {
      value,
      fieldType: "email",
    });
  },
  getInsurance: (userId: string) => {
    return apiClient.get(`/users/${userId}/insurances`);
  },
  signupProvider: (data: any) => {
    return apiClient.post("/auth/provider/register", data).then((res) => res.data);
  },
  logout: () => {
    return apiClient.post("/auth/logout").then((res) => res.data);
  },
  forgotPassword: (data: any) => {
    return apiClient.post("/auth/forgot-password", data);
  },
  resetPassword: (data: any) => {
    return apiClient.post("/auth/reset-password", data);
  },
  changePassword: (userId: string, data: any) => {
    return apiClient.patch(`/users/${userId}/password`, data);
  },
  getUserProfile: (id: string) => {
    return apiClient.get(`/users/${id}`).then((res) => res.data);
  },
  saveLastActive: (id: string) => {
    return apiClient.post(`/users/${id}/active`);
  },
  saveUserProfile: (id: string, data: any) => {
    return apiClient.put(`/users/${id}`, data);
  },
  actionCompletedOnboardingScreen: (screen: OnboardingScreen) => {
    return apiClient.put(`/users/onboarding/next-screen`, { screen });
  },
  getProgressDashboard: (userId: string, month: string, year: string) => {
    return apiClient.get(`/users/${userId}/progress-dashboard?month=${month}&year=${year}`).then((r) => r.data);
  },
  getDashboard: (userId: string) => {
    return apiClient.get(`/users/${userId}/dashboard`).then((r) => r.data);
  },
  getPredesignedUrl: ({
    id,
    mimeType,
    uploadType,
    fileName,
  }: {
    id: string;
    mimeType: string;
    uploadType: string;
    fileName?: string;
  }) => {
    let url = `/users/${id}/images/signed-url?mimeType=${mimeType}&uploadType=${uploadType}`;
    if (fileName) {
      url += `&fileName=${fileName}`;
    }
    return apiClient.get(url).then((r) => r.data);
  },
  getAuthRequests: async () => {
    return (await apiClient.get("/authrequest")).data;
  },
  getAllPatients: async () => {
    return (await apiClient.get("/authrequest/all-patients")).data;
  },
  createAuthRequest: async (createRequest: AuthRequest) => {
    return await apiClient.post("/authrequest", createRequest);
  },
  deleteAuthRequest: async (id: string) => {
    return await apiClient.delete(`/authrequest/${id}`);
  },
  getRbtRequests: async () => {
    let requests = (await apiClient.get("/rbtrequest")).data;
    requests = requests?.map((r) => ({
      ...r,
      shiftsNeeded: r.shiftsNeeded.map((shift) => {
        if (!isNaN(Date.parse(shift.startTime))) return shift;

        const newStart = new Date();
        newStart.setHours(shift.startTime.split(":")[0]);
        newStart.setMinutes(shift.startTime.split(":")[1]);
        const newEnd = new Date();
        newEnd.setHours(shift.endTime.split(":")[0]);
        newEnd.setMinutes(shift.endTime.split(":")[1]);
        return {
          ...shift,
          startTime: newStart,
          endTime: newEnd,
        };
      }),
    }));
    return requests ?? [];
  },
  createRbtRequest: async (createRequest: RbtRequest) => {
    let data = createRequest as any;
    data.shiftsNeeded = convertShiftsTimeToCustomFormat(createRequest.shiftsNeeded);
    return await apiClient.post("/rbtrequest", data);
  },
  updateRbtRequest: async (id, updateRequest: RbtRequest) => {
    let data = updateRequest as any;
    if (updateRequest.status === RbtRequestStatus.IN_PROCESS) {
      data.status = "In Progress";
    }
    data.shiftsNeeded = convertShiftsTimeToCustomFormat(updateRequest.shiftsNeeded);
    return await apiClient.patch(`/rbtrequest/${id}`, data);
  },
  deleteRbtRequest: async (id: string) => {
    return await apiClient.delete(`/rbtrequest/${id}`);
  },
  candidateReviewFeedback: async (id, reviewFeedback: ReviewRbtCandidateDto) => {
    return await apiClient.patch(`/rbtcandidate/${id}/provider-review`, reviewFeedback);
  },
  offerMadeToCandidate: async (id) => {
    return await apiClient.post(`/rbtcandidate/${id}/offer-made`);
  },
};

const convertShiftsTimeToCustomFormat = (shifts) =>
  shifts.map((shift) => {
    const start = new Date(shift.startTime);
    const startMins = start.getMinutes() < 10 ? `${start.getMinutes()}0` : start.getMinutes();
    const end = new Date(shift.endTime);
    const endMins = end.getMinutes() < 10 ? `${end.getMinutes()}0` : end.getMinutes();
    return {
      ...shift,
      startTime: `${start.getHours()}:${startMins}`,
      endTime: `${end.getHours()}:${endMins}`,
    };
  });

import { useParams } from "react-router-dom";
import { Button } from "../../../components/Button";
import { OnboardingScreen, useUser } from "../../auth/context/user-context";
import "./OnboardingStyles.css"
import { gettingStarted, legalSetup, practiceSystemsSetup } from "./ScreenData";
import { greenLargeCircleNumberSvg, greenLargeCircleTickSvg, greyLargeCircleNumberSvg, smallGreenCircleLetterSvg, smallGreenCircleTickSvg, smallGreyCircleLetterSvg } from "./components/circleSvgs";
import { OnboardingScreenLoader } from "./OnboardingScreenLoader";
import { useEffect } from "react";

const smallJoiner = <div style={{ marginLeft: "17px", height: "10px", paddingTop: "20px", marginTop: "-15px", paddingBottom: "20px", marginBottom: "-15px", width: "1px", borderLeft: "3px", borderColor: "#DDDDDD", borderWidth: "1px" }} />

export const Onboarding = () => {
  const params = useParams();
  const { providerOnboardingStatus } = useUser();

  const currentScreen = params?.screen;

  if (currentScreen && currentScreen.length > 0) {
    const onboardingScreen = OnboardingScreen.parse(currentScreen);
    if (onboardingScreen) return <OnboardingScreenLoader screenToLoad={onboardingScreen} />
  }


  const createBulletIcon = (currentScreen: boolean, completed: boolean, letter?: string) => {
    if (completed) return smallGreenCircleTickSvg
    if (currentScreen) return smallGreenCircleLetterSvg(letter);
    return smallGreyCircleLetterSvg(letter)
  }

  const createGroupSection = (screens: Array<{ screen: OnboardingScreen; title?: string; }>, groupTitle: string, sectionNum: number) => {
    const allCompleted = screens.filter(screen => providerOnboardingStatus?.onboardingScreenStatuses[screen.screen] !== "COMPLETED").length === 0
    const currentGroupSection = screens.filter(screen => providerOnboardingStatus?.currentOnboardingScreen === screen.screen).length > 0
    let sectionIcon = greyLargeCircleNumberSvg(sectionNum)
    if (allCompleted) sectionIcon = greenLargeCircleTickSvg;
    if (currentGroupSection) sectionIcon = greenLargeCircleNumberSvg(sectionNum);

    return <>
      <div className="text-lg font-normal mt-3">{sectionIcon} {groupTitle}</div>
      <div className="longJoiner" style={{ height: `${(40 * screens.length + 10)}px`, borderColor: allCompleted ? "rgb(0, 174, 155)" : "#DDDDDD" }} />
      <div className="groupedOverviewItems">
        {screens.map((screen, i) => {
          const numToLetter = { 0: "A", 1: "B", 2: "C", 3: "D", 4: "E" };
          const status = providerOnboardingStatus?.onboardingScreenStatuses[screen.screen]
          const current = providerOnboardingStatus?.currentOnboardingScreen === screen.screen
          const icon = (status === "COMPLETED") ? createBulletIcon(current, true) : createBulletIcon(current, false, numToLetter[i])

          const titleDiv = <div className="text-base font-normal">{icon} {screen.title}</div>
          return <div key={i}>
            {current || status === "COMPLETED" ? (
              <a href={`/provider/onboarding/${screen?.screen}`}>{titleDiv}</a>) : titleDiv}
            {(i < screens.length - 1) && smallJoiner}
          </div>
        }
        )}
      </div>
    </>
  }

  return (
    <div>
      <div
        className={`bg-white rounded-[16px] flex-1 h-full mx-auto`}
        style={{ minHeight: "300px", maxWidth: "600px" }}
      >
        <div className="text-black text-3xl font-bold text-center pt-5">Onboarding Overview</div>
        <div className="flex flex-row justify-center items-center pt-1">
          <div style={{ position: "relative" }}>
            {/* Getting Started */}
            {createGroupSection(gettingStarted.filter(s => s.title), "Getting Started", 1)}

            {/* Legal Setup */}
            {createGroupSection(legalSetup.filter(s => s.title), "Legal Setup", 2)}

            {/* Practice Systems Setup */}
            {createGroupSection(practiceSystemsSetup.filter(s => s.title), "Practice Systems Setup", 3)}

            {/* Launch Your Practice */}
            <div className="text-lg font-normal mt-3 mb-10">{greyLargeCircleNumberSvg(4)} Launch Your Practice</div>

          </div>
        </div>
      </div>

      <div className="mb-10" />


      <div className="onboardingFooter">
        <div className="continueOnboardingBtnDiv">
          <a href={`/provider/onboarding/${providerOnboardingStatus?.currentOnboardingScreen}`}>
            <Button
              variant="primary"
              className="!rounded-full flex-1 !whitespace-nowrap mx-auto">
              <div className="font-normal text-l">
                Continue with onboarding
              </div>
            </Button>
          </a>
        </div>

      </div>
    </div >
  );
}

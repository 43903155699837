import { useCallback, useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../../assets/icons/Logo.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { ReactComponent as HamburgerIcon } from "../../../assets/icons/hamburg.svg";

import { Button } from "../../../components/Button";
import { Dropdown } from "../../../components/Dropdown";
import { MobileHeader } from "./MobileHeader";
import { AnimatePresence } from "framer-motion";
import { UserButton } from "../../../components/UserButton/UserButton";
import { LogoutConfirm } from "../../family/components/LogoutConfirm";
import { DeleteConfirm } from "../../family/components/DeleteConfirm";
import { ChangePassword } from "../../family/profile/ChangePassword";
import { FamilyProfile } from "../../family/profile/FamilyProfile";
import { api } from "../../../services/api";
import { WEBSITE_URL } from "../../../env";
import { clearCookies } from "../../../services/utils";
import { Link, useNavigate } from "react-router-dom";

export function MenuItem({
  className = "",
  href,
  title,
  active,
  isMobile,
  target,
}: {
  href: string;
  title: string;
  className?: string;
  active?: boolean;
  isMobile?: boolean;
  target?: string;
}) {
  return (
    <Link
      to={href}
      className={`whitespace-nowrap md:px-5 py-3 rounded-3xl justify-start items-center gap-2.5 flex ${isMobile ? "px-5" : ""
        } ${active ? "bg-[#FFFFFF99]" : ""} ${className}`}
      target={target}
    >
      <div
        className={`${isMobile && active ? "!text-primary" : ""
          } text-[#212424] hover:text-primary text-base font-medium `}
      >
        {title}
      </div>
    </Link>
  );
}

export function Header({
  user,
  isBannerOpen,
}: {
  user: any;
  isBannerOpen: boolean;
}) {
  const navigate = useNavigate();
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [loginMenuOpen, setLoginMenuOpen] = useState(false);
  const [secondaryInsuranceData, setSecondaryInsuranceData] = useState(
    {} as any
  );
  const [insuranceData, setInsuranceData] = useState({} as any);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const [logoutConfirm, setLogoutConfirm] = useState(false);

  const handleLogoutConfirm = () => {
    setLogoutConfirm(false);
    clearCookies();
    window.location.href = "/login";
  };

  const handleLogout = () => {
    setIsProfileOpen(false);
    setOpenDropdown(false);
    setLogoutConfirm(true);
  };

  const handleEditProfile = () => {
    setShowEditProfile(true);
  };

  const handleChangePassword = () => {
    setShowChangePassword(true);
    setIsProfileOpen(false);
  };

  const handleDeleteAccount = () => {
    setIsProfileOpen(false);
    setShowDeleteAccount(true);
  };

  const handleDeleteAccountClose = () => {
    setShowDeleteAccount(false);
  };

  const handleClose = () => {
    setIsSideMenuOpen(false);
  };

  const toogleSideMenu = () => {
    setIsSideMenuOpen((open) => !open);
  };

  const fetchInsuranceData = useCallback(async () => {
    if (!user?.id) return;
    try {
      const insuranceResponse = await api.getInsurance(user?.id);
      if ([200, 201].includes(insuranceResponse.status)) {
        // take the last active insurance

        setSecondaryInsuranceData(
          (insuranceResponse?.data || []).find(
            (e) => e.isActive && !e.isPrimary
          ) || {}
        );
        setInsuranceData(
          (insuranceResponse?.data || []).find(
            (e) => e.isActive && e.isPrimary
          ) || {}
        );
      }
    } catch (error) { }
  }, [user?.id]);

  useEffect(() => {
    fetchInsuranceData();
  }, [fetchInsuranceData]);

  useEffect(() => {
    // check for scroll event and set scroll value in state
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const isPatient = user?.type === "PF";
  return (
    <>
      <div
        className={`bg-tintPrimary fixed z-[2] ${isBannerOpen ? "top-24 md:top-[58px]" : "top-0"
          } left-0 right-0 flex flex-col`}
      >
        <div
          className={`pl-5 lg:pl-10 pr-5 py-5 border-b border-black border-opacity-10 justify-between items-center flex ${scrollTop > 0 ? "bg-[#FFFFFFA3] backdrop-blur-[54px]" : ""
            }`}
        >
          <div className="justify-center items-center gap-2 flex">
            <Link to={`${WEBSITE_URL}`} rel="noreferrer">
              <Logo />
            </Link>
          </div>
          <button className="lg:hidden" onClick={toogleSideMenu}>
            {isSideMenuOpen ? (
              <CloseIcon className="h-8 w-8 [&_path]:stroke-primary" />
            ) : (
              <HamburgerIcon className="h-8 w-8" />
            )}
          </button>
          <div className="justify-start items-center gap-3 hidden lg:flex">
            <div className="justify-start items-center flex">
              {!!user?.id && isPatient ? (
                <>
                  <MenuItem title="My Chats" href="/family/dashboard" />
                  <MenuItem
                    title="Browse Providers"
                    href="/providers"
                    active={true}
                  />
                </>
              ) : (
                <>
                  <MenuItem title="About" href={`${WEBSITE_URL}`} />
                  <MenuItem
                    title="Browse Providers"
                    href="/providers"
                    active={true}
                  />
                  <MenuItem
                    title="For Providers"
                    href={`${WEBSITE_URL}/for-providers`}
                  />
                  <MenuItem
                    title="Refer a Client"
                    href={`${WEBSITE_URL}/refer-client`}
                  />
                  <MenuItem
                    title="Contact Us"
                    href={`${WEBSITE_URL}/contact-us`}
                  />
                </>
              )}
            </div>
            {!user?.id && (
              <div className="relative">
                <Button
                  onClick={() => navigate("/login")}
                  variant="primary"
                  className="!px-7 !rounded-full !text-base"
                >
                  Sign In
                </Button>
              </div>
            )}
            <div>
              {!!user?.id && isPatient && (
                <UserButton
                  user={{ ...user }}
                  onClick={() => setOpenDropdown(true)}
                />
              )}
              <Dropdown
                padding="15px"
                className="!mt-[26px] w-[110px] right-6 top-12"
                isOpen={openDropdown}
                setIsOpen={() => setOpenDropdown(false)}
              >
                <button
                  className="!pb-[2px] text-[#212424] text-base"
                  onClick={() => {
                    setOpenDropdown(false);
                    setIsProfileOpen(true);
                  }}
                >
                  My Profile
                </button>
                <button
                  className="text-[#E3270E] text-base pt-3"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:hidden">
        <AnimatePresence mode="wait" initial={false}>
          {isSideMenuOpen && (
            <MobileHeader
              onClose={handleClose}
              userInfo={{ ...user }}
              onLogout={handleLogout}
              onProfile={() => {
                setOpenDropdown(false);
                setIsProfileOpen(true);
              }}
            />
          )}
        </AnimatePresence>
      </div>

      <AnimatePresence mode="wait" initial={false}>
        {isProfileOpen && (
          <FamilyProfile
            user={{ ...user }}
            onClose={() => setIsProfileOpen(false)}
            onChangePassword={handleChangePassword}
            onEdit={handleEditProfile}
            onDelete={handleDeleteAccount}
            onLogout={handleLogout}
            insurance={insuranceData}
            secondaryInsurance={secondaryInsuranceData}
            edit={showEditProfile}
            onEditClose={() => setShowEditProfile(false)}
            fetchInsuranceData={fetchInsuranceData}
          />
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait" initial={false}>
        {showChangePassword && (
          <ChangePassword
            onClose={() => setShowChangePassword(false)}
            user={user}
          />
        )}
      </AnimatePresence>

      <AnimatePresence mode="wait" initial={false}>
        {logoutConfirm && (
          <LogoutConfirm
            onCancel={() => setLogoutConfirm(false)}
            onConfirm={handleLogoutConfirm}
          />
        )}
      </AnimatePresence>

      <AnimatePresence mode="wait" initial={false}>
        {showDeleteAccount && (
          <DeleteConfirm
            onCancel={handleDeleteAccountClose}
            userId={user?.id}
          />
        )}
      </AnimatePresence>
    </>
  );
}

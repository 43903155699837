import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { MenuItem } from "../../providers/components/Header";
import { UserButton } from "../../../components/UserButton/UserButton";

import { Dropdown } from "../../../components/Dropdown";
import { WEBSITE_URL } from "../../../env";

const framerSidebarBackground = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0, transition: { delay: 0.1 } },
  transition: { duration: 0.3 },
};

const framerSidebarPanel = {
  initial: { x: "-100%" },
  animate: { x: 0 },
  exit: { x: "-100%" },
  transition: { duration: 0.3 },
};
export function MobileFamilyHeaderMenu({
  onClose,
  className = "",
  overlayClassName = "",
  onProfile,
  onLogout,
  userInfo = {},
}: {
  onClose: () => void;
  className?: string;
  overlayClassName?: string;
  onProfile: () => void;
  onLogout: () => void;
  userInfo: any;
}) {
  const pathname = window.location.pathname;
  const [openDropdown, setOpenDropdown] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const disableScroll = () => {
    document.body.style.overflow = "hidden";
  };

  const enableScroll = () => {
    document.body.style.overflow = "";
  };

  useEffect(() => {
    disableScroll();
    return () => enableScroll();
  }, []);
  return (
    <>
      <motion.div
        {...framerSidebarBackground}
        className={`fixed z-[2] top-[73px] h-[100vh] left-0 right-0 bottom-0 bg-black/40 ${overlayClassName}`}
        onClick={handleClose}
      />
      <motion.div
        {...framerSidebarPanel}
        className={`fixed z-[3] top-[73px] h-[calc(100%-73px)] left-0 right-0 flex flex-col justify-between bg-tintPrimary w-[70%] md:w-[40%] ${className}`}
      >
        <div className="px-6 mt-5">
          <MenuItem
            active={pathname === "/family/dashboard"}
            title="My Chats"
            href="/family/dashboard"
            isMobile
          />
          <MenuItem
            active={pathname === "/providers"}
            title="Browse Providers"
            href="/providers"
            isMobile
          />
        </div>
        <div className="relative">
          <UserButton
            user={userInfo}
            variant="sideMenu"
            onClick={() => setOpenDropdown(true)}
          />

          <Dropdown
            padding="15px"
            className="w-[calc(100%-32px)] !top-[-100px] left-4"
            isOpen={openDropdown}
            setIsOpen={() => setOpenDropdown(false)}
          >
            <button
              className="!pb-[2px] text-[#212424] text-base"
              onClick={() => {
                onClose();
                onProfile();
              }}
            >
              My Profile
            </button>
            <button
              className="text-[#E3270E] text-base pt-3"
              onClick={() => {
                onClose();
                onLogout();
              }}
            >
              Logout
            </button>
          </Dropdown>
        </div>
      </motion.div>
    </>
  );
}

export function FooterAction({ onLogout }) {
  return (
    <div className="justify-center items-center gap-3 flex">
      <button
        onClick={onLogout}
        className="text-red-700 hover:text-black text-sm font-medium"
      >
        Logout
      </button>
    </div>
  );
}

import { useMemo, useState } from "react";
import { Button } from "../../../components/Button";
import { TextInput } from "../../../components/TextInput";
import {
  getLocationListForSelect,
  validateEmail,
  validatePhone,
} from "../../../services/utils";
import { ReactComponent as AlertIcon } from "../../../assets/icons/Alert.svg";

import { SelectInput } from "../../../components/SelectInput";
import { ReferralFormType } from "./ClientReferral";

export default function passwordValidation(password: string) {
  //   add special character
  const passwordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*-_]{8,}$/;
  return passwordRegex.test(password);
}

export function Step1({
  onNext,
  referralForm,
  setReferralForm
}: {
  onNext: () => void;
  referralForm: ReferralFormType;
  setReferralForm(value: ReferralFormType): void;
}) {
  const [errors, setErrors] = useState({} as any);
  const [error, setError] = useState<string>("");
  const [saving, setSaving] = useState(false);
  const [userInfo, setUserInfo] = useState({} as any);
  const [showEmailVerification, setShowEmailVerification] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "parentPhone") {
      // allow only number
      const regex = /^[0-9\b]+$/;
      if (value === "" || regex.test(value)) {
        if (value && !validatePhone(value.toString())) {
          setErrors((prev) => ({
            ...prev,
            mobile: "Invalid mobile number",
          }));
        } else {
          setErrors((prev) => ({ ...prev, mobile: "" }));
        }
        setReferralForm({ ...referralForm, parentPhone: value });
        return;
      } else {
        return;
      }
    }

    if (name === "parentEmail") {
      if (value && !validateEmail(value)) {
        setErrors((prev) => ({
          ...prev,
          email: "Invalid email format",
        }));
      } else {
        setErrors((prev) => ({ ...prev, parentEmail: "" }));
      }
    }
    setReferralForm({ ...referralForm, [name]: value } as ReferralFormType);
  };

  const validateAndNext = async (e) => {
    e.preventDefault();

    let newErrors = {};

    if (referralForm.parentEmail && referralForm.parentEmail.trim().length > 0 && !validateEmail(referralForm.parentEmail)) {
      newErrors = { ...newErrors, parentEmail: "Invalid email format" };
    }

    if (referralForm.parentPhone && referralForm.parentPhone.trim().length > 0 && !validatePhone(referralForm.parentPhone)) {
      newErrors = { ...newErrors, parentPhone: "Invalid phone number" };
    }


    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return
    }

    onNext();
  };

  const disabled = useMemo(() => {
    return !referralForm.parentFirstName ||
      !referralForm.parentLastName ||
      !referralForm.clientFirstName ||
      !referralForm.clientLastName ||
      !referralForm.clientState ||
      (!referralForm.parentEmail && !referralForm.parentPhone)
  }, [referralForm]);

  return (
    <>
      <div className="bg-white p-8 rounded-[16px]">
        <div>
          <h1 className="text-3xl font-bold mb-5">Client Referral - ABA Therapy</h1>
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold">Family Information</h2>
            <div className="text-sm text-black/60">1/3</div>
          </div>
        </div>
        <form className="mt-4 flex flex-col gap-[22px]" onSubmit={validateAndNext}>
          <div className="flex flex-col md:flex-row items-center gap-3">
            <div className="flex-1 w-full flex flex-col gap-1.5">
              <label className="text-sm text-black/80">
                Parent / Guardian First Name
              </label>
              <TextInput
                name="parentFirstName"
                value={referralForm.parentFirstName}
                onChange={handleChange}
                placeholder="Enter first name"
                maxLength={128}
              />
            </div>
            <div className="flex-1 w-full flex flex-col gap-1.5">
              <label className="text-sm text-black/80">
                Parent / Guardian Last Name
              </label>
              <TextInput
                name="parentLastName"
                value={referralForm.parentLastName}
                onChange={handleChange}
                placeholder="Enter last name"
                maxLength={128}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center gap-3">
            <div className="flex-1 w-full flex flex-col gap-1.5">
              <label className="text-sm text-black/80">
                Client First Name
              </label>
              <TextInput
                name="clientFirstName"
                value={referralForm.clientFirstName}
                onChange={handleChange}
                placeholder="Enter first name"
                maxLength={128}
              />
            </div>
            <div className="flex-1 w-full flex flex-col gap-1.5">
              <label className="text-sm text-black/80">
                Client Last Name
              </label>
              <TextInput
                name="clientLastName"
                value={referralForm.clientLastName}
                onChange={handleChange}
                placeholder="Enter last name"
                maxLength={128}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-center gap-3">

            <div className="flex-1 w-full flex flex-col gap-1.5">
              <label className="text-sm text-black/80">
                Client State
              </label>
              <SelectInput
                name="clientState"
                data={[...getLocationListForSelect()]}
                value={referralForm.clientState}
                onChange={handleChange}
              />
              {errors.clientState && (
                <div className="text-red-500 text-xs font-medium">
                  {errors.clientState}
                </div>
              )}
            </div>
          </div>

          <div className="flex-1 w-full flex flex-col gap-1.5">
            <label htmlFor="email" className="text-sm text-black/80">
              Parent / Guardian Phone Number
            </label>
            <TextInput
              type="tel"
              placeholder="Enter phone number"
              value={referralForm.parentPhone}
              onChange={handleChange}
              name="parentPhone"
              maxLength={10}
            />
            {errors.parentPhone && (
              <div className="text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                {errors.parentPhone}
              </div>
            )}
          </div>

          <div className="flex-1 w-full flex flex-col gap-1.5">
            <label htmlFor="email" className="text-sm text-black/80">
              Parent / Guardian Email
            </label>
            <TextInput
              type="email"
              placeholder="Enter email address"
              value={referralForm.parentEmail}
              onChange={handleChange}
              name="parentEmail"
              maxLength={128}
            />
            {errors.parentEmail && (
              <div className="text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                {errors.parentEmail}
              </div>
            )}
          </div>

          <div className="mt-7">
            {error && (
              <div className="text-red-500 text-xs font-medium mb-4 flex items-center gap-1">
                <AlertIcon /> {error}
              </div>
            )}
            <Button
              type="submit"
              variant="primary"
              className="!rounded-full w-full"
              disabled={disabled || saving}
              loading={saving}
              onClick={validateAndNext}
            >
              Next
            </Button>
          </div>
        </form>
      </div>

    </>
  );
}

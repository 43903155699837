
// Large SVG Circles
export const greenLargeCircleNumberSvg = (number: number) => <svg style={{ display: 'inline-block', zIndex: '1' }} width="40" height="36" viewBox="0 0 40 37" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-[35px] h-7">
  <circle cx="18.1909" cy="18.1909" r="18.1909" fill="#00AE9B"></circle>
  <text x="18.2" y="24.1909" stroke="white" textAnchor="middle" style={{ fontWeight: 400, fontSize: "20px" }} fill="white">{number}</text>
</svg >


export const greenLargeCircleTickSvg = <svg style={{ display: 'inline-block', zIndex: '1' }} width="40" height="36" viewBox="0 0 40 37" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-[35px] h-7">
  <circle cx="18.1909" cy="18.1909" r="18.1909" fill="#00AE9B"></circle>
  <path fillRule="evenodd" clipRule="evenodd" d="M8.48047 18.3188L11.3214 15.478L16.0201 20.1767L25.0256 11.1713L27.8665 14.0122L16.0201 25.8585L8.48047 18.3188Z" fill="white"></path>
</svg>

export const greyLargeCircleNumberSvg = (number: number) => <svg style={{ display: 'inline-block', zIndex: '1' }} width="40" height="36" viewBox="0 0 40 37" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-[35px] h-7">
  <circle cx="18.1909" cy="18.1909" r="18.1909" stroke="#DDDDDD" fill="#ffffff"></circle>
  <text x="18.2" y="24.1909" stroke="#DDDDDD" textAnchor="middle" style={{ fontWeight: 400, fontSize: "20px" }} fill="#DDDDDD">{number}</text>
</svg >


// Small SVG Circles
export const smallGreenCircleTickSvg = <svg style={{ display: 'inline-block' }} width="20" height="50" viewBox="0 0 35 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-[35px] h-[25px]">
  <circle cx="18.1909" cy="18.1909" r="18.1909" fill="#00AE9B"></circle>
  <path fillRule="evenodd" clipRule="evenodd" d="M8.48047 18.3188L11.3214 15.478L16.0201 20.1767L25.0256 11.1713L27.8665 14.0122L16.0201 25.8585L8.48047 18.3188Z" fill="white"></path>
</svg>;

export const smallGreenCircleLetterSvg = (letter) => <svg style={{ display: 'inline-block' }} width="20" height="50" viewBox="0 0 35 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-[35px] h-[25px]">
  <circle cx="18.1909" cy="18.1909" r="18.1909" fill="#00AE9B"></circle>
  <text x="18.2" y="25.1909" stroke="white" textAnchor="middle" style={{ fontWeight: 400, fontSize: "20px" }} fill="white">{letter}</text>
</svg >

export const smallGreyCircleLetterSvg = (letter) => <svg style={{ display: 'inline-block' }} width="20" height="50" viewBox="0 0 35 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-[35px] h-[25px]">
  <circle cx="18.1909" cy="18.1909" r="18.1909" stroke="#DDDDDD" fill="white"></circle>
  <text x="18.2" y="25.1909" stroke="#DDDDDD" textAnchor="middle" style={{ fontWeight: 400, fontSize: "20px" }} fill="#DDDDDD">{letter}</text>
</svg >
import { useEffect, useRef, useState } from "react";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { ProviderList } from "./components/ProviderList";
import { SearchView } from "./components/SearchView";
import { useProvider } from "./context/provider-context";
import { FilterModal } from "./components/FilterModal";
import { ProviderDetails } from "./ProviderDetails";
import { getUser } from "../../services/utils";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useApp } from "../../services/app-context";
import { api } from "../../services/api";
import { SkeletonView } from "./components/ProviderCard";
import { AnimatePresence, useTime } from "framer-motion";
import { SelectedFilters } from "./components/SelectedFilters";
import { ShowToast } from "../../services/toast";
import { EmptyStates } from "../../components/EmptyStates";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { WEBSITE_URL } from "../../env";
import { PromptZipModal } from "./PromptZipModal";

const hasFilters = (filters) => {
  if (filters.showAvailable) return true;
  if (filters.providerTypes.length > 0) return true;
  if (filters.focusAreas.length > 0) return true;
  if (filters.addressState) return true;
  return false;
};

export function Providers() {
  const {
    providers,
    setPage,
    total,
    page,
    search,
    setSearch,
    isLoading,
    totalPages,
    filters,
    setFilters,
    searchByZip,
    setSearchByZip
  } = useProvider() as any;
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [provider, setProvider] = useState<any>(null);
  const [isBannerOpen, setIsBannerOpen] = useState(true);
  const user = getUser();
  const navigate = useNavigate();
  const { setSelectedProvider } = useApp() as any;
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showSetZipModal, setShowSetZipModal] = useState(true);

  const handlePageChange = (e) => {
    setPage(e.selected);

    var element = document.getElementById('resultsList');
    var headerOffset = 220;
    var elementPosition = element ? element.getBoundingClientRect().top : 0;
    const offsetPosition = elementPosition + window.scrollY - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  };

  const handleFilterModalClose = () => {
    setFilterModalOpen(false);
  };

  const handleSchedule = async (provider) => {
    setSelectedProvider(provider)
    // check if user is logged in
    if (!user?.id) {
      // redirect to register page
      navigate("/register");
    } else {
      // redirect to messaging page
      // create schedule appointment
      const payload = {
        providerId: provider.id,
        patientId: user?.id,
      };
      try {
        const response = await api.scheduleAppointment(payload);
        if ([200, 201].includes(response.status)) {
          navigate("/family/dashboard");
        }
      } catch (error: any) {
        if (
          error.response?.data?.message === "Already scheduled consultation"
        ) {
          navigate("/family/dashboard");
        } else if (
          error.response?.data?.message.includes("You have an existing provider chat")
        ) {
          navigate("/family/dashboard?switch=true");
        } else {
          ShowToast({
            message:
              error.response.data.message ||
              "Unable to schedule appointment, please try again later",
            type: "error",
          });
        }
      }
    }
  };

  useEffect(() => {
    const first = params?.providerFirst
    const last = params?.providerLast

    if (first && last) {
      setShowSetZipModal(false)
      setSearch('')
      navigate(`/providers?f=${first}&l=${last}`, { replace: true })
    }
  }, [navigate, params?.providerFirst, params?.providerLast])

  useEffect(() => {
    if (!providers) return;
    setTimeout(() => {
      const first = searchParams.get("f")
      const last = searchParams.get("l")
      if (first && last) {
        const desiredProvider = providers.filter(p => p?.firstName?.toLowerCase() === first.toLowerCase()
          && p?.lastName?.toLowerCase() === last.toLowerCase())[0];
        console.log(desiredProvider)
        if (desiredProvider) {
          setShowSetZipModal(false)
          setProvider(desiredProvider)
        }
        setSearchParams(undefined)
      }
    }, 1000)
  }, [providers])

  useEffect(() => {
    setSearch('')
    setSearchByZip(true)
  }, [])

  return (
    <div className="relative">
      {isBannerOpen && (
        <div
          style={{
            backgroundImage: "url('/images/banner-overlay.png')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "0%",
            backgroundAttachment: "scroll",
          }}
          className="fixed top-0 z-[2] left-0 right-0 h-24 md:h-[58px] p-5 flex justify-center items-center w-full bg-primary"
        >
          <div className="absolute top-0 h-24 md:h-[58px]"></div>
          <span className="font-medium text-lg leading-[21.6px] tracking-[-0.035px] text-white">
            Headstart is now live in Indiana -- coming soon to other states! Out
            of state?{" "}
            <a className="underline" href={`${WEBSITE_URL}/join-our-waitlist`}>
              Join our waitlist.
            </a>
          </span>
          <button
            className="absolute right-[18px]"
            onClick={() => setIsBannerOpen(false)}
          >
            <CloseIcon className="[&_path]:stroke-white" />
          </button>
        </div>
      )}
      <div
        className="bg-tintPrimary relative pt-24 md:pt-16"
        style={{
          backgroundImage: "url('/images/header-bg-overlay.png')",
          backgroundSize: "contain",
          backgroundRepeat: "repeat",
        }}
      >
        <Header user={user} isBannerOpen={isBannerOpen} />
        <div
          className={`${!user?.id ? "pb-[65px] flex flex-col items-center" : "pb-16 items-center"}`}
        >
          <div
            className={`pt-24 ${!user?.id ? "" : ""
              } md:pt-[10.5rem] text-center`}
          >
            <span className="text-3xl md:text-[60px] font-bold text-black">
              Browse Providers
            </span>
          </div>
          {!user?.id && (<>
            <div className="w-fit self-center mt-4 px-5 py-[7px] bg-white/60 rounded-[40px] border justify-start items-center gap-2.5 flex">
              <div>
                <span className="text-[#212424] text-base font-medium leading-snug">
                  Reach out to a provider in less than 5 minutes!{"  "}
                </span>
                <a
                  href="/register"
                  className="text-primary text-base font-bold leading-snug"
                >
                  Get started
                </a>
              </div>
            </div>
          </>
          )}

          <div className="text-[#212424] text-center text-base font-medium mt-5 pb-0">
            Questions? Call
            <a href="tel:1234567890"
              className="text-primary text-base font-bold leading-snug ml-1"
            >
              (512) 766-2637
            </a>
          </div>
        </div>
        <SearchView
          search={search}
          searchByZip={searchByZip}
          setSearchByZip={newVal => {
            setSearchByZip(newVal);
            setSearch('');
            const searchInput = document.getElementById("searchInput") as HTMLInputElement | null;
            if (searchInput) {
              searchInput.value = '';
            }
          }}
          onFilter={() => setFilterModalOpen(true)}
          hasFilters={hasFilters(filters)}
          onSearch={(search) => {
            setPage(0);
            setSearch(search);
          }}
        />
      </div>
      {/** provider's list */}
      <div className="mx-2 lg:mx-auto max-w-[1240px] my-24">
        <SelectedFilters
          filters={filters}
          onClear={setFilters}
          total={total}
          isLoading={isLoading}
        />
        {isLoading ? (
          <div className="flex flex-wrap gap-5">
            {Array(10)
              .fill(0)
              .map((_, i) => (
                <SkeletonView key={i} />
              ))}
          </div>
        ) : (
          <>
            {providers?.length > 0 ? (
              <div id="resultsList">
                <ProviderList
                  providers={providers}
                  page={page + 1}
                  onPageChange={handlePageChange}
                  onView={(provider) => setProvider(provider)}
                  onSchedule={(provider) => handleSchedule(provider)}
                  totalPages={totalPages}
                />
              </div>
            ) : (
              <div className="flex my-16 md:my-32 justify-center items-center text-2xl font-bold text-black">
                <EmptyStates text={`${searchByZip ? 'No providers found close to your location' : 'No Providers Found'}`} />
              </div>
            )}
          </>
        )}
      </div>
      <Footer />
      <AnimatePresence mode="wait" initial={false}>
        {filterModalOpen && (
          <FilterModal
            onClose={handleFilterModalClose}
            filters={filters}
            setFilters={(newFilters) => {
              setFilters(newFilters);
              setPage(0);
            }}
          />
        )}
      </AnimatePresence>
      {provider && (
        <ProviderDetails
          onClose={() => setProvider(null)}
          isOpen={!!provider}
          provider={provider}
          onSchedule={() => handleSchedule(provider)}
        />
      )}
      {showSetZipModal &&
        <PromptZipModal
          onCancel={function (): void {
            setShowSetZipModal(false)
          }}
          onConfirm={function (zip: string): void {
            setSearch(zip)
            setSearchByZip(true)
            setShowSetZipModal(false)
          }} />
      }
    </div>
  );
}

import { RbtCandidate } from "./RbtCandidate.types";

export enum DAY {
  MON = "MON",
  TUES = "TUES",
  WED = "WED",
  THUR = "THUR",
  FRI = "FRI",
  SAT = "SAT",
  SUN = "SUN",
}

export type Shift = {
  day: "MON" | "TUES" | "WED" | "THUR" | "FRI" | "SAT" | "SUN";
  startTime: Date;
  endTime: Date;
  timeZone: string;
};

export enum RbtRequestStatus {
  REVIEW = "Reviewing",
  // IN_PROGRESS = "In Progress",
  IN_PROCESS = "In Process",
  ACTION_REQUIRED = "Action Required",
  COMPLETED = "Completed",
  CANCELED = "Canceled",
}

export const rbtRequestStatusColor = (status: RbtRequestStatus) => {
  switch (status) {
    case RbtRequestStatus.REVIEW:
      return "grey";
    // case RbtRequestStatus.IN_PROGRESS:
    case RbtRequestStatus.IN_PROCESS:
      return "rgb(59 130 246)"; //blue
    case RbtRequestStatus.ACTION_REQUIRED:
      return "rgb(249 115 22)"; //orange
    case RbtRequestStatus.COMPLETED:
      return "green";
    case RbtRequestStatus.CANCELED:
      return "rgb(239 68 68)"; //red
  }
};

export type RbtRequest = {
  id?: string;
  friendlyId?: string;
  ownerId: string;
  status?: RbtRequestStatus;
  clientZipCode: string;
  clientLocationComments: string;
  qualifications: "RBT_ONLY" | "BT_OR_RBT";
  weeklyHoursNeeded: number | "";
  shiftsNeeded: Array<Shift>;
  comments?: string;
  desiredStart: Date;
  hireReason?: "NEW" | "REPLACE" | "BOTH";
  rbtCandidates: Array<RbtCandidate>;
  createdAt?: Date;
  updatedAt?: Date;
  isActive?: boolean;
};

import { Button } from "../../../../components/Button";
import { PopupModal } from "../../../../components/PopupModal";

export function DeleteConfirm({
  onCancel,
  name = "",
  onConfirm,
  loading,
  fileType,
}: {
  onCancel: () => void;
  name: string;
  onConfirm: () => void;
  loading: boolean;
  fileType: string;
}) {
  return (
    <PopupModal contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[442px] max-w-[442px]">
      <div className="flex flex-col w-full">
        <p className="text-center text-xl text-black/80 font-medium break-words">
          Are you sure you want to delete the{" "}
          {fileType === "folder" ? "folder" : "file"} "{name}"?
        </p>
        <div className="flex gap-3 items-center w-full mt-8">
          <Button
            onClick={onCancel}
            variant="outline"
            className="!rounded-full w-full flex-1"
            disabled={loading}
          >
            Go Back
          </Button>
          <Button
            onClick={onConfirm}
            className="!rounded-full w-full flex-1 !bg-[#D75656] hover:!bg-black border border-[rgba(62,163,163,0.20)]"
            variant="primary"
            disabled={loading}
            loading={loading}
          >
            Delete
          </Button>
        </div>
      </div>
    </PopupModal>
  );
}

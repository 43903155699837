
import { OnboardingScreenWrapper } from "../OnboardingScreenWrapper";

export const JoinderAgreementScreen = () => {

  return (
    <OnboardingScreenWrapper title={"Joinder Agreement"}>
      <>
      </>
    </OnboardingScreenWrapper>
  );
}


import { OnboardingScreenWrapper } from "../OnboardingScreenWrapper";

export const TransitionToLegalSetupScreen = () => {

  return (
    <OnboardingScreenWrapper title={"Section Complete"}>
      <>
      </>
    </OnboardingScreenWrapper>
  );
}

import { useEffect } from "react";
import { readFile } from "./CropImage";
import ImageCropModalContent from "./ImageCropModalContent";
import { useImageCropContext } from "./provider/ImageCropProvider";
import { PopupModal } from "../PopupModal";

export const ImageCrop = ({
  image,
  isOpen,
  onClose,
  onImageChange,
}: {
  image: File;
  isOpen: boolean;
  onClose: () => void;
  onImageChange: (data: any) => void;
}) => {
  const { getProcessedImage, setImage, resetStates } =
    useImageCropContext() as any;

  const handleDone = async () => {
    try {
      const avatar = await getProcessedImage();
      onImageChange({
        url: window.URL.createObjectURL(avatar),
        file: new File([avatar], image.name, {
          type: image.type,
        }),
      });
      resetStates();
      onClose();
    } catch (error) {}
  };

  useEffect(() => {
    if (isOpen && image) {
      if (typeof image === "string") {
        (async function () {
          let blob = await fetch(image).then((r) => r.blob());
          let dataUrl = await new Promise((resolve) => {
            let reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          });
          // now do something with `dataUrl`
          setImage(dataUrl);
        })();
      } else {
        readFile(image).then((imageDataUrl) => {
          setImage(imageDataUrl);
        });
      }
    }
  }, [isOpen, image]);

  return (
    <PopupModal
      contentClassName="w-full md:mx-auto md:w-[600px] max-w-[600px]"
      onClose={onClose}
    >
      <ImageCropModalContent handleDone={handleDone} handleClose={onClose} />
    </PopupModal>
  );
};
